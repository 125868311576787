import { QuestCategoryEnum } from "types/interfaces";

export const questBanners: Record<QuestCategoryEnum, { mobile: string; tablet: string; desktop: string }> = {
  [QuestCategoryEnum.Career]: {
    mobile:
      "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-01-m-a7d65162c49c4f4b16db61028aabdd01.png",
    tablet:
      "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-01-t-1ac4757f827599a23d37361b51c73562.png",
    desktop: "https://cdn-assets.workway.app/images/programs/banner/banner-01-7e9ec04942c4fd844729daf39d7883e1.png",
  },
  [QuestCategoryEnum.Mindset]: {
    mobile:
      "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-02-m-4c35af917119d58f6fb0f964ace1d43d.png",
    tablet:
      "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-02-t-964fe95af036db956814523aa8ec8a40.png",
    desktop: "https://cdn-assets.workway.app/images/programs/banner/banner-02-5e2d2ba03de67d77c2451fa1644ea277.png",
  },
  [QuestCategoryEnum.Collaboration]: {
    mobile:
      "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-03-m-856ec7c5641f96ef940247c8441cdb1f.png",
    tablet:
      "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-03-t-9d592757ab34572b15c0ea6d4263c4c9.png",
    desktop: "https://cdn-assets.workway.app/images/programs/banner/banner-03-a8daa75c69cdb25c5f8fe5907130e3e5.png",
  },
  [QuestCategoryEnum.Soul]: {
    mobile:
      "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-04-m-60c78ccbb311b680981e5b58cec74c4c.png",
    tablet:
      "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-04-t-eb37b3a2388be2adfdf68bd8eca555d3.png",
    desktop: "https://cdn-assets.workway.app/images/programs/banner/banner-04-aa2abe2e6d7f53b535737893ef9fee26.png",
  },
  [QuestCategoryEnum.Health]: {
    mobile:
      "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-05-m-bff4ab901ab421e9965d590101f1e513.png",
    tablet:
      "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-05-t-3e3e0a53ffdfe3f58bab6b14d24ff777.png",
    desktop: "https://cdn-assets.workway.app/images/programs/banner/banner-05-de689099454b4fea9c8764d41d71ccc9.png",
  },
  [QuestCategoryEnum.Intrapreneurship]: {
    mobile:
      "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-06-m-a773f46551e90dc5a59a666137b1a958.png",
    tablet:
      "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-06-t-b28d5fcf30a543c9f181919f15e21e54.png",
    desktop: "https://cdn-assets.workway.app/images/programs/banner/banner-06-5059ac5ed0deabd6b882278e8c6075f7.png",
  },
};
