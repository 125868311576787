const EmptyStateAssignedToMe = () => {
  return (
    <svg
      width="246"
      height="160"
      viewBox="0 0 246 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Layer_1" clip-path="url(#clip0_9468_7521)">
        <path
          id="Vector"
          d="M142.699 160.002H103.827C102.694 160.002 102.147 158.614 102.975 157.841L108.42 154.728C108.651 154.512 108.956 154.392 109.272 154.392H147.862C148.912 154.392 148.755 155.557 148.331 155.998L143.668 159.539C143.431 159.831 143.074 160.002 142.697 160.002H142.699Z"
          fill="#574E48"
        />
        <path
          id="Vector_2"
          d="M135.482 155.998L120.944 156.047L114.654 118.564L129.192 118.516L135.482 155.998Z"
          fill="#574E48"
        />
        <path
          id="Vector_3"
          d="M127.4 126.472C129.07 136.27 130.537 146.112 132.454 155.866C132.56 156.398 133.373 156.173 133.269 155.641C131.352 145.886 129.885 136.045 128.214 126.247C128.123 125.712 127.309 125.939 127.4 126.472Z"
          fill="#6F635C"
        />
        <path
          id="Vector_4"
          d="M104.341 158.823H142.219C142.762 158.823 142.763 157.979 142.219 157.979H104.341C103.798 157.979 103.797 158.823 104.341 158.823Z"
          fill="#6F635C"
        />
        <path
          id="Vector_5"
          d="M120.374 155.303C120.483 155.928 120.516 156.459 121.229 156.593C121.517 156.647 121.869 156.593 122.16 156.593H132.006C132.306 156.593 132.635 156.632 132.934 156.593C133.76 156.485 134.539 155.662 135.186 155.131C135.606 154.787 135.006 154.192 134.589 154.534C134.139 154.902 133.43 155.606 132.885 155.748C132.59 155.825 132.169 155.748 131.867 155.748H122.927C122.503 155.748 121.924 155.854 121.514 155.748C121.106 155.643 121.288 155.644 121.19 155.077C121.096 154.542 120.282 154.769 120.375 155.302L120.374 155.303Z"
          fill="#6F635C"
        />
        <path
          id="Vector_6"
          d="M220.297 22.9784C219.622 22.5009 218.429 21.936 217.143 21.9339L20.4416 122.856C20.2825 123.751 20.7444 125.074 21.2416 126.226C21.5974 127.051 22.0999 127.262 23.3316 127.227L200.388 127.658C202.5 128.306 204.246 126.885 204.648 124.901C208.52 105.793 220.745 43.1209 223.071 31.5901C223.194 30.9773 223.317 30.3635 223.442 29.7508C224.148 26.1149 222.029 24.2018 220.296 22.9773L220.297 22.9784Z"
          fill="#574E48"
        />
        <g id="Group">
          <path
            id="Vector_7"
            d="M219.635 25.0539C218.127 33.288 216.612 41.522 215.104 49.7571C210.976 72.2393 206.037 94.7069 201.317 117.146C201.214 117.621 201.111 118.096 201.014 118.571C200.72 119.948 200.44 121.325 200.159 122.708C200.084 123.106 199.714 123.387 199.274 123.386L20.8398 122.993L22.1954 116.183L40.4539 24.6586C40.7629 23.0939 42.4939 21.831 44.3218 21.8352L216.902 22.2159C218.724 22.2201 219.951 23.4893 219.636 25.0539H219.635Z"
            fill="white"
          />
          <path
            id="Vector_8"
            d="M199.278 123.62L20.5557 123.226L21.9674 116.136L40.2238 24.6128C40.5525 22.9504 42.3856 21.6011 44.3122 21.6011C44.3216 21.6011 216.902 21.9818 216.902 21.9818C217.927 21.9839 218.829 22.3709 219.378 23.0429C219.844 23.6141 220.018 24.3433 219.867 25.0976L215.335 49.7998C212.063 67.6266 208.219 85.7342 204.501 103.245C203.513 107.896 202.526 112.546 201.548 117.194C201.445 117.67 201.341 118.143 201.244 118.616C200.951 119.995 200.671 121.371 200.391 122.754C200.297 123.255 199.828 123.62 199.278 123.62ZM21.1268 122.758L199.276 123.151C199.608 123.151 199.875 122.951 199.93 122.664C200.211 121.278 200.492 119.899 200.786 118.522C200.882 118.048 200.985 117.573 201.089 117.097C202.067 112.449 203.054 107.799 204.042 103.148C207.759 85.6396 211.602 67.5361 214.873 49.7155L219.406 25.0091C219.53 24.3943 219.391 23.8024 219.015 23.3405C218.555 22.7777 217.785 22.4531 216.902 22.4521L44.3216 22.0713C42.5988 22.0713 40.9718 23.251 40.6847 24.7054L22.4282 116.229L21.1278 122.759L21.1268 122.758Z"
            fill="#574E48"
          />
        </g>
        <path
          id="Vector_9"
          d="M217.477 35.9354L219.875 24.028L218.539 22.635L216.898 22.2137L44.3203 21.8329L42.7827 22.1284L41.0787 23.3622L40.1081 26.377L38.2803 35.5401L217.477 35.9354Z"
          fill="#574E48"
        />
        <path
          id="Vector_10"
          d="M201.318 117.145C201.215 117.62 201.112 118.095 201.014 118.57C200.721 119.947 200.441 121.324 200.16 122.707C200.084 123.105 199.715 123.386 199.275 123.385L20.8403 122.992L22.1959 116.182L201.317 117.145H201.318Z"
          fill="#EDE6DE"
        />
        <path
          id="Vector_11"
          d="M199.278 123.807L20.8402 123.414C20.7143 123.414 20.5947 123.357 20.5146 123.259C20.4345 123.161 20.4022 123.033 20.4272 122.91L21.7827 116.1L40.0392 24.5752C40.3846 22.8275 42.3008 21.4251 44.3221 21.4127L216.903 21.7934C217.984 21.7955 218.939 22.2075 219.523 22.9232C220.026 23.538 220.213 24.3224 220.051 25.1328L215.52 49.8319C212.246 67.6618 208.402 85.7705 204.684 103.283C203.697 107.934 202.71 112.584 201.732 117.233C201.628 117.708 201.525 118.181 201.429 118.655C201.136 120.034 200.855 121.41 200.575 122.791C200.464 123.379 199.919 123.807 199.278 123.807ZM21.3552 122.571L199.277 122.963C199.516 122.963 199.708 122.825 199.746 122.628C200.028 121.238 200.309 119.859 200.601 118.481C200.698 118.009 200.801 117.532 200.905 117.055C201.882 112.409 202.869 107.759 203.857 103.107C207.574 85.601 211.417 67.4974 214.688 49.679L219.219 24.9768C219.335 24.4025 219.213 23.8803 218.868 23.4569C218.443 22.9378 217.727 22.6381 216.9 22.6371L44.319 22.2564C42.6816 22.2564 41.1378 23.3684 40.8662 24.7396L21.3541 122.571H21.3552Z"
          fill="#574E48"
        />
        <path
          id="Vector_12"
          d="M109.882 33.9661C110.377 25.3055 110.236 15.8907 106.337 7.73879C105.049 5.04542 103.269 1.48131 99.8291 0.342168C96.6031 -0.726232 93.2263 0.857122 91.4588 3.18222C88.8923 6.55491 88.8757 11.1344 89.089 14.997C89.1524 16.1383 89.2388 17.2795 89.3397 18.4207C89.1711 18.1429 89.0057 17.8641 88.8351 17.5874C86.0627 13.1058 82.9657 8.56791 78.4799 5.15777C72.0216 0.24854 57.7724 -3.36758 54.459 6.19912C50.7576 16.8863 60.2016 26.9492 66.8419 35.1406C69.1493 37.9869 71.4723 40.8249 73.8057 43.6556C69.6247 41.7143 64.8684 40.4701 60.4585 39.9489C53.4707 39.1229 48.16 42.9804 50.0107 49.1151C52.0039 55.7221 58.4393 59.1302 65.3813 61.5947C75.0812 65.0381 84.8122 68.4181 94.5277 71.8283C94.9844 71.9885 95.4796 71.6504 95.6148 71.3029C95.904 70.5549 96.1277 69.808 96.3077 69.0517C99.3672 68.9289 102.492 66.868 104.327 65.0475C104.687 64.6907 104.573 64.2537 104.276 63.9853C104.208 63.1625 104.126 62.3416 104.037 61.5208C104.126 61.4418 104.198 61.3419 104.243 61.216C107.423 52.363 109.353 43.2165 109.883 33.9661H109.882Z"
          fill="#C2DAFB"
        />
        <path
          id="Vector_13"
          d="M90.0751 19.9396C95.1238 27.7066 98.3154 36.5628 99.1467 45.8007C99.1945 46.3375 100.04 46.3427 99.9914 45.8007C99.1467 36.413 95.9373 27.4091 90.8054 19.5131C90.51 19.0595 89.7786 19.4819 90.0762 19.9396H90.0751Z"
          fill="#272221"
        />
        <path
          id="Vector_14"
          d="M74.3697 45.2077C76.4524 45.9911 78.2823 47.2883 79.6139 49.0818C79.9343 49.5136 80.6677 49.0922 80.3432 48.6553C78.868 46.6683 76.9049 45.2618 74.5944 44.3931C74.0847 44.2017 73.8651 45.0173 74.3697 45.2077Z"
          fill="#272221"
        />
        <path
          id="Vector_15"
          d="M193.914 118.532C192.401 118.529 192.499 120.656 194.014 120.661C195.526 120.664 195.428 118.536 193.914 118.532Z"
          fill="white"
        />
        <path
          id="Vector_16"
          d="M190.131 118.524C188.619 118.521 188.717 120.648 190.231 120.652C191.744 120.655 191.646 118.528 190.131 118.524Z"
          fill="white"
        />
        <path
          id="Vector_17"
          d="M186.396 118.516C184.883 118.513 184.981 120.641 186.495 120.645C188.008 120.648 187.91 118.521 186.396 118.516Z"
          fill="white"
        />
        <path
          id="Vector_18"
          d="M209.572 26.6111C206.733 26.6048 206.916 30.5986 209.76 30.6049C212.599 30.6111 212.416 26.6173 209.572 26.6111Z"
          fill="white"
        />
        <path
          id="Vector_19"
          d="M202.561 26.5955C199.722 26.5893 199.905 30.583 202.749 30.5893C205.588 30.5955 205.405 26.6018 202.561 26.5955Z"
          fill="white"
        />
        <path
          id="Vector_20"
          d="M111.883 46.7661C111.876 46.7359 111.876 46.7047 111.88 46.6746C111.916 46.3906 111.819 46.1315 111.643 45.9297C110.391 42.3458 106.523 39.2987 102.809 39.228C100.255 39.1791 98.3197 40.6824 97.1119 42.737C97.0027 42.9232 96.7509 42.9544 96.6021 42.7973C94.4913 40.5658 91.8781 38.969 88.83 38.5913C85.2253 38.145 82.1647 40.3619 80.6302 43.5172C78.9876 46.893 79.3787 50.6277 80.1538 54.2002C80.2047 54.4353 79.9915 54.6329 79.7584 54.5736C71.8562 52.5575 64.5688 59.486 63.6107 67.4506C63.0489 72.1133 64.0133 77.7268 67.1561 81.3721C69.5883 84.1934 73.1254 85.5552 76.6937 86.4727C76.7998 86.4998 76.8945 86.5768 76.9173 86.6839C77.0661 87.3882 78.2157 87.745 78.6568 87.1115C78.7233 87.0158 78.8534 86.9846 78.9668 87.0095C79.3132 87.0855 79.6586 87.1604 79.9998 87.2322C81.012 87.4465 81.5832 86.3333 81.2294 85.6467C81.1556 85.5021 81.1847 85.3284 81.3158 85.2327C87.1644 80.9549 92.9631 76.3869 97.1535 70.4592C101.963 63.6566 106.125 56.3234 110.457 49.1879C110.527 49.0735 110.658 49.0007 110.789 49.0267C111.397 49.1515 112.097 48.8197 112.065 48.0218C112.049 47.6004 111.98 47.1801 111.885 46.764L111.883 46.7661Z"
          fill="white"
        />
        <path
          id="Vector_21"
          d="M218.287 23.8948C219.56 25.3409 218.629 27.6857 218.265 29.319C217.868 31.1063 217.47 32.8925 217.072 34.6797C216.955 35.2093 217.768 35.435 217.887 34.9045C218.409 32.5586 218.937 30.2147 219.453 27.8678C219.805 26.2688 220.046 24.6199 218.884 23.2977C218.524 22.8889 217.929 23.4881 218.287 23.8948Z"
          fill="#6F635C"
        />
        <path
          id="Vector_22"
          d="M66.7732 99.6067C63.6626 90.1742 61.2699 80.5107 57.8473 71.1812C54.796 62.8639 50.9094 54.0473 44.7799 47.4995C42.4902 45.0538 39.3557 43.123 35.8842 43.7638C32.4189 44.4036 30.7763 47.228 30.5432 50.5747C29.9908 58.4956 32.7144 65.1162 36.3513 72.1862C33.2106 69.1308 30.407 67.7419 26.7045 65.3451C22.149 62.3968 16.9432 59.975 11.4452 59.7034C7.07068 59.4871 -0.244791 60.6044 0.0558591 66.4031C0.25872 70.3302 3.08213 73.6613 5.82543 76.2018C13.1732 83.0085 18.6608 85.2223 26.77 91.8907C34.1781 97.9828 31.7126 95.3664 27.2777 94.4988C22.8865 93.6405 16.3638 93.2119 11.9664 94.2741C8.86626 95.0231 5.48629 96.7854 3.6491 99.4621C0.263921 104.395 3.90501 109.832 8.37628 112.347C12.7508 114.809 18.0397 115.694 22.9947 115.983C31.338 116.47 44.652 115.5 54.4038 116.598C57.4624 114.639 57.0681 114.406 60.5999 114.528C60.6655 114.53 60.7258 114.548 60.7893 114.56C61.7526 113.581 62.6275 112.562 63.1185 111.795C65.2241 108.5 66.5973 104.655 67.517 100.879C67.6751 100.23 67.2704 99.7648 66.7763 99.6067H66.7732Z"
          fill="#C2DAFB"
        />
        <path
          id="Vector_23"
          d="M37.5853 74.5903C42.7005 79.0064 46.1887 84.829 49.4469 90.6673C49.7122 91.1417 50.4414 90.7162 50.1762 90.2407C46.8846 84.3442 43.3486 78.454 38.1824 73.9931C37.7725 73.6394 37.1733 74.2345 37.5853 74.5903Z"
          fill="#272221"
        />
        <path
          id="Vector_24"
          d="M76.578 74.7817C76.7205 74.5685 76.7465 74.2907 76.5468 74.0056C73.558 69.7664 69.4113 65.266 63.7021 65.8683C59.8872 66.2709 56.8922 69.5261 57.2563 73.2701C51.1392 72.157 42.7928 72.2413 41.921 80.1372C41.0513 88.0187 48.7715 92.3568 54.2331 96.595C53.9408 97.1651 54.3569 98.0275 55.1091 97.7529C55.236 97.706 55.3608 97.654 55.4877 97.6062C55.519 97.6322 55.5512 97.6582 55.5814 97.6842C56.2024 98.2116 57.0451 97.6103 56.9754 97.0049C59.7593 95.8137 62.3902 94.3302 65.0285 92.8218C67.9486 91.151 71.0415 89.6051 73.6193 87.4184C77.2812 84.312 79.494 78.9586 76.577 74.7828L76.578 74.7817Z"
          fill="#C2DAFB"
        />
        <path
          id="Vector_25"
          d="M51.6315 72.5773C48.7821 72.5451 45.7944 73.7851 44.3306 76.3433C42.9741 78.7152 43.2425 81.8163 45.1317 83.8096C45.5062 84.2049 46.1023 83.6067 45.7288 83.2124C44.0185 81.4065 43.8937 78.5539 45.2305 76.4899C46.6068 74.3656 49.1847 73.394 51.6315 73.421C52.1746 73.4273 52.1756 72.5825 51.6315 72.5763V72.5773Z"
          fill="#272221"
        />
        <path
          id="Vector_26"
          d="M32.3481 95.8574C34.9759 96.5669 37.3895 98.1888 39.6469 99.6556C41.8035 101.058 43.8841 102.57 46.0084 104.019C46.4589 104.326 46.8813 103.594 46.435 103.289C44.2201 101.778 42.0521 100.201 39.7978 98.7485C37.559 97.3045 35.1715 95.744 32.5718 95.0429C32.0464 94.9014 31.8227 95.7159 32.3471 95.8574H32.3481Z"
          fill="#272221"
        />
        <g id="Group_2">
          <path
            id="Vector_27"
            d="M57.0924 72.8977C62.113 77.4855 66.3564 82.8296 69.6854 88.7604C69.9517 89.2348 70.681 88.8093 70.4147 88.3339C67.0524 82.3437 62.76 76.9341 57.6896 72.3006C57.289 71.9344 56.6898 72.5305 57.0924 72.8977Z"
            fill="#272221"
          />
          <path
            id="Vector_28"
            d="M59.015 65.9952C57.9414 64.0997 56.4767 62.4363 54.8163 61.0339C53.9768 60.3255 53.0821 59.6815 52.1438 59.1115C50.6821 58.223 46.8673 57.0912 46.3888 59.7918C46.3097 60.236 46.4002 60.6865 46.5906 61.1193C45.4119 61.1994 44.2759 61.6644 43.9825 62.815C43.8296 63.4163 43.8962 64.0217 44.1053 64.5877C43.2033 64.7406 42.3669 65.1733 41.9123 65.9952C41.2309 67.2259 42.11 68.9476 42.9256 69.9005C43.8983 71.0366 49.7999 74.274 51.5019 75.1364C54.2462 76.5273 58.0236 77.706 60.0689 74.5913C61.7105 72.0925 60.3248 68.3057 59.0161 65.9942L59.015 65.9952Z"
            fill="#FF8D82"
          />
          <path
            id="Vector_29"
            d="M50.6707 62.0472C49.7958 61.4667 48.8221 61.0818 47.7859 60.9122C47.1794 60.8134 46.9183 61.7413 47.53 61.8412C48.4954 61.9993 49.3693 62.3385 50.1849 62.8794C50.703 63.2227 51.1867 62.3894 50.6707 62.0472Z"
            fill="#574E48"
          />
          <path
            id="Vector_30"
            d="M48.0427 66.2594C47.1751 65.6332 46.243 65.1369 45.2495 64.7437C44.6732 64.5148 44.4225 65.447 44.9936 65.6727C45.908 66.0347 46.76 66.5164 47.5569 67.0917C48.0604 67.4548 48.5421 66.6194 48.0438 66.2594H48.0427Z"
            fill="#574E48"
          />
        </g>
        <path
          id="Vector_31"
          d="M184.259 10.2761C183.89 6.01916 180.693 3.50577 176.546 3.26754C166.079 2.6652 158.302 12.1258 152.989 19.8688C150.924 22.8785 149.052 26.0119 147.369 29.24C146.926 26.9638 146.152 24.7781 144.975 22.8368C142.405 18.5924 137.075 18.4259 135.457 23.5807C134.473 26.7131 134.727 30.4093 134.94 33.6342C135.174 37.19 135.7 40.7177 136.202 44.2433C137.312 52.027 138.244 59.9687 135.772 67.5973C135.57 68.2205 135.972 68.6605 136.466 68.7822C136.066 69.3586 135.673 69.9401 135.289 70.5289C134.588 71.6056 139.305 75.0824 139.97 73.9921C151.076 55.8293 167.51 42.2512 179.227 24.5274C181.926 20.4431 184.696 15.3497 184.256 10.2761H184.259Z"
          fill="#C2DAFB"
        />
        <path
          id="Vector_32"
          d="M199.644 124.025C200.087 124.874 200.53 125.724 200.973 126.573C201.225 127.054 201.954 126.628 201.703 126.146C201.259 125.297 200.816 124.447 200.373 123.598C200.121 123.117 199.392 123.543 199.644 124.025Z"
          fill="#6F635C"
        />
        <path
          id="Vector_33"
          d="M185.964 41.8974C184.279 39.6597 181.753 39.2956 179.215 39.539C181.238 36.3931 183.93 31.0813 180.518 28.956C176.231 26.2844 169.948 30.6236 166.568 33.0194C158.535 38.713 152.437 46.7578 148.964 55.9281C148.857 55.9916 148.759 56.0831 148.681 56.2163C145.926 60.9674 151.1 61.3866 154.545 62.2907C159.755 63.6566 164.884 65.3877 170.017 67.0449C169.89 67.589 170.526 68.2933 171.231 67.9853C171.468 67.8813 171.701 67.7721 171.932 67.6576C172.176 67.7346 172.419 67.8137 172.663 67.8907C173.42 68.1289 173.842 67.279 173.559 66.7266C176.167 65.0506 178.352 62.739 180.48 60.4941C183.117 57.7143 185.658 54.6485 186.855 50.9492C187.801 48.027 187.898 44.4639 185.965 41.8964L185.964 41.8974Z"
          fill="#C2DAFB"
        />
        <path
          id="Vector_34"
          d="M175.852 28.0364C170.145 29.2171 165.165 33.2775 161.041 37.2078C156.764 41.2827 153.167 46.039 150.37 51.2395C150.113 51.718 150.841 52.1446 151.099 51.666C153.804 46.6382 157.252 42.0473 161.356 38.0764C165.43 34.1336 170.394 30.0275 176.077 28.8509C176.608 28.7407 176.384 27.9271 175.852 28.0364Z"
          fill="#272221"
        />
        <path
          id="Vector_35"
          d="M146.777 30.6298C141.405 39.9874 141.117 51.2009 144.375 61.3232C144.54 61.8381 145.356 61.6176 145.19 61.0985C141.999 51.1874 142.243 40.2257 147.506 31.0563C147.778 30.584 147.048 30.1586 146.777 30.6298Z"
          fill="#272221"
        />
        <path
          id="Vector_36"
          d="M130.024 127.773H117.264C117.031 127.773 116.841 127.584 116.841 127.351C116.841 127.118 117.031 126.928 117.264 126.928H130.024C130.257 126.928 130.447 127.118 130.447 127.351C130.447 127.584 130.257 127.773 130.024 127.773Z"
          fill="#6F635C"
        />
        <path
          id="Vector_37"
          d="M181.354 51.9999C174.69 47.1073 166.473 50.5944 162.268 56.7084C160.055 52.8946 155.469 51.2675 150.456 53.2972C143.275 56.2038 140.154 63.6639 139.652 70.9294C139.02 70.9575 138.609 71.9749 139.29 72.4618C142.996 75.1084 146.463 78.7661 149.005 82.5518C151.49 86.2543 153.065 90.6558 154.915 94.6974C157.805 101.014 160.522 108.459 166.689 112.34C173.354 116.536 178.865 111.616 179.97 104.781C180.707 100.216 180.907 95.5796 181.67 91.0095C182.437 86.4124 183.451 81.8662 184.665 77.3669C184.709 77.2025 184.701 77.0558 184.66 76.9268C187.163 68.8946 188.665 57.3669 181.354 51.9999Z"
          fill="white"
        />
        <path
          id="Vector_38"
          d="M60.4762 116.268C61.7922 114.116 62.0492 111.271 62.1387 108.858C62.2968 104.63 61.5436 100.475 60.0612 96.5211C59.9717 96.2808 59.8063 96.1268 59.6149 96.0467C58.9043 95.7471 57.7132 92.6095 57.1982 91.8647C56.2651 90.5164 55.2008 89.2795 53.993 88.1716C51.3693 85.7643 46.9335 83.3528 43.2757 84.519C40.4742 85.4126 38.9751 88.4545 38.5745 91.1718C38.0585 94.6756 39.129 98.0025 40.5636 101.145C40.7769 101.612 40.4575 102.142 39.9457 102.184C36.3223 102.478 33.0796 103.726 31.5576 107.565C30.3717 110.558 30.8835 113.605 32.2983 116.267H60.4752L60.4762 116.268Z"
          fill="white"
        />
        <path
          id="Vector_39"
          d="M140.835 49.1432C140.81 48.3318 140.782 47.5162 140.754 46.6954C140.498 39.3008 138.079 33.3783 130.129 31.7346C121.201 29.8881 111.482 35.6473 106.061 42.1294C101.949 47.046 99.722 52.8322 97.8775 58.8462C97.6102 59.717 96.9381 60.3952 96.0736 60.6834C83.8146 64.7666 72.1142 72.4036 68.3774 85.2223C68.0767 86.2522 67.232 87.022 66.1761 87.2134C60.2297 88.287 54.5641 91.4277 51.1623 96.5201C47.517 101.978 44.7977 109.824 46.586 116.322L95.6492 116.599C103.51 113.315 125.021 114.643 129.079 108.702C133.832 101.742 119.723 90.3801 127.043 87.2176C130.578 85.6904 133.035 83.4329 134.618 79.9094C135.982 76.8759 136.683 73.6072 137.942 70.5445C138.067 70.2418 138.259 69.9744 138.477 69.73C138.544 69.653 138.602 69.5583 138.641 69.4449C141.013 62.6881 141.049 56.0811 140.836 49.1432H140.835Z"
          fill="#A83126"
        />
        <path
          id="Vector_40"
          d="M82.0377 115.662C82.0585 107.698 88.9173 102.667 96.0944 101.079C96.2723 101.039 96.4065 100.85 96.4044 100.672C96.3617 96.8051 95.3609 92.9966 95.9092 89.1256C96.3711 85.8673 97.7994 82.8358 99.878 80.2954C100.892 79.0564 102.058 77.7445 103.47 76.9633C105.029 76.1009 106.769 75.6286 108.534 75.4392C111.902 75.0782 115.295 75.6098 118.641 75.9552C119.181 76.0103 119.177 75.1656 118.641 75.1105C115.007 74.736 111.313 74.1513 107.666 74.7058C105.987 74.9607 104.347 75.4808 102.871 76.3287C101.461 77.1391 100.301 78.4488 99.2798 79.6972C97.2585 82.168 95.7646 85.084 95.1945 88.2403C94.4392 92.4192 95.5128 96.4899 95.5586 100.67C95.6616 100.535 95.7656 100.398 95.8686 100.263C88.3149 101.935 81.2127 107.327 81.1919 115.661C81.1909 116.204 82.0345 116.205 82.0366 115.661L82.0377 115.662Z"
          fill="#782B24"
        />
        <path
          id="Vector_41"
          d="M120.25 82.2855C119.907 77.8975 111.677 70.0078 111.203 70.0067C109.429 70.0046 106.945 70.8899 105.051 73.9245C103.481 76.439 96.7871 88.5472 95.8945 93.2514C95.0934 97.4699 96.0027 101.503 96.4344 105.834C96.7153 108.662 97.6828 112.754 101.078 112.922C114.378 113.575 118.631 94.5737 119.981 91.3685C121.182 88.5149 120.501 85.4803 120.251 82.2855H120.25Z"
          fill="#FFD37E"
        />
        <path
          id="Vector_42"
          d="M115.949 45.3752C110.906 47.0876 105.938 50.7848 104.609 56.1955C104.203 57.8538 104.238 59.5588 104.27 61.2535C104.287 62.1336 104.312 63.02 104.238 63.8969C104.201 64.3432 104.199 65.0205 103.907 65.3898C103.499 65.9068 102.746 66.0982 102.15 66.3011C99.0968 67.3393 96.0966 68.5929 93.3096 70.2231C87.8937 73.3908 83.282 78.1763 81.5769 84.3443C81.1587 85.8579 80.9892 87.3789 80.9413 88.9435C80.9059 90.1014 80.4846 90.7172 79.4401 91.1822C78.6204 91.5474 77.8027 91.9125 76.9985 92.311C74.1647 93.7144 71.4641 95.4007 69.0381 97.4335C63.6305 101.965 59.5733 108.441 59.6076 115.662C59.6108 116.205 60.4545 116.206 60.4524 115.662C60.4201 108.919 64.1039 102.881 69.0578 98.5269C71.4755 96.4015 74.2126 94.6621 77.0818 93.2129C78.2615 92.6168 79.8729 92.2142 80.8727 91.3497C81.9681 90.402 81.733 89.1099 81.8256 87.8096C82.3072 81.034 86.8888 75.3456 92.4118 71.7679C95.1156 70.0171 98.0628 68.6699 101.085 67.5703C102.141 67.1864 103.677 66.9783 104.441 66.0774C105.372 64.9799 105.142 63.0158 105.124 61.6956C105.098 59.8148 105.001 57.9328 105.509 56.0988C105.964 54.4592 106.811 52.9466 107.895 51.641C110.042 49.0569 113.017 47.2623 116.174 46.1908C116.685 46.0171 116.466 45.2015 115.949 45.3763V45.3752Z"
          fill="#782B24"
        />
        <path
          id="Vector_43"
          d="M148.717 68.5482C145.827 67.5557 142.073 67.719 140.43 65.5864C138.72 63.3674 136.623 52.5846 136.175 49.963C136.015 49.0226 136.049 47.8283 135.431 47.0449C135.173 46.7172 134.826 46.4925 134.45 46.3177C134.466 46.2002 134.415 46.0806 134.264 46.0493C134.049 46.0046 133.843 45.9984 133.641 46.0088C133.573 45.9869 133.505 45.964 133.438 45.9422C133.288 45.8943 133.181 45.9765 133.135 46.0889C131.25 46.6038 130.037 49.3555 129.442 50.9471C128.286 54.0441 127.848 57.499 125.631 60.0884C125.467 60.2787 127.034 62.5913 127.636 66.6735C127.798 67.7659 122.856 69.0787 121.777 69.3794C121.639 69.4179 121.553 69.5614 121.588 69.7008C122.223 72.1851 128.845 106.63 136.537 105.623C140.424 105.114 145.596 73.6988 147.579 69.8818C147.583 69.8725 147.583 69.8642 147.586 69.8558C147.822 69.7945 148.996 68.6449 148.716 68.5482H148.717Z"
          fill="#FDAF90"
        />
        <path
          id="Vector_44"
          d="M114.569 42.7578C114.248 42.3854 113.845 42.2564 113.431 42.2782C113.321 42.2075 113.182 42.1679 113.01 42.1794C107.771 42.5414 108.27 54.9887 111.041 54.4499C111.961 54.2709 112.116 52.8384 112.286 52.1425C112.833 49.9016 113.315 47.7523 114.247 45.627C114.636 44.7406 115.347 43.6587 114.57 42.7568L114.569 42.7578Z"
          fill="white"
        />
        <path
          id="Vector_45"
          d="M134.595 46.2803C134.024 45.9099 133.512 45.3128 133.055 44.6158C132.633 44.235 132.187 43.874 131.692 43.4912C129.961 42.1575 128.795 40.8259 127.777 38.892C127.186 37.7716 126.574 36.4067 125.393 35.7814C123.825 34.9523 121.855 35.5807 120.411 36.3703C119.293 36.9809 118.296 37.7903 117.37 38.6829C117.373 38.8889 117.32 39.1084 117.18 39.332C116.173 40.9466 115.186 42.5716 114.254 44.2267C114.125 44.724 113.975 45.2129 113.745 45.6675C113.685 45.7861 113.612 45.8808 113.535 45.964C113.696 47.2467 113.94 48.5253 114.092 49.6967C114.452 52.4847 114.782 55.305 115.323 58.0649C117.208 67.6681 129.67 61.5645 134.121 57.7258C134.481 57.4147 135.398 51.8491 135.655 47.2998C135.184 47.0605 134.899 46.6798 134.595 46.2813V46.2803Z"
          fill="#FDAF90"
        />
        <path
          id="Vector_46"
          d="M134.954 34.35C131.94 31.2925 127.334 31.0158 123.391 32.0717C115.196 34.2668 114.358 42.1773 111.498 50.7723C111.284 51.4163 112.411 51.4642 112.812 51.1323C113.134 51.1437 113.444 50.9742 113.525 50.557C114.611 44.9497 118.249 40.2652 123.431 37.9349C123.788 37.7747 124.21 37.9609 124.304 38.3406C125.608 43.5411 131.687 47.7773 137.207 47.589C137.645 47.5744 137.364 46.1638 137.431 45.8288C137.546 45.7341 137.635 45.5937 137.673 45.3971C138.403 41.5833 137.794 37.2306 134.954 34.35Z"
          fill="#912D2C"
        />
        <path
          id="Vector_47"
          d="M245.921 46.4446C246.209 42.6881 244.619 39.3976 240.677 38.6881C236.21 37.885 231.81 40.3734 228.271 42.7942C220.543 48.0811 214.169 55.1073 208.896 62.8004C207.28 65.1588 205.774 67.5838 204.347 70.0525C204.807 68.3453 205.201 66.6194 205.507 64.8748C206.253 60.6137 206.862 55.9344 205.759 51.6795C203.433 42.7099 193.494 43.4049 187.897 48.9445C181.212 55.5619 179.19 66.4603 177.162 75.2478C174.604 86.3292 169.037 110.875 167.209 116.96L200.87 117.142C201.223 117.015 210.385 112.405 213.512 110.212C219.36 106.112 225.861 101.601 229.733 95.4694C231.658 92.4202 232.928 88.9435 233.445 85.3773C233.858 82.531 234.084 79.4277 231.788 77.3222C230.649 76.2777 229.307 75.6889 227.854 75.4361C229.249 74.1367 230.632 72.8259 231.985 71.4881C238.745 64.8041 245.167 56.2694 245.92 46.4446H245.921Z"
          fill="#C2DAFB"
        />
        <path
          id="Vector_48"
          d="M190.166 93.809C184.545 97.4813 180.048 102.651 177.216 108.74C176.988 109.23 177.716 109.658 177.945 109.166C180.717 103.205 185.087 98.1346 190.592 94.5383C191.046 94.2418 190.622 93.5105 190.166 93.809Z"
          fill="#272221"
        />
        <path
          id="Vector_49"
          d="M125.734 72.2985C127.683 73.137 129.675 73.8184 131.735 74.3323C132.263 74.4645 132.487 73.6499 131.96 73.5178C129.974 73.0215 128.041 72.3786 126.16 71.5693C125.666 71.357 125.235 72.0842 125.734 72.2985Z"
          fill="#DE806E"
        />
        <path
          id="Vector_50"
          d="M136.109 73.8923C139.582 72.749 143.015 71.4943 146.407 70.1295C146.905 69.9287 146.688 69.112 146.182 69.3149C142.79 70.6798 139.356 71.9344 135.884 73.0777C135.37 73.2473 135.591 74.0629 136.109 73.8923Z"
          fill="#DE806E"
        />
        <path
          id="Vector_51"
          d="M203.383 71.9521C201.485 79.5869 197.897 86.7048 192.867 92.7552C192.522 93.1703 193.117 93.7706 193.465 93.3524C198.589 87.1885 202.263 79.9573 204.197 72.1758C204.328 71.6483 203.514 71.4236 203.383 71.9511V71.9521Z"
          fill="#272221"
        />
        <path
          id="Vector_52"
          d="M168.479 117.03L187.382 117.07C189.837 113.905 191.341 109.616 189.644 105.688C187.908 101.671 183.428 99.9021 179.413 101.223C180.909 98.8535 181.83 95.9999 181.645 93.189C181.483 90.7224 179.836 89.2743 177.35 89.6509C171.706 90.5071 168.029 96.3099 166.393 101.271C166.365 101.358 166.381 101.429 166.42 101.484C165.983 102.297 166.282 103.565 166.426 104.423C166.894 107.208 167.362 109.992 167.829 112.777C168.035 114.006 168.079 115.631 168.48 117.028L168.479 117.03Z"
          fill="white"
        />
        <path
          id="Vector_53"
          d="M172.16 91.8221C169.51 93.6395 167.841 96.4161 167.459 99.6026C167.394 100.141 168.239 100.136 168.303 99.6026C168.648 96.7365 170.208 94.1825 172.586 92.5513C173.032 92.2455 172.61 91.5141 172.16 91.8221Z"
          fill="#272221"
        />
        <path
          id="Vector_54"
          d="M170.239 116.979L125.931 116.717C123.1 113.14 120.002 109.425 118.439 105.227C116.661 100.473 115.5 95.5422 114.654 90.5476C110.676 87.1323 106.814 76.7614 106.948 76.3921C109.507 69.2233 117.607 69.3575 123.898 68.029C124.202 67.9645 124.813 67.976 124.653 68.3713C124.316 69.19 124.128 70.1326 124.069 71.1718C123.984 72.3984 124.08 73.7685 124.311 75.2197C124.824 78.4967 126.024 82.2012 127.426 85.756C128.01 87.2332 128.631 88.6792 129.252 90.0608C131.961 96.0675 134.713 100.795 134.745 100.806C135.446 96.4004 137.175 87.9094 139.542 80.4296C139.965 79.0855 140.415 77.7737 140.875 76.5263C142.385 72.4785 144.083 69.0902 145.897 67.3664C145.907 67.356 145.924 67.3508 145.934 67.3393C146.02 67.2103 146.154 67.1198 146.352 67.1198C146.818 67.1198 147.278 67.125 147.733 67.125C148.74 67.125 149.736 67.1302 150.704 67.1677C153.451 67.2582 156.037 67.5796 158.355 68.7198C158.612 68.8374 158.863 68.9716 159.11 69.1162C160.031 69.6519 160.898 70.3261 161.717 71.1885C162.071 71.563 162.418 71.9697 162.75 72.414C162.798 72.4733 162.841 72.5315 162.884 72.5908C163.822 73.8756 164.587 75.2519 165.214 76.6969C165.717 77.8475 166.134 79.0418 166.483 80.2683C167.982 85.4938 168.271 91.2384 168.86 96.3567C169.331 100.425 169.673 104.506 169.893 108.59C170 110.635 170.08 112.68 170.123 114.731C170.138 115.282 170.235 116.315 170.24 116.979H170.239Z"
          fill="#FFD37E"
        />
        <path
          id="Vector_55"
          d="M120.843 82.0681C120.048 81.5469 119.077 82.0161 118.787 82.9149C118.579 83.5568 118.656 84.2954 118.789 84.9446C118.857 85.2827 119.082 85.548 119.363 85.705C119.515 85.9776 119.697 86.2325 119.91 86.4582C120.519 87.1011 121.699 86.8421 121.897 85.9027C122.154 84.6772 121.965 82.8036 120.843 82.0671V82.0681Z"
          fill="white"
        />
        <path
          id="Vector_56"
          d="M115.355 109.828C114.6 108.696 111.594 107.165 111.407 107.286C111.391 107.286 111.381 107.28 111.365 107.281C107.857 107.452 104.063 107.858 100.841 109.162C99.2172 109.818 97.9178 110.991 96.8619 112.367C96.1743 113.263 93.8523 115.878 95.2806 116.769C96.0546 117.25 96.9764 116.786 97.7451 116.623C98.0208 116.565 98.3069 116.571 98.5888 116.577C102.558 116.673 106.529 116.633 110.5 116.633C110.548 116.64 111.281 116.642 111.332 116.633C111.332 116.633 115.962 110.739 115.357 109.83L115.355 109.828Z"
          fill="#FDAF90"
        />
        <path
          id="Vector_57"
          d="M126.909 103.016C126.759 102.347 126.262 101.324 125.432 101.072C124.047 100.653 123.495 102.12 123.691 103.006C123.853 103.743 124.333 104.858 125.142 105.266C125.776 105.586 126.507 105.355 126.825 104.822C127.139 104.294 127.034 103.572 126.91 103.016H126.909Z"
          fill="white"
        />
        <path
          id="Vector_58"
          d="M138.424 44.7125C137.848 41.6051 137.01 37.577 134.638 35.2779C132.533 33.2389 129.192 32.4379 126.355 32.3692C119.856 32.2121 113.733 36.1487 112 42.5268C111.638 43.8584 113.236 44.5429 113.718 43.2519C115.703 37.9432 120.737 34.6267 126.277 34.7755C128.789 34.842 131.739 35.6244 133.416 37.6166C135.156 39.6847 135.879 42.686 136.36 45.2826C136.61 46.6361 138.674 46.0628 138.425 44.7136L138.424 44.7125Z"
          fill="white"
        />
        <path
          id="Vector_59"
          d="M160.651 71.0917C160.394 70.9315 160.137 70.8993 159.912 70.9471C159.682 70.7276 159.351 70.6049 158.949 70.6902C152.738 72.0395 150.003 81.3014 148.761 86.4842C147.835 90.3334 147.139 95.1521 146.529 99.0657C146.379 100.034 146.234 101.004 146.106 101.979C145.967 102.996 145.833 104.013 145.715 105.03C145.496 106.914 143.498 110.341 142.519 112.193C142.337 112.542 140.598 116.953 140.683 116.958C140.683 116.958 142.766 117.081 145.48 117.178C146.695 117.22 148.029 117.258 149.361 117.269C150.796 117.285 152.225 117.28 153.484 117.232C155.417 117.162 156.964 116.99 157.537 116.67C159.491 115.562 161.034 112.971 161.633 110.941C162.511 107.965 163.17 104.902 163.758 101.856C164.904 95.9188 165.686 89.8423 165.862 83.7919C166.006 78.9097 165.069 73.8663 160.653 71.0928L160.651 71.0917Z"
          fill="#FFD37E"
        />
        <path
          id="Vector_60"
          d="M153.279 102.796C151.106 102.39 148.68 102.122 146.105 101.978C135.14 101.335 121.355 102.803 111.52 104.761C110.669 104.933 110.15 105.677 109.823 106.555C109.481 107.481 109.357 108.563 109.299 109.286C109.277 109.51 109.267 109.74 109.262 109.971C109.256 110.057 109.251 110.148 109.251 110.239C109.224 111.641 109.401 113.065 109.701 114.452C109.818 115.046 109.963 115.63 110.123 116.208C110.31 116.888 110.856 117.161 111.403 117.145C111.499 117.167 111.6 117.178 111.713 117.178H147.557C148.033 117.557 154.377 103.001 153.279 102.797V102.796Z"
          fill="#FFD37E"
        />
        <path
          id="Vector_61"
          d="M166.499 90.6704C166.234 95.5464 165.368 100.426 164.174 105.156C163.595 107.453 162.924 109.742 162.022 111.936C161.361 113.545 160.569 115.312 159.227 116.473C158.815 116.83 159.415 117.425 159.824 117.07C161.154 115.919 161.965 114.213 162.642 112.625C163.598 110.386 164.305 108.044 164.91 105.688C166.168 100.792 167.069 95.7243 167.343 90.6704C167.372 90.1274 166.528 90.1295 166.499 90.6704Z"
          fill="#C3995A"
        />
        <path
          id="Vector_62"
          d="M119.824 63.5141C119.294 61.7893 117.536 60.7521 116.462 59.3144C115.022 57.3857 114.631 54.9284 114.708 52.5763C114.742 51.5173 115.032 50.4613 114.923 49.3981C114.854 48.724 114.54 47.9781 113.982 47.5672C113.482 47.201 112.337 46.9857 111.922 47.563C110.231 49.91 108.256 53.111 108.449 56.1394C108.644 59.1677 111.182 62.0046 112.724 64.4712C112.733 64.4858 112.744 64.4962 112.753 64.5097C112.769 64.6512 112.785 64.7937 112.786 64.9352C112.804 66.6247 111.887 68.516 111.202 70.0098C111.156 70.1108 111.134 70.2096 111.127 70.3053C110.84 70.7027 110.895 71.4028 111.508 71.6223C113.482 72.3287 115.461 72.9134 117.576 72.4244C118.725 72.1591 120.519 65.7851 119.823 63.5173L119.824 63.5141Z"
          fill="#FDAF90"
        />
        <path
          id="Vector_63"
          d="M132.476 55.563C132.144 58.5831 130.049 60.9456 127.117 61.6925C126.591 61.8267 126.814 62.6413 127.342 62.5071C130.667 61.6603 132.947 58.9607 133.321 55.563C133.38 55.0231 132.535 55.0283 132.476 55.563Z"
          fill="#DE806E"
        />
        <path
          id="Vector_64"
          d="M140.688 43.6473C139.309 41.4252 135.673 41.4574 133.931 42.9555C131.768 44.8155 131.543 47.8782 132.16 50.5164C132.923 53.7778 137.469 55.8147 140.032 53.2202C142.274 50.9513 142.347 46.3188 140.688 43.6473Z"
          fill="white"
        />
        <path
          id="Vector_65"
          d="M119.119 72.4296C118.5 71.9052 115.842 69.6602 115.06 70.9419C114.729 71.4829 115.455 72.9414 115.602 73.4303C115.937 74.5487 116.213 75.8678 116.994 76.7739C118.085 78.04 119.887 78.169 121.387 77.68C121.459 77.6571 121.503 77.6124 121.529 77.5614C121.644 77.5635 121.988 76.9726 121.995 76.8218C122.089 74.9783 120.407 73.5198 119.119 72.4285V72.4296Z"
          fill="#FFD37E"
        />
        <path
          id="Vector_66"
          d="M202.725 89.7622C203.019 89.1131 203.123 88.389 202.924 87.6129C202.543 86.1409 201.261 85.4834 199.915 85.4793C200.312 84.5867 200.406 83.6452 199.794 82.815C198.506 81.0663 196.128 82.2595 194.696 83.0917C191.859 84.7406 189.135 86.9804 187.361 89.7705C185.564 92.594 184.818 97.3711 188.686 98.8972C192.059 100.228 196.483 98.7828 199.656 97.4761C199.681 97.4657 199.707 97.4553 199.733 97.4449C201.576 96.6782 203.534 95.6171 204.143 93.5677C204.627 91.9396 204.011 90.5653 202.725 89.7633V89.7622Z"
          fill="#FF8D82"
        />
        <path
          id="Vector_67"
          d="M115.791 80.8571C116.999 76.5055 118.278 71.6525 119.927 65.8257C120.067 65.3284 112.49 65.6041 112.518 66.7266C111.325 69.3679 110.089 71.719 108.823 73.9026C108.929 78.1409 112.185 81.2119 115.791 80.8571Z"
          fill="#FDAF90"
        />
        <path
          id="Vector_68"
          d="M121.509 76.75C121.477 76.7229 121.445 76.6803 121.397 76.6699C118.629 76.0707 116.702 75.1385 115.358 74.1107C114.25 73.2649 113.532 72.3495 113.061 71.4985C112.055 69.705 112.118 68.1955 111.834 68.1955C111.048 68.2007 110.078 68.1955 107.937 71.4028C107.551 71.9812 106.733 73.2608 105.673 74.9742C105.244 75.6649 104.778 76.4254 104.281 77.2389C103.525 78.4863 102.702 79.8512 101.861 81.2753C101.689 81.5645 101.519 81.8538 101.347 82.1482C98.3437 87.2665 95.2332 92.9039 94.0763 96.0415C93.578 97.4012 93.1286 99.0616 92.9143 100.774C92.6844 102.632 92.727 104.559 93.2732 106.24C93.7497 107.685 94.5954 108.959 95.9603 109.854C96.9351 110.49 98.0857 110.839 99.3122 110.973C100.517 111.102 101.791 111.026 103.033 110.813C104.805 110.508 106.513 109.93 107.878 109.276C113.463 106.61 121.338 83.5994 121.996 76.827C122.023 76.5378 121.724 76.8374 121.509 76.7521V76.75Z"
          fill="#FFD37E"
        />
        <path
          id="Vector_69"
          d="M118.718 72.3568C120.164 73.6405 121.483 74.944 121.384 77.0205C121.341 77.9339 121.179 78.8598 120.985 79.7534C120.574 81.6426 120.007 83.5006 119.429 85.344C118.02 89.8298 116.434 94.2637 114.745 98.6507C114.117 100.282 113.498 101.927 112.75 103.508C112.519 103.997 113.247 104.425 113.479 103.934C115.174 100.349 116.474 96.543 117.802 92.8124C119.34 88.4878 120.99 84.0707 121.888 79.5568C122.112 78.4322 122.346 77.1953 122.213 76.0592C122 74.2293 120.622 72.9206 119.315 71.7607C118.91 71.4007 118.31 71.9958 118.718 72.3578V72.3568Z"
          fill="#C3995A"
        />
        <path
          id="Vector_70"
          d="M118.507 75.8064C119.048 76.0197 119.622 76.0478 120.187 75.9281C120.719 75.8158 120.494 75.0012 119.962 75.1136C119.539 75.203 119.131 75.15 118.731 74.9919C118.516 74.9076 118.27 75.0844 118.211 75.2863C118.143 75.5255 118.296 75.7221 118.506 75.8054L118.507 75.8064Z"
          fill="#C3995A"
        />
        <path
          id="Vector_71"
          d="M113.807 49.5385C114.019 52.4993 114.416 55.4756 115.133 58.3604C115.646 60.4264 116.659 62.5164 118.895 63.0636C119.422 63.1926 119.648 62.3791 119.119 62.2491C116.688 61.654 116.108 58.9908 115.662 56.8675C115.156 54.4488 114.828 52.003 114.652 49.5385C114.614 48.9997 113.769 48.9955 113.807 49.5385Z"
          fill="#DE806E"
        />
        <path
          id="Vector_72"
          d="M152.586 71.1427C152.186 70.868 151.703 70.7245 151.151 70.7682C150.951 70.7734 150.751 70.8202 150.557 70.893C150.518 70.9065 150.48 70.918 150.441 70.9325C150.416 70.944 150.393 70.9596 150.368 70.971C150.182 71.0501 149.998 71.1375 149.828 71.2613C149.324 71.6296 148.991 72.1632 148.757 72.7573C148.193 73.8433 148.043 75.1188 148.664 76.3484C150.193 79.3768 153.702 77.2462 153.889 74.3625C153.974 73.0517 153.489 71.7627 152.585 71.1427H152.586Z"
          fill="white"
        />
        <path
          id="Vector_73"
          d="M161.817 84.8977C161.394 84.2766 160.734 83.8688 159.817 83.8688C158.932 83.8688 158.319 84.2912 157.818 84.8977C156.887 86.0264 156.406 87.6847 156.767 89.0725C156.993 89.9422 157.681 90.7203 158.685 90.9398C161.946 91.6525 163.267 87.0251 161.817 84.8977Z"
          fill="white"
        />
        <path
          id="Vector_74"
          d="M143.936 88.9362C144.027 88.0082 143.666 87.2041 143.088 86.5039C142.558 85.86 141.495 85.9848 140.945 86.5039C140.074 87.3289 139.419 88.5877 139.786 89.8413C139.837 90.0171 139.921 90.1731 140.015 90.3188C140.245 90.7432 140.588 91.1011 141.029 91.2977C141.75 91.6202 142.691 91.5307 143.102 90.7536C143.119 90.7224 143.129 90.686 143.143 90.6527C143.604 90.2158 143.873 89.5874 143.936 88.9362Z"
          fill="white"
        />
        <path
          id="Vector_75"
          d="M135.765 101.815C135.779 101.866 135.802 101.911 135.821 101.959C136.501 101.87 137.216 101.897 137.966 102.006C138.721 102.115 139.478 102.17 140.234 102.203C140.503 101.765 140.675 101.259 140.726 100.734C140.833 99.6254 140.402 98.6631 139.713 97.8257C139.078 97.0548 137.807 97.2046 137.15 97.8257C136.33 98.6028 135.671 99.5952 135.642 100.761C135.637 100.951 135.657 101.143 135.688 101.336C135.696 101.499 135.719 101.661 135.765 101.816V101.815Z"
          fill="white"
        />
        <path
          id="Vector_76"
          d="M154.895 96.3942C154.614 95.2873 153.68 94.299 152.439 94.3458C148.417 94.4988 148.32 102.185 152.175 101.369C154.277 100.925 155.371 98.272 154.895 96.3942Z"
          fill="white"
        />
        <path
          id="Vector_77"
          d="M143.044 112.981C143.544 112.225 143.626 111.147 142.934 110.444C140.696 108.162 136.128 112.212 138.666 114.041C140.05 115.039 142.194 114.265 143.043 112.981H143.044Z"
          fill="white"
        />
        <path
          id="Vector_78"
          d="M112.065 112.979C111.578 113.723 110.656 114.291 109.698 114.451C109.399 113.064 109.222 111.64 109.249 110.238C109.249 110.147 109.254 110.056 109.259 109.97C110.18 109.623 111.203 109.671 111.958 110.442C112.649 111.148 112.568 112.225 112.065 112.979Z"
          fill="white"
        />
        <path
          id="Vector_79"
          d="M127.795 108.249C128.285 107.48 128.329 106.364 127.591 105.606C125.196 103.153 120.624 107.199 123.313 109.193C124.779 110.28 126.965 109.553 127.795 108.25V108.249Z"
          fill="white"
        />
        <path
          id="Vector_80"
          d="M121.189 117.177H115.027C115.905 115.33 118.871 113.804 120.616 115.581C121.05 116.025 121.222 116.603 121.19 117.177H121.189Z"
          fill="white"
        />
        <path
          id="Vector_81"
          d="M161.351 108.321C161.386 107.397 160.886 106.415 159.911 106.167C156.75 105.365 154.915 111.318 158.098 111.566C159.833 111.701 161.292 109.891 161.352 108.321H161.351Z"
          fill="white"
        />
        <path
          id="Vector_82"
          d="M111.246 43.6264C110.498 44.9227 110.14 46.351 110.198 47.847C110.219 48.389 111.064 48.3911 111.043 47.847C110.99 46.4925 111.298 45.2275 111.975 44.0519C112.247 43.5807 111.517 43.1541 111.246 43.6254V43.6264Z"
          fill="#272221"
        />
        <path
          id="Vector_83"
          d="M137.022 42.9055C134.411 42.8608 133.213 45.3232 133.095 47.6088C132.967 50.0857 133.723 52.5222 136.168 53.5822C136.661 53.7965 137.092 53.0694 136.595 52.853C134.689 52.027 133.971 50.3209 133.929 48.3495C133.888 46.3635 134.531 43.7076 137.023 43.7502C137.567 43.7596 137.567 42.9149 137.023 42.9055H137.022Z"
          fill="#272221"
        />
        <path
          id="Vector_84"
          d="M150.671 79.6639C148.476 86.8182 146.911 94.1388 145.974 101.563C145.906 102.1 146.751 102.095 146.819 101.563C147.747 94.2158 149.315 86.9669 151.486 79.8876C151.646 79.3664 150.83 79.1438 150.671 79.6629V79.6639Z"
          fill="#C3995A"
        />
        <path
          id="Vector_85"
          d="M153.27 101.826C141.747 101.102 130.151 101.5 118.716 103.103C118.18 103.178 118.408 103.992 118.941 103.918C130.298 102.326 141.827 101.952 153.271 102.67C153.814 102.705 153.812 101.86 153.271 101.826H153.27Z"
          fill="#C3995A"
        />
        <path
          id="Vector_86"
          d="M149.468 100.558C151.554 100.03 153.659 99.8252 155.808 99.9323C156.351 99.9594 156.35 99.1147 155.808 99.0876C153.585 98.9763 151.399 99.1968 149.243 99.743C148.717 99.8762 148.939 100.691 149.468 100.558Z"
          fill="#C3995A"
        />
        <path
          id="Vector_87"
          d="M167.812 108.358C167.156 107.839 166.292 107.935 165.694 108.536C165.213 109.018 164.953 109.712 164.795 110.463C164.743 110.709 164.741 110.967 164.775 111.219C164.685 111.92 164.73 112.64 164.986 113.297C165.35 114.228 166.238 114.656 167 114.423C167.649 114.223 168.144 113.596 168.449 112.846C169.076 111.301 169.078 109.36 167.812 108.358Z"
          fill="white"
        />
        <path
          id="Vector_88"
          d="M202.135 89.732C201.155 90.0296 200.231 90.4571 199.374 91.0189C198.922 91.3154 199.344 92.0467 199.801 91.7481C200.6 91.2249 201.446 90.8244 202.36 90.5466C202.879 90.3895 202.658 89.5739 202.135 89.732Z"
          fill="#272221"
        />
        <path
          id="Vector_89"
          d="M199.069 85.5032C197.94 85.7185 196.929 86.2293 196.066 86.9867C195.657 87.3456 196.256 87.9417 196.663 87.5838C197.422 86.918 198.305 86.505 199.294 86.3178C199.828 86.2169 199.602 85.4023 199.069 85.5032Z"
          fill="#272221"
        />
        <path
          id="Vector_90"
          d="M186.413 50.5664C185.7 51.3175 185.099 52.157 184.628 53.0787C184.38 53.5614 185.108 53.989 185.357 53.5053C185.798 52.6449 186.345 51.8647 187.011 51.1635C187.386 50.7692 186.79 50.1711 186.414 50.5664H186.413Z"
          fill="#272221"
        />
        <path
          id="Vector_91"
          d="M117.724 78.1336C117.228 77.3024 116.111 76.9612 115.161 77.188C113.551 77.5719 112.991 79.2853 112.803 80.6439C112.742 81.0829 112.972 81.5115 113.326 81.8257C113.495 82.0775 113.727 82.299 114.032 82.4759C114.916 82.9888 115.964 82.8317 116.729 82.194C117.269 81.7435 117.595 81.0403 117.817 80.4078C118.079 79.6608 118.147 78.8379 117.725 78.1326L117.724 78.1336Z"
          fill="white"
        />
        <path
          id="Vector_92"
          d="M106.133 79.331C105.453 80.9747 103.997 82.8858 101.978 82.3885C101.743 82.3292 101.529 82.2491 101.347 82.1471C101.518 81.8527 101.689 81.5635 101.861 81.2743C102.701 79.8501 103.526 78.4852 104.28 77.2379C104.779 76.4244 105.244 75.6639 105.672 74.9731C106.925 75.9042 106.727 77.9391 106.132 79.331H106.133Z"
          fill="white"
        />
        <path
          id="Vector_93"
          d="M103.098 86.8171C101.491 86.8982 100.531 88.7 100.182 90.0868C100.103 90.402 100.116 90.7349 100.195 91.0512C100.223 91.7794 100.535 92.4608 101.281 92.9289C103.021 94.0192 104.713 92.131 105.184 90.6028C105.65 89.087 105.108 86.7162 103.098 86.8181V86.8171Z"
          fill="white"
        />
        <path
          id="Vector_94"
          d="M112.02 96.5377C111.395 95.6452 110.144 95.4048 109.221 95.9635C108.675 96.2933 108.22 96.828 107.928 97.4043C107.794 97.6675 107.715 97.9578 107.683 98.2532C107.355 99.1104 107.257 100.08 107.627 100.879C108.062 101.815 109.119 102.213 110.055 101.994C110.8 101.82 111.383 101.189 111.733 100.516C112.345 99.3445 112.845 97.7185 112.019 96.5388L112.02 96.5377Z"
          fill="white"
        />
        <path
          id="Vector_95"
          d="M105.847 99.6805C105.732 99.8033 105.794 99.744 105.847 99.6805V99.6805Z"
          fill="white"
        />
        <path
          id="Vector_96"
          d="M96.5772 103.664C96.1923 105.04 94.9231 106.63 93.2742 106.239C92.728 104.558 92.6854 102.63 92.9153 100.773C93.3116 100.436 93.7933 100.206 94.3603 100.13C95.4151 100.002 96.4003 100.579 96.6895 101.538C96.882 102.191 96.7592 103.026 96.5772 103.663V103.664Z"
          fill="white"
        />
        <path
          id="Vector_97"
          d="M103.034 110.811C101.792 111.025 100.518 111.1 99.313 110.971C99.3879 110.34 99.5918 109.734 99.9611 109.21C100.518 108.429 101.471 107.641 102.466 108.219C103.376 108.755 103.366 109.815 103.034 110.811Z"
          fill="white"
        />
        <path
          id="Vector_98"
          d="M129.251 90.0598C128.989 90.3167 128.646 90.4769 128.239 90.4665C127.308 90.4395 126.703 89.6311 126.446 88.7583C126.119 88.0249 125.991 87.1843 126.408 86.478C126.633 86.0868 127.013 85.835 127.426 85.7549C128.009 87.2322 128.63 88.6782 129.251 90.0598Z"
          fill="white"
        />
        <path
          id="Vector_99"
          d="M124.31 75.2197C123.758 75.6639 122.88 75.4174 122.527 74.8338C122.023 74.0036 121.916 73.11 122.05 72.1675C122.082 71.9479 122.195 71.7659 122.35 71.6265C122.403 71.5464 122.462 71.4663 122.532 71.4018C122.992 70.9732 123.581 70.9305 124.068 71.1719C123.983 72.3984 124.079 73.7685 124.31 75.2197Z"
          fill="white"
        />
        <path
          id="Vector_100"
          d="M120.577 97.112C120.427 96.4431 119.93 95.4205 119.1 95.1687C117.715 94.7495 117.163 96.2163 117.358 97.1027C117.521 97.8392 118.001 98.9544 118.81 99.3622C119.444 99.6827 120.175 99.4517 120.493 98.918C120.807 98.3906 120.702 97.6686 120.578 97.112H120.577Z"
          fill="white"
        />
        <path
          id="Vector_101"
          d="M132.216 97.6977C132.066 97.0288 131.57 96.0062 130.739 95.7544C129.354 95.3352 128.802 96.802 128.997 97.6884C129.159 98.4249 129.64 99.5401 130.448 99.9479C131.083 100.268 131.813 100.037 132.132 99.5037C132.446 98.9763 132.341 98.2543 132.217 97.6977H132.216Z"
          fill="white"
        />
        <g id="Group_3">
          <path
            id="Vector_102"
            d="M166.193 29.2307C165.762 28.568 165.223 27.9688 164.571 27.458C161.314 24.9093 156.287 25.7217 153.553 28.6762C150.797 31.6557 150.002 36.465 152.062 39.9823C152.102 40.051 152.143 40.1186 152.185 40.1852C152.397 40.516 152.399 40.9342 152.2 41.2723C151.927 41.7373 151.627 42.1857 151.296 42.6185C151.055 42.9358 151.331 43.3436 151.636 43.4549C152.672 43.8325 153.929 43.613 155.105 43.2135C155.625 43.0377 156.175 42.9899 156.714 43.0887C158.893 43.4882 161.308 43.0419 163.212 41.962C167.449 39.561 168.808 33.2505 166.192 29.2307H166.193Z"
            fill="white"
          />
          <path
            id="Vector_103"
            d="M161.388 32.2757C160.403 31.278 159.527 32.1217 158.812 33.2317C158.344 32.4754 157.103 32.0187 156.596 32.9384C156.206 33.6458 156.534 34.6747 156.846 35.3654C157.184 36.1155 158.1 37.0289 158.908 37.3171C159.564 37.5511 162.536 33.4398 161.389 32.2767L161.388 32.2757Z"
            fill="#FF8D82"
          />
        </g>
        <path
          id="Vector_104"
          d="M161.441 58.1503C160.156 60.9685 159.766 63.9895 160.297 67.0408C160.39 67.5755 161.204 67.3487 161.112 66.8161C160.624 64.0093 160.989 61.1682 162.172 58.5758C162.395 58.0847 161.668 57.6551 161.442 58.1492L161.441 58.1503Z"
          fill="#272221"
        />
        <path
          id="Vector_105"
          d="M127.574 48.2132C127.054 48.569 126.531 48.6126 125.916 48.6449C125.525 48.6657 124.647 48.3786 124.417 48.7614C124.226 49.0777 124.27 49.6145 124.726 49.6821C126.027 49.8756 127.15 49.7882 128.091 48.8686C128.57 48.4004 128.134 47.8303 127.575 48.2132H127.574Z"
          fill="#272221"
        />
        <path
          id="Vector_106"
          d="M116.682 49.2379C117.259 49.4501 117.512 49.4595 118.063 49.4376C118.412 49.4241 119.165 49.061 119.407 49.4199C119.606 49.7175 119.607 50.2064 119.224 50.3635C118.147 50.8046 117.13 50.7141 116.284 50.1066C115.769 49.7372 116.093 49.0205 116.683 49.2379H116.682Z"
          fill="#272221"
        />
        <path
          id="Vector_107"
          d="M120.552 49.4168C119.94 50.0077 119.284 50.6715 119.52 51.5984C119.747 52.4899 120.569 52.9747 121.418 53.1401C121.948 53.2431 122.176 52.4296 121.642 52.3256C121.1 52.2205 120.466 51.9854 120.334 51.3737C120.211 50.8077 120.786 50.3635 121.148 50.014C121.539 49.6353 120.942 49.0392 120.551 49.4168H120.552Z"
          fill="#DE806E"
        />
        <path
          id="Vector_108"
          d="M122.486 52.1736C122.67 52.1008 122.869 52.0655 123.058 52.1487C123.266 52.2413 123.512 52.209 123.636 51.9968C123.741 51.8168 123.694 51.512 123.484 51.4194C123.088 51.2447 122.671 51.1968 122.261 51.3591C122.049 51.4434 121.901 51.6421 121.967 51.8782C122.022 52.08 122.273 52.2579 122.486 52.1726V52.1736Z"
          fill="#DE806E"
        />
        <path
          id="Vector_109"
          d="M129.058 45.6197C129.037 45.5188 128.998 45.4199 128.941 45.3253C128.587 44.7458 127.75 44.5752 127.165 44.4785C126.239 44.3255 125.223 44.4826 124.467 45.1099C123.757 45.6987 123.532 46.5757 124.318 47.2249C124.951 47.7471 125.893 47.1229 126.584 47.1104C127.165 47.099 127.97 47.3716 128.481 46.9627C128.852 46.6673 129.163 46.1201 129.059 45.6197H129.058Z"
          fill="#912D2C"
        />
        <path
          id="Vector_110"
          d="M116.351 48.0374C116.916 48.2475 117.537 47.7929 118.048 47.6431C118.654 47.4652 119.658 47.7409 120.056 47.1188C120.552 46.3458 120.099 45.654 119.309 45.344C118.466 45.0132 117.535 45.1578 116.773 45.5438C116.291 45.7872 115.611 46.1638 115.472 46.7588C115.45 46.8556 115.445 46.9513 115.457 47.0439C115.512 47.5016 115.943 47.8865 116.351 48.0384V48.0374Z"
          fill="#912D2C"
        />
        <path
          id="Vector_111"
          d="M125.365 53.4886C124.969 53.188 124.554 53.5063 124.136 53.6561C123.099 54.0275 122.15 54.1648 121.068 53.9099C120.639 53.809 119.771 53.938 119.913 54.5768C120.007 54.995 120.714 55.1895 121.061 55.3206C121.783 55.5942 122.574 55.6483 123.324 55.4569C124.117 55.254 125.285 54.6735 125.489 53.7903C125.518 53.6623 125.465 53.5635 125.366 53.4876L125.365 53.4886Z"
          fill="#E85546"
        />
        <path
          id="Vector_112"
          d="M112.637 51.4944C112.156 52.9768 111.664 54.5466 111.955 56.1217C112.257 57.755 113.494 58.6912 114.343 60.026C114.635 60.4827 115.365 60.0603 115.073 59.5994C114.361 58.4811 113.215 57.6104 112.856 56.2944C112.438 54.7651 112.981 53.1672 113.452 51.7191C113.62 51.201 112.805 50.9794 112.637 51.4944Z"
          fill="#DE806E"
        />
        <path
          id="Vector_113"
          d="M81.2929 55.8324C83.0635 56.958 84.2182 58.7058 84.6021 60.7635C84.702 61.2972 85.5155 61.0714 85.4167 60.5388C84.9932 58.2678 83.6658 56.3401 81.7194 55.1021C81.2596 54.8098 80.8351 55.5401 81.2929 55.8314V55.8324Z"
          fill="#272221"
        />
      </g>
      <defs>
        <clipPath id="clip0_9468_7521">
          <rect
            width="245.909"
            height="160"
            fill="white"
            transform="translate(0.0454102 0.00201416)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EmptyStateAssignedToMe;
