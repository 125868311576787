export interface ICategoryNode {
  id: string;
  name: string;
  title: string;
  description: string;
  color: string;
  assets?: {
    banners: Record<string, string>;
  };
  pattern: string;
}

export interface ICategoryEdge {
  node: ICategoryNode;
}

export interface ICategoriesData {
  categories: {
    edges: ICategoryEdge[];
  };
}

export interface IGraphQLResponse<T> {
  data: T;
}
export interface IRendition {
  contentType: string;
  id: string;
  url: string;
}
export interface ICaption {
  id: string;
  language: string;
  label: string;
  default: boolean;
  isDefault: boolean;
  url: string;
}
export interface IAsset {
  thumbnailUrl?: string | null;
  url: string;
  renditions?: IRendition[];
  captions?: ICaption[];
}
export interface IOutcome {
  name: string;
}

export interface IQuestProgress {
  completed: boolean;
  completedAt: string | null;
  ended: boolean;
  endedAt: string | null;
  enrolledAt: string | null;
  enrollmentStartedAt: string | null;
  started: boolean;
  startedAt: string | null;
  totalDays: number;
  totalDaysCompleted: number;
  totalDaysMissed: number;
  totalIntros: number;
  totalIntrosCompleted: number;
  totalLessons: number;
  totalLessonsCompleted: number;
  totalLessonsMissed: number;
}

export interface IQuestNode {
  authors: IAuthor[];
  coverAsset: IAsset | null;
  description: string;
  headshotCoverAsset: IAsset | null;
  id: string;
  name: string;
  userProgress?: IUserProgress;
  resources: IResource[];
  type: string;
  url: string;
  owned?: boolean | null;
  courseEndedAt?: string;
  courseStartedAt?: string;
  enrollmentStartedAt?: string;
  trailerAsset?: IAsset;
  wordmarkAsset?: IAsset | null;
  slug: string;
  language: string;
  currentAssignment?: IWorkwayQuestAssignment;
  releases?: IRelease[];
}

export interface IQuestEdge {
  node: IQuestNode;
}

export interface IMeditationEdge {
  node: IMeditation;
}

export interface IQuestsData {
  quests: {
    edges: IQuestEdge[];
    totalCount: number;
  };
}

export interface ICategory {
  name: string;
}

export interface IMarker {
  id: string;
  status: string;
  name: string;
  time: number;
}

export interface IPrimaryAsset {
  contentType: string;
  edgeUrl: string;
  url: string;
  name: string;
  captions: ICaption[];
  markers: IMarker[];
}

export interface ITask {
  id: string;
  completed: boolean;
  description: string;
  name: string;
  required: boolean;
}

export interface IPage {
  id?: string;
  name?: string;
  groupName?: string;
  duration: number;
  preview?: boolean;
  coverAsset?: IAsset;
  position: number;
  description?: string;
  owned?: boolean;
  tasks?: ITask[];
  sections: ISection[];
  completed: boolean;
  locked: boolean;
  type: string;
  url?: string;
  nextPage?: {
    id: string;
  };
}

export interface IGroup {
  id: string;
  type: string;
  name: string;
  description: string;
  locked: boolean;
  secondsTillUnlock: number;
  unlockAfterDays: number;
  position: number;
}

export interface IWordmarkAsset {
  url: string;
}

export interface IGrothArea {
  name: string;
}

export interface ISectionInfo {
  id: string;
  body: string;
  title: string;
  caption: string;
  mode: string;
  downloadable: boolean;
  link: string;
  enrollButtonText: string;
}

export interface ISection {
  info: ISectionInfo;
  id: string;
  type: string;
  position: number;
  duration: string;
  coverAsset: IAsset;
  primaryAsset: IPrimaryAsset | null;
  media: IMedia;
}

export interface IMaterial extends ISection {}

export interface IRelease {
  id: string;
  publishedAt: string;
  perpetual: boolean;
}

export interface IQuest {
  name: string;
  enrollmentsCount?: number;
  duration?: number;
  lessonsCount?: number;
  trailerAsset?: IAsset;
  outcomes?: IOutcome[];
  owned: boolean;
  groups?: IGroup[];
  releases?: IRelease[];
  growthAreas: IGrothArea;
  authors: IAuthor[];
  materials: IMaterial[];
  categories: ICategory[];
  coverAsset: IAsset | null;
  description: string;
  id: string;
  courseStartedAt?: string;
  pages: IPage[];
  headshotCoverAsset?: IAsset;
  userProgress?: IUserProgress;
  resources?: IResource[];
  type: string;
  url: string;
  wordmarkAsset: IWordmarkAsset;
  slug: string;
  language: string;
  currentAssignment?: IWorkwayQuestAssignment;
}

export interface IWorkwayQuestAssignment {
  id: string;
  startDate: string;
  endDate: string;
}

export interface IRecommendedQuestNode {
  quest: IQuest;
}

export interface IRecommendedQuestEdge {
  node: IRecommendedQuestNode;
}

export interface IRecommendedQuestResponse {
  data: {
    recommendedB2bQuestsPickedForYou: {
      edges: IRecommendedQuestEdge[];
    };
  };
}

export interface IData {
  series: ISeries[];
}

export interface ISeries {
  authors: IAuthor[];
  coverAsset: ICoverAsset;
  description: string | null;
  featured: boolean;
  id: string;
  media: IMedia[];
  publishedAt: string;
  slug: string;
  subtitle: string;
  title: string;
}

export interface ICoverAsset {
  thumbnailUrl: string | null;
  edgeUrl: string;
  url: string;
}

export interface IMedia {
  author: IAuthor;
  coverAsset: ICoverAsset;
  id: string;
  type: string;
  description: string;
  mediaAsset: IMediaAsset;
  title: string;
}

export interface IMediaAsset {
  contentType: string;
  duration?: number;
  edgeUrl?: string;
  thumbnailUrl?: string | null;
  url: string;
  captions: ICaption[];
}

export interface IApiResponse {
  data: IData;
}

export interface IApiResponse {
  data: IData;
}

export interface IData {
  meditations?: IMeditation[];
}

export interface IMeditation {
  resource: IResource;
}

export interface IResource {
  author: IAuthor;
  coverAsset?: IAsset | null;
  ctaQuest?: string | null;
  description?: string | null;
  globalId?: string | null;
  id: string;
  info?: string | null;
  mediaAsset?: IMediaAsset | null;
  title?: string;
  totalDuration?: number;
  type: string;
}

export interface IAuthor {
  name: string;
  bioTitle?: string;
  avatarAsset?: IAsset;
  bannerAsset?: IAsset;
  description?: string;
  headline?: string;
}

export interface ICoverAsset {
  thumbnailUrl: string | null;
  url: string;
}

export interface ICategoryNodeAdditionalInfo {
  [key: string]: {
    title: string;
    description: string;
    color?: string;
    pattern?: string;
  };
}

export interface ICurrentOrganizationResponse {
  currentOrganization: ICurrentOrganization | null;
}

export interface ICurrentOrganization {
  name: string;
  division: string;
  logoFullUrl: string;
  logoSquareUrl: string;
}

export interface AuthorI {
  name: string;
}

export interface CoverAssetI {
  thumbnailUrl: string | null;
  url: string;
}

export interface MediaAssetI {
  contentType: string;
  duration?: number;
  edgeUrl?: string;
  url: string;
}

export interface MediaI {
  author: AuthorI;
  mediaAsset: MediaAssetI;
  coverAsset?: CoverAssetI;
  id?: string;
  title: string;
}

export interface ResourceI {
  author: AuthorI;
  coverAsset: CoverAssetI;
  description: string;
  globalId: string;
  id: string;
  info: string | null;
  mediaAsset: MediaAssetI | undefined;
  title: string;
}
export interface AssetI {
  url: string;
}

export interface PrimaryAssetI {
  contentType: string;
  edgeUrl?: string;
  url: string;
}
export interface SectionI {
  primaryAsset?: PrimaryAssetI;
}

export interface PageI {
  position: number;
  sections: SectionI[];
}

export interface CategoriesI {
  name: string;
}

export interface QuestI {
  authors: AuthorI[];
  coverAsset: CoverAssetI;
  description: string;
  headshotCoverAsset?: AssetI | undefined | null;
  id: string;
  name: string;
  type: string;
  url: string;
  wordmarkAsset: AssetI | undefined;
  pages: PageI[];
  categories?: CategoriesI[];
}

export interface SeriesI {
  authors: AuthorI[];
  coverAsset: CoverAssetI;
  description: null | string;
  featured: boolean;
  id: string;
  media: MediaI[];
  publishedAt: string;
  slug: string;
  subtitle: string;
  title: string;
}

export interface MeditationI {
  resource: ResourceI;
}

export enum QuestCategoryEnum {
  Career = "57",
  Collaboration = "59",
  Soul = "30",
  Mindset = "54",
  Health = "55",
  Intrapreneurship = "56",
}

export interface CategoryContentPropsI {
  id: string;
  title: string;
  bannerImage?: string;
  description: string;
  questData: IQuestNode[];
  headline: string;
  category: ICategoryNode;
}

export interface MarkPageAsCompletedInput {
  pageId: string;
}

export interface Categories {
  edges: CategoryEdge[];
}

export interface CategoryEdge {
  node: CategoryNode;
}

export interface CategoryNode {
  id: string;
  name: string;
}

export enum QuestStatus {
  AssignedToMe = "AssignedToMe",
  Completed = "completed",
  InProgress = "in-progress",
}

export interface IMindvalleyRegion {
  id: number;
  code: string;
  name: string;
  countries: [ICountry];
}

export interface ICountry {
  id: number;
  iso3Code: string;
  name: string;
  mindvalleyRegion?: IMindvalleyRegion;
  cities?: [ICity];
}

export interface ICity {
  id: number;
  name: string;
  cityCode: string;
  country: ICountry;
}

export interface ICityEdgeFragment {
  node: ICity;
  cursor: string;
}

export interface IOption {
  name: string;
  value: string;
}

export interface IUserLevel {
  level: number;
}

export interface ICurrentProfile {
  avatarUrl: string | null;
  bio: string | null;
  country: string | null;
  email: string;
  firstName: string;
  industry: string | null;
  lastName: string;
  location: string | null;
  role: string;
  spokenLanguages: ISpokenLanguage[];
  tags: string[];
  userLevel: IUserLevel;
  orgName?: string;
  orgLogo?: string;
  lastUpdatedAt?: string;
}

export interface IUserProgress {
  totalDays: number;
  totalDaysCompleted: number;
  totalLessons: number;
  totalLessonsCompleted: number;
  completed: boolean;
  started: boolean;
  enrolledAt: string;
  resumePage: IPage;
}

export enum IQuestProgressInput {
  Ongoing = "ongoing",
  Available = "available",
  Completed = "completed",
}

export interface IUpdateProfileInput {
  avatarUrl?: string;
  bio?: string;
  email?: string;
  lastName?: string;
  location?: string;
  spokenLanguagesIds?: number[];
}

export interface IMessage {
  code?: string;
  field?: string;
  message?: string;
}

export interface ISpokenLanguage {
  displayName?: string;
  id?: number;
  iso2Code?: string;
}

export interface IUpdateCurrentProfile {
  result: ICurrentProfile;
  successful: boolean;
  messages: IMessage[];
}
