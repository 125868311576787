import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { app, stageView } from "@microsoft/teams-js";
import { teamsConfig } from "config/teamsConfig";
import { cn } from "libs/classMerger";
import dateFormater from "libs/dateFormater";
import { useNavigate } from "react-router-dom";
import type { IResource } from "types/interfaces";
import ProgressBar from "./ProgressBar";
import Icons from "./icons/Icons";
import { Label } from "./ui/label";
import { Tooltip } from "./ui/tooltip";

const cardVariants = cva("flex flex-col overflow-hidden  w-full", {
  variants: {
    variant: {
      programCard: "flex flex-col overflow-hidden  w-fit",
      programCardInprogress: "flex w-full rounded-xl border border-cool-grey-250  hover:shadow-light",
      default: "flex overflow-hidden w-full",
    },
    size: {
      default: "",
      sm: "",
      lg: "",
    },
  },
  defaultVariants: {
    variant: "default",
    size: "default",
  },
});

export interface CardProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof cardVariants> {
  asChild?: boolean;
  imageUrl?: string;
  title: string;
  author: string;
  totalLessons?: number;
  totalLessonsCompleted?: number;
  availableDays?: number;
  duration?: number;
  pages?: IResource[];
}

const Card = React.forwardRef<HTMLButtonElement, CardProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      imageUrl = "https://assets.mindvalley.com/api/v1/assets/96164bb0-7364-42cd-b046-9c02fe9b0c09.jpg",
      title,
      author,
      totalLessons,
      totalLessonsCompleted,
      availableDays,
      duration,
      pages,
      ...props
    },
    ref,
  ) => {
    const formattedDueDate = dateFormater(availableDays);
    // mobile chapter/detaile view container
    const DetailsViewMobile = () => {
      return (
        <div className="lg:hidden block px-4 ">
          <div className="md:p-0 ">
            <p className=" title-9 text-start line-clamp-2	  ">{title}</p>
            <span className="flex justify-between gap-2 w-fit title-10">
              <p>lesson {(totalLessonsCompleted ?? 0) + 1}</p>
              <p>•</p>
              <p> {duration ? Math.round(duration / 60) : 0} mins</p>
            </span>
          </div>
          <Label className="hidden lg:flex w-fit gap-2 bg-cool-grey-250 rounded p-1 ">
            <Icons name="calendar-reminder-outlined" width={14} height={14} fill="black" />
            <p className="title-10">Complete by {formattedDueDate}</p>
          </Label>
        </div>
      );
    };

    // mobile progress bar container
    const ProgressElementMobile = () => {
      const navigate = useNavigate();
      const handleClickPlayIcon = () => {
        if (!pages || !Array.isArray(pages) || pages.length === 0) {
          console.error("Pages array is empty or invalid");
          return;
        }

        const mediaAsset = pages[0].mediaAsset;
        if (!mediaAsset) {
          console.error("No media asset found");
          return;
        }

        // [ ] replace with continue in quest consumption
        // navigate(
        //   `/stageView?mediaUrl=${encodeURIComponent(
        //     mediaAsset.url
        //   )}&thumbnailUrl=${encodeURIComponent(
        //     imageUrl
        //   )}&autoplay=true&mimeType=${
        //     mediaAsset.contentType
        //   }&isStageViewSupported=false`
        // );
      };
      const handleClickMenuIcon = () => {
        // cta function
        console.log("menu icon click");
      };

      return (
        <div className="lg:hidden flex w-full justify-between p-[6px] items-center gap-10  ">
          <span className="flex flex-col lg:w-3/5 w-full justify-center items-start">
            <ProgressBar
              value={totalLessonsCompleted}
              maxValue={totalLessons}
              containerClasses="my-2"
              progressColor="bg-yellow-500"
            />
            <p className="title-10">
              {totalLessonsCompleted} / {totalLessons} lessons completed
            </p>
          </span>
          <div className="flex gap-2">
            <button
              className="bg-red-600 rounded-full w-9 h-9 flex items-center justify-center hover:bg-gradient-to-b from-red-400 to-red-600"
              onClick={handleClickPlayIcon}
              type="button"
            >
              <Icons name="play-filled" width={20} height={21} fill="white" />
            </button>
            <button
              className=" border border-cool-grey-250 rounded-full w-9 h-9 flex items-center justify-center  "
              onClick={handleClickMenuIcon}
              type="button"
            >
              <Icons name="list-filled" height={20} width={20} fill="#595E67" />
            </button>
          </div>
        </div>
      );
    };

    // web view
    const DetailsView = () => {
      const navigate = useNavigate();

      const handleClickPlayIcon = () => {
        if (!pages || !Array.isArray(pages) || pages.length === 0) {
          console.error("Pages array is empty or invalid");
          return;
        }

        // const mediaAsset = pages
        //   .flatMap((page) => page.sections) // Flatten the array of arrays
        //   .find((section) => section.primaryAsset && section.primaryAsset.contentType === "video/mp4")?.primaryAsset;

        const mediaAsset = pages[0].mediaAsset;
        if (!mediaAsset) {
          console.error("No media asset found");
          return;
        }

        app.initialize().then(() => {
          if (stageView.isSupported()) {
            stageView
              .open({
                appId: teamsConfig.appId,
                contentUrl: `${window.location.origin}/stageView?mediaUrl=${mediaAsset.url}&thumbnailUrl=${imageUrl}&autoplay=true`,
                title: title,
                websiteUrl: window.location.origin,
                openMode: stageView.StageViewOpenMode.modal,
                threadId: "",
              })
              .catch((error) => {
                console.error(error);
              });
          } else {
            navigate(
              `/stageView?mediaUrl=${encodeURIComponent(mediaAsset.url)}&thumbnailUrl=${encodeURIComponent(
                imageUrl,
              )}&autoplay=true&mimeType=${mediaAsset.contentType}&isStageViewSupported=false`,
            );
          }
        });
        // cta function
      };
      const handleClickMenuIcon = () => {
        // cta function
        console.log("menu icon click");
      };
      return (
        <div className="lg:flex items-start  flex-col w-full justify-between lg:px-6 lg:py-4 gap-3  hidden">
          <div className="flex flex-col gap-y-4">
            <div className="md:p-0 ">
              <p className="lg:heading-8 md:title-9 text-start  ">{title}</p>
              <span className="flex justify-between gap-2 w-fit title-10">
                <p>lesson {(totalLessonsCompleted ?? 0) + 1}</p>
                <p>•</p>
                <p>{duration ? Math.round(duration / 60) : 0} mins</p>
              </span>
            </div>
            <Label className="hidden lg:flex w-fit gap-2 bg-cool-grey-250 rounded py-1 px-[6px] ">
              <Icons name="calendar-reminder-outlined" width={14} height={14} fill="black" />
              <p className="title-10">Complete by {formattedDueDate}</p>
            </Label>
          </div>
          <div className="flex w-full justify-between">
            <span className="flex flex-col w-4/5 justify-center items-start">
              <ProgressBar
                value={totalLessonsCompleted}
                maxValue={totalLessons}
                containerClasses="my-2"
                progressColor="bg-yellow-500"
              />
              <p className="title-10">
                {totalLessonsCompleted} / {totalLessons} lessons completed
              </p>
            </span>
            <div className="flex gap-2">
              <button
                className="bg-red-600 rounded-full w-9 h-9 flex items-center justify-center hover:bg-gradient-to-b from-red-400 to-red-600"
                onClick={handleClickPlayIcon}
                type="button"
              >
                <Icons name="play-filled" width={20} height={21} fill="white" />
              </button>
              <Tooltip tooltipContent={<p className="caption-disclaimer">Learn more</p>}>
                <button
                  className=" border border-cool-grey-250 rounded-full w-9 h-9 flex items-center justify-center hover:bg-cool-grey-200 "
                  onClick={handleClickMenuIcon}
                  type="button"
                >
                  <Icons name="list-filled" height={20} width={20} fill="#595E67" />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      );
    };

    const Comp = asChild ? Slot : "button";

    return (
      <Comp className={cn(cardVariants({ variant, size, className }))} ref={ref} {...props}>
        <div className={`${variant === "programCard" ? "flex flex-col items-start" : "flex w-full "}`}>
          <div className=" flex flex-col lg:w-fit w-full">
            <div className="flex border-b border-cool-grey-250  items-center ">
              <img
                src={imageUrl}
                alt="quests"
                className={` lg:max-w-[302px] lg:w-[302px] lg:h-[170px] lg:max-h-[170px] md:max-w-[200px] md:max-h-[112px] md:w-[200px] md:h-[112px] 
                  sm:max-w-[180px] sm:max-h-[100px] w-[180px] h-[100px] object-contain aspect-video `}
              />

              <DetailsViewMobile />
            </div>
            <div className="lg:hidden p-[6px] ">
              <ProgressElementMobile />
            </div>
          </div>

          <>
            {variant === "programCard" ? (
              <>
                <div className="title-7">{title}</div>
                <div className="text-body-sm text-cool-grey-450">{author}</div>
              </>
            ) : (
              <DetailsView />
            )}
          </>
        </div>
      </Comp>
    );
  },
);
Card.displayName = "Card";

export { Card, cardVariants };
