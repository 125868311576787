import useCuratedMeditationSeries from "hooks/useCuratedMeditationSeries";
import { BannerImage } from "tabs/Home/components/BannerImage";

interface MoreCuratedSectionProps {
  handleSelect: (id: string) => void;
}

export function MoreCuratedSection({ handleSelect }: MoreCuratedSectionProps) {
  const { curatedData } = useCuratedMeditationSeries();

  return (
    <div>
      <h6 className="heading-6">More curated series</h6>
      <div className="flex flex-col md:flex-row gap-4 mt-4">
        {curatedData.map((series) => (
          <button
            type="button"
            key={series.id}
            className="w-full md:w-1/3 aspect-video"
            onClick={() => handleSelect(series.id)}
          >
            <BannerImage src={series.coverAsset.url} alt={series.title} />
          </button>
        ))}
      </div>
    </div>
  );
}
