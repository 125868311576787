import { useEffect } from "react";
import "./hubspotForm.css";

const HubspotForm = ({
  portalId,
  formId,
}: {
  portalId: string;
  formId: string;
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/embed/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      // @ts-ignore
      if (window.hbspt) {
        // @ts-ignore
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: "#hubspotForm",

          onFormReady: function ($form: HTMLElement) {
            const hsEmailInput = $form.querySelector(
              "div > .input input[name='email']"
            );
            const hsFirstnameInput = $form.querySelector(
              "div > .input input[name='firstname']"
            );
            const hsLastnameInput = $form.querySelector(
              "div > .input input[name='lastname']"
            );
            if (hsEmailInput && hsFirstnameInput && hsLastnameInput) {
              //@ts-ignore=
              hsEmailInput.value = "testemail@gmail.com"
                ? "testemail@gmail.com"
                : "";
              // @ts-ignore
              hsFirstnameInput.value = "testfirstname" ? "testfirstname" : "";
              //@ts-ignore
              hsLastnameInput.value = "testlastname" ? "testlastname" : "";
              console.log(hsEmailInput, hsFirstnameInput, hsLastnameInput);
            }
          },
        });
      }
    });
  }, []);

  return (
    <div className="flex flex-col items-center justify-center m-auto p-4">
      <div id="hubspotForm" className="w-full"></div>
    </div>
  );
};

export default HubspotForm;
