import "./Quest.css";
import ProgressBar from "components/ProgressBar";
import Icons from "components/icons/Icons";
import { getFormattedDate } from "tabs/Programs/programs-utils";
import type { IQuestNode } from "types/interfaces";

export default function Quest(props: {
  quest: IQuestNode;
}) {
  const coverUrl = props.quest.coverAsset?.url;
  const author = props.quest.authors?.length > 0 && props.quest.authors[0].name;
  const progress = props.quest.userProgress;
  const name = props.quest.name;
  const assignment = props.quest.currentAssignment;
  const completedCount = props.quest.type === "weekly" ? progress?.totalLessonsCompleted : progress?.totalDaysCompleted;
  const totalCount = props.quest.type === "weekly" ? progress?.totalLessons : progress?.totalDays;

  return (
    <button type="button" className="cursor-pointer rounded-2xl">
      <div className="overflow-hidden flex md:gap-0 flex-col justify-start items-start">
        <div className="rounded-2xl w-full relative flex-grow h-auto mb-3 mr-3 md:mr-0 overflow-hidden">
          <img
            className="rounded-2xl object-contain transition-all ease-in-out duration-500 hover:scale-105 "
            alt={name}
            src={coverUrl}
          />
          <div className="flex flex-col items-center gap-0 rounded-2xl justify-center w-full absolute bottom-0">
            {!progress?.completed && progress?.started && (
              <ProgressBar
                containerClasses="bg-black-18a border-t border-white-12a"
                progressClasses="!bg-yellow-500"
                value={completedCount}
                maxValue={totalCount}
              />
            )}
            {assignment && (
              <div
                className="rounded-b-2xl backdrop-blur self-stretch px-4 py-2 text-white justify-start items-start inline-flex"
                style={{ background: "rgba(0, 0, 0, 0.40)" }}
              >
                <div className="justify-start items-center gap-1.5 flex">
                  <div className="justify-center items-center flex">
                    <Icons name="calendar-reminder-outlined" width={12} height={12} fill="white" />
                  </div>
                  <div className="text-white title-10">Complete by {getFormattedDate(assignment.endDate ?? "")}</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex w-full flex-col flext-start gap-1 text-left">
          <div className="w-full flex flex-row justify-between gap-2">
            <p
              className="title-8 h-full md:title-7 text-cool-grey-700 text-ellipsis line-clamp-2 md:line-clamp-1"
              data-testid="quest-title"
            >
              {name}
            </p>
            {progress?.completed && (
              <div>
                <Icons name="checklist-circle-filled" height={20} width={20} fill="#159F65" />
              </div>
            )}
          </div>
          <p className="body-small text-cool-grey-450 text-ellipsis line-clamp-1" data-testid="quest-author">
            {author}
          </p>
        </div>
      </div>
    </button>
  );
}
