export const QuestConsumptionSkeleton = () => {
  return (
    <section className="bg-white flex flex-col h-screen lg:overflow-auto mt-16 w-full">
      <div className="flex-1 flex flex-col lg:flex-row overflow-y-auto w-full">
        <main className="lg:h-[calc(100dvh-20px)] flex flex-col lg:flex-1 lg:overflow-y-auto no-scrollbar sticky lg:static top-0 z-10 touch-auto">
          <div className="lg:hidden px-4 md:px-9 py-3 bg-brown-50 space-y-0.5">
            <div className="w-full h-[20px] bg-brown-100 rounded-2xl animate-pulse" />
            <div className="w-full h-[12px] bg-brown-100 rounded-2xl animate-pulse" />
          </div>
          <div className="w-full h-[551px] bg-brown-100 animate-pulse" />
          <div className="hidden lg:flex flex-col py-6 pl-20 pr-6 gap-1 w-full">
            <div className="w-[65%] h-[24px] rounded-2xl bg-brown-100 animate-pulse" />
            <div className="w-1/2 h-[20px] rounded-2xl bg-brown-100 animate-pulse" />
            <div className="w-[35%] h-[40px] rounded-2xl my-2 bg-brown-100 animate-pulse" />
            <div className="w-full h-[20px] rounded-2xl bg-brown-100 animate-pulse" />
            <div className="w-[80%] h-[20px] rounded-2xl bg-brown-100 animate-pulse" />
            <div className="w-[70%] h-[20px] rounded-2xl bg-brown-100 animate-pulse" />
          </div>
        </main>
        <aside className="xl:w-[520px] lg:w-[460px] md:w-[360px] lg:bg-brown-50 overflow-y-auto relative flex-1 lg:flex-none pb-[50px] lg:pb-0">
          <div className="pt-4 pr-9 pl-6 flex flex-col">
            <div className="hidden lg:flex flex-row gap-3 items-center">
              <div className="w-[25%] h-[56.25px] rounded-lg bg-brown-100 animate-pulse" />
              <div className="flex flex-col gap-1 w-[75%]">
                <div className="w-full h-[20px] bg-brown-100 rounded-2xl animate-pulse" />
                <div className="w-full h-[12px] bg-brown-100 rounded-2xl animate-pulse" />
              </div>
            </div>
            <div className="hidden lg:block w-full h-[36px] mt-4 bg-brown-100 rounded-2xl animate-pulse bg-white" />
            <div className="flex flex-row mt-6 gap-x-6 w-full lg:w-1/2">
              <div className="w-1/2 pb-5 border-b-2 border-b-red-600 ">
                <div className="w-full h-[18px] bg-brown-100 rounded-2xl animate-pulse" />
              </div>
              <div className="w-1/2 h-[18px] bg-brown-100 rounded-2xl animate-pulse" />
            </div>
          </div>

          <div className="border-b border-b-cool-grey-300" />
          <div className="pt-4 pr-9 pl-6 flex flex-col gap-1">
            {[1, 2, 3, 4, 5].map((val) => (
              <div key={val}>
                <div className="w-1/2 h-[20px] bg-brown-100 rounded-2xl animate-pulse" />
                {[1, 2, 3, 4, 5, 6].map((val1) => (
                  <div key={val1} className="w-full h-[12px] bg-brown-100 rounded-2xl animate-pulse" />
                ))}
                <div className="w-full h-[12px] mb-6 bg-brown-100 rounded-2xl animate-pulse" />
              </div>
            ))}
          </div>
        </aside>
      </div>
    </section>
  );
};
