import { CheckIcon } from "@radix-ui/react-icons";
import { DrawerClose } from "components/ui/drawer";
import { cn } from "libs/classMerger";
import type { TabContent, TabSection } from "./Profile";

export const NavMenu = ({
  currentOption,
  title,
  tabBtn,
  options,
  onSelectOption,
}: {
  title: string;
  tabBtn: JSX.Element;
  options: TabContent[];
  onSelectOption: (option: TabSection) => void;
  currentOption?: TabSection;
}) => {
  return (
    <div className="w-full bg-white justify-center align-center rounded-tr-lg rounded-tl-lg">
      <div className="grid px-2 py-[6px] justify-items-center">
        <div className="w-[80px] h-[6px] rounded-sm bg-cool-grey-300"> </div>
      </div>
      <div className="flex justify-center py-3 px-9 heading-8">{title}</div>
      <div className="h-[1px] rounded-sm bg-cool-grey-300"> </div>
      <div className="flex justify-between flex-col h-full min-h-[320px] pb-3">
        <div className="flex">
          <div className="w-full flex flex-col justify-start align-start gap-2 self-stretch py-3">
            {options.map((section: TabContent) => (
              <DrawerClose
                key={section.id}
                asChild
                onClick={() => onSelectOption(section.id)}
              >
                <div className="flex flex-row justify-between px-4 py-2 cursor-pointer">
                  <span className={cn("button-text-medium flex gap-2")}>
                    {currentOption && section.renderIcon(currentOption)}{" "}
                    {section.label}
                  </span>
                  <div
                    className={cn(
                      currentOption === section.id ? "flex" : "hidden"
                    )}
                  >
                    <CheckIcon />
                  </div>
                </div>
              </DrawerClose>
            ))}
          </div>
        </div>
        <div className="flex px-2 mb-8">{tabBtn}</div>
      </div>
    </div>
  );
};
