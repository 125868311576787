import { useCallback, useEffect, useRef, useState } from "react";

import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "components/Carousel";
import Icons from "components/icons/Icons";
import useScreenType from "hooks/useScreenType";
import { Link } from "react-router-dom";
import ProgressBar from "../../../components/ProgressBar";
import { cn } from "../../../libs/classMerger";

const ContinueLearningSection = () => {
  const [courses, setCourses] = useState<any>([]);
  const [userData, setUserData] = useState<any>({});
  const [currentItem, setCurrentItem] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isUserHasMoreCousres, setIsUserHasMoreCousres] = useState(true);
  const setCarouselApi = useCallback((api: any | undefined) => {
    carouselRef.current = api || null;
  }, []);

  const carouselRef = useRef<any>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    setCourses([
      {
        id: "0",
        title: "video 1",
        name: "Keith Ferazzi",
        completedLessons: 3,
        totalLessons: 132,
        currentLesson: 4,
        image: "https://a.storyblok.com/f/60579/1920x1080/783fbef96e/7fmw_questcover_1920x1080_noauthor.jpg",
        video: "https://www.w3schools.com/html/mov_bbb.mp4",
        namePlate: "https://a.storyblok.com/f/60579/207x59/ca8dad669e/your-trainer-vishen-name.svg",
        assigned: true,
        CompleteBy: "Jul 19, 2024",
      },
      {
        id: "1",
        title: "video 1",
        name: "Keith Ferazzi",
        completedLessons: 3,
        totalLessons: 132,
        currentLesson: 4,
        image: "https://a.storyblok.com/f/60579/1920x1080/f2f143da5f/32f76a8d-4bcf-4a0f-b1f2-bb356c867118.jpg",
        video: "",
        namePlate: "https://a.storyblok.com/f/60579/207x59/ca8dad669e/your-trainer-vishen-name.svg",
        assigned: true,
        continueLessonURL: "/",
        CompleteBy: "Jul 19, 2024",
        courseURL: "/",
      },
      {
        id: "2",
        title: "video 1",
        name: "Keith Ferazzi",
        completedLessons: 3,
        totalLessons: 132,
        currentLesson: 4,
        image:
          "https://a.storyblok.com/f/60579/1922x1080/b7330f38db/gut-health_quest_cover_all-diseases-begin-in-the-gut_1920x1080_en.jpg",
        video: "https://www.w3schools.com/html/mov_bbb.mp4",
        namePlate: "https://a.storyblok.com/f/60579/207x59/ca8dad669e/your-trainer-vishen-name.svg",
        assigned: true,
        continueLessonURL: "/",
        CompleteBy: "Jul 19, 2024",
        courseURL: "/",
      },
      {
        id: "3",
        title: "video 1",
        name: "Keith Ferazzi",
        completedLessons: 3,
        totalLessons: 132,
        currentLesson: 4,
        image: "https://a.storyblok.com/f/60579/1922x1080/f62fff20cd/gut-health.png",
        video: "",
        namePlate: "https://a.storyblok.com/f/60579/207x59/ca8dad669e/your-trainer-vishen-name.svg",
        assigned: false,
        continueLessonURL: "/",
        CompleteBy: "Jul 19, 2024",
        courseURL: "/",
      },
    ]);

    setUserData({
      name: "John Doe",
      libraryURL: "/",
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const selectedCard = (item: any) => {
    return (
      <div className="w-full bg-black rounded-3xl overflow-hidden animation-block relative">
        <div className="relative h-[391px] overflow-hidden">
          {item?.video ? (
            <video
              ref={videoRef}
              className="bg-black full-screen-video rounded-t-3xl"
              width="100%"
              height="100%"
              src={item.video}
              // autoPlay
            >
              <track src="captions.vtt" kind="captions" label="English" />
            </video>
          ) : (
            <img src={item?.image} alt="bg" className="w-full h-full " />
          )}
          {item?.assigned && (
            <div className="absolute bottom-0 bg-black/40 w-full text-white py-1 px-4 flex justify-between">
              <div className="flex items-center gap-2">
                <Icons name="calendar-reminder-outlined" width={14} height={14} fill="white" />
                <div className="title-10">Assigned to You</div>
              </div>
              <div className="body-2xs">Complete by {item?.CompleteBy}</div>
            </div>
          )}
        </div>
        <div className="p-6 bg-black flex flex-col gap-2">
          <div className="flex flex-col gap-2">
            {item?.namePlate && (
              <div>
                <img src={item?.namePlate} alt="name plate" />
              </div>
            )}
            {item?.name && <div className="text-cool-grey-300 title-10">with {item?.name}</div>}
          </div>
          <div className="flex items-center w-full gap-4">
            <div className=" w-full flex flex-col gap-[6px]">
              <ProgressBar
                progressClasses="bg-yellow-500"
                value={item?.completedLessons}
                maxValue={item?.totalLessons}
              />
              <div className="text-white/90 title-10">{`${item?.completedLessons} / ${item?.totalLessons} completed`}</div>
            </div>
            <div className="w-auto">
              <div className="flex justify-end gap-1 items-center">
                <Link
                  to={item?.continueLessonURL}
                  className="btn btn-primary whitespace-nowrap bg-red-600 rounded-[100px] px-3 py-1.5 flex items-center gap-2 text-white"
                >
                  <Icons name="play-filled" width={20} height={21} fill="white" />
                  <div className="button-text-medium">Continue lesson {item?.currentLesson}</div>
                </Link>
                <Link
                  to={item?.courseURL}
                  className="rounded-[100px] border p-2 flex items-center justify-center cursor-pointer"
                >
                  <Icons name="list-filled" width={20} height={21} fill="#FFFFFFE5" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const smallCard = (item: any, index: any) => {
    return (
      <div
        className={cn(
          "flex-none min-w-[392px] rounded-3xl overflow-hidden bg-black",
          index <= currentItem ? "opacity-0" : "",
        )}
      >
        <div>
          <div className="relative">
            <img src={item.image} alt="bg" />
            {item?.assigned && (
              <div className="absolute bottom-0 bg-black/40 w-full text-white py-1 px-4">
                <div className="flex items-center gap-2">
                  <Icons name="calendar-reminder-outlined" width={14} height={14} fill="white" />
                  <div className="title-10">Assigned to You</div>
                </div>
              </div>
            )}
          </div>
          <div className="p-4">
            <div className="flex items-center w-full gap-4">
              <div className=" w-full flex justify-end flex-col gap-[6px]">
                <ProgressBar
                  containerClasses="mt-1 h-[3px]"
                  progressClasses="bg-yellow-500"
                  value={item.completedLessons}
                  maxValue={item.totalLessons}
                />
                <div
                  className={"text-white/90 title-10"}
                >{`${item.completedLessons} / ${item.totalLessons} completed`}</div>
              </div>
              <div className=" w-auto">
                <div className="flex justify-end gap-1 items-center">
                  <button
                    type="button"
                    onClick={() => handleCardClick(item)}
                    className="btn btn-primary whitespace-nowrap bg-red-600 rounded-full p-2 flex items-center gap-2 text-white enable-click"
                  >
                    <Icons name="play-filled" width={20} height={21} fill="white" />
                  </button>
                  <Link
                    className="rounded-full border p-2 enable-click flex items-center justify-center"
                    type="button"
                    to={item?.courseURL}
                  >
                    <Icons name="list-filled" width={20} height={21} fill="#FFFFFFE5" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const ExtraCard = () => {
    return (
      <div className="bg-white/60 rounded-2xl overflow-hidden relative  min-w-[392px] h-full flex flex-col items-start justify-between ">
        <div className="px-4 flex flex-col items-start justify-center h-1/2">
          <div className="flex items-center gap-4">
            <div className="text-2xl font-semibold">Discover more</div>
            <Icons name="arrow-right-circle-outlined" width={20} height={20} />
          </div>
          <div className="text-sm">programs to take your skills to the next level</div>
        </div>
        <div className="shape-image absolute right-0 w-1/3 h-full" />
        <div className="w-full h-1/2 absolute bottom-0 card-background" />
      </div>
    );
  };

  const controlSection = () => {
    return (
      <div className={cn("flex justify-between items-start pl-9", userHasMoreThanTwoCourses ? "lg:pt-16" : "")}>
        <div className="flex flex-col gap-3 max-w-[480px]">
          <div className="heading-4">Continue where you left off</div>
          <div className="text-sm body-small">
            We know life gets busy, but revisiting your library helps maintain progress and keeps you motivated. Dive
            deeper into the skills you're building and unlock new learning opportunities within your existing programs.
          </div>
          <Link
            to={userData.libraryURL}
            className="btn whitespace-nowrap bg-black-4a w-fit px-6 py-[6px] rounded-[100px] flex items-center gap-2"
          >
            <div className="text-black button-text-medium text-cool-grey-700">Go to my library</div>
            <Icons name="arrow-right-circle-outlined" width={20} height={20} />
          </Link>
        </div>

        {userHasMoreThanTwoCourses && (
          <div className="flex gap-2">
            <CarouselPrevious type="button" disabled={currentItem === 0} onClick={() => nextPerviousArrowHandler(-1)}>
              previous
            </CarouselPrevious>
            <CarouselNext
              type="button"
              disabled={currentItem === courses.length - 1}
              onClick={() => nextPerviousArrowHandler(1)}
            >
              next
            </CarouselNext>
          </div>
        )}
      </div>
    );
  };

  const handleCardClick = (item: any) => {
    const newCourses = [...courses];
    const propItemIndex = newCourses.findIndex((course: any) => course.id === item.id);
    [newCourses[currentItem], newCourses[propItemIndex]] = [newCourses[propItemIndex], newCourses[currentItem]];
    setCourses(newCourses);
  };

  const nextPerviousArrowHandler = (value: number) => {
    if (value > 0 && currentItem <= courses.length) {
      handleAnimationBlock("next");
      if (currentItem <= courses.length - 2) {
        carouselRef.current?.scrollNext();
      }
    } else if (value < 0 && currentItem >= 0) {
      handleAnimationBlock("prev");
      carouselRef.current?.scrollPrev();
    }
    if (currentItem <= courses.length - 1 && currentItem >= 0) {
      if (currentItem === 0 && value < 0) setCurrentItem(0);
      else {
        setCurrentItem((prev) => prev + value);
      }
    }
  };
  const handleAnimationBlock = (action: string) => {
    if (action === "next") {
      document.querySelectorAll(".animation-block").forEach((item: any) => {
        item.classList.add("slide-out-left");
        item.classList.remove("slide-in-animation-right-left-slow");
        setTimeout(() => {
          item.classList.remove("slide-out-right");
          item.classList.remove("slide-out-left");
          item.classList.remove("slide-in-animation-left-right-slow");
          item.classList.add("slide-in-animation-right-left-slow");
        }, 200);
      });
    } else {
      document.querySelectorAll(".animation-block").forEach((item: any) => {
        item.classList.add("slide-out-right");
        item.classList.remove("slide-in-animation-left-right-slow");
        setTimeout(() => {
          item.classList.remove("slide-out-right");
          item.classList.remove("slide-out-left");
          item.classList.remove("slide-in-animation-right-left-slow");
          item.classList.add("slide-in-animation-left-right-slow");
        }, 200);
      });
    }
  };

  const userHasMoreThanTwoCourses = courses.length > 1;
  const screenType = useScreenType();
  const isTabletAndSmallerScreen = screenType.isMobile || screenType.isTablet;

  return (
    <div className="background-section flex py-10">
      <div className="overflow-unset">
        <div className="relative w-full flex flex-nowrap flex-col gap-6 pr-6 md:pr-0">
          <div className="heading-3">Welcome {userData.name}</div>
          <div className="">
            <div className={cn("grid grid-cols-12 gap-6 w-full")}>
              <div
                className={cn(
                  "col-span-12 lg:col-span-6  overflow-hidden",
                  userHasMoreThanTwoCourses ? "md:col-span-10" : "md:col-span-12 md:pr-6",
                )}
              >
                {selectedCard(courses[currentItem])}
              </div>
              <div className="hidden md:block col-span-2 lg:col-span-6 col-start-11 lg:col-start-7 gap-20 overflow-hidden relative">
                {userHasMoreThanTwoCourses && (
                  <div>
                    <Carousel
                      className="disable-click"
                      opts={{
                        align: "start",
                        dragFree: false,
                      }}
                      setApi={setCarouselApi}
                    >
                      <div>
                        <CarouselContent ref={carouselRef}>
                          {courses
                            .map((item: any, index: any) => (
                              <CarouselItem key={item.id}>
                                <div key={item.id}>{smallCard(item, index)}</div>
                              </CarouselItem>
                            ))
                            .slice(1)}
                          {isUserHasMoreCousres && <CarouselItem>{ExtraCard()}</CarouselItem>}
                          {/* for UI purposes */}
                          <CarouselItem>
                            <div className="min-w-[372px] py-10" />
                          </CarouselItem>
                          <CarouselItem>
                            <div className="min-w-[372px] py-10" />
                          </CarouselItem>
                        </CarouselContent>
                      </div>
                    </Carousel>
                  </div>
                )}
                <div
                  className={cn(
                    userHasMoreThanTwoCourses ? "" : "flex items-center h-full justify-center",
                    "hidden lg:flex",
                  )}
                >
                  {controlSection()}
                </div>
              </div>
              <div className="flex lg:hidden col-span-12">{controlSection()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinueLearningSection;
