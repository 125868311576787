import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { DataService } from "services/dataService";
import type { ICurrentProfile } from "types/interfaces";

function useUserProfileDetails() {
  const { instance } = useMsal();
  const [profileData, setProfileData] = useState<ICurrentProfile>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const getUserProfileDetails = async () => {
      const dataService = new DataService(instance);
      try {
        const data = await dataService.getCurrentProfile();
        if (data) setProfileData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    getUserProfileDetails();
  }, []);

  return { profileData, loading, error };
}

export default useUserProfileDetails;
