import { useQuery } from "@apollo/client";
import gqlQuery from "services/graphql/queries";
import type { IQuest } from "types/interfaces";
import PopularWithIndustryItemCard from "../components/PopularWithIndustryItemCard";

interface RecommendedQuestEdge {
  node: {
    quest: IQuest;
  };
}

interface GetPickedForYouQuestsData {
  recommendedB2bQuestsPickedForYou: {
    edges: RecommendedQuestEdge[];
  };
}

export default function PopularInYourIndustrySection() {
  const { loading, error, data } = useQuery<GetPickedForYouQuestsData>(gqlQuery.getPickedForYouQuests);

  const recommendedQuests = data?.recommendedB2bQuestsPickedForYou?.edges || [];

  if (loading) return <div>Loading...</div>;

  if (error) return <div>Error: {error.message}</div>;

  // Temporary fallback
  if (recommendedQuests.length === 0) {
    return <div>No quests available at the moment.</div>;
  }

  return (
    <section className="flex flex-col gap-y-6">
      <div className="flex flex-col gap-[2px] justify-center">
        <div className="heading-6">Picked for you</div>
        <div className="caption-disclaimer">Quests based on your organization’s goals</div>
      </div>

      <div className="flex flex-col md:flex-row gap-6">
        {recommendedQuests.map(({ node: { quest } }) => (
          <PopularWithIndustryItemCard key={quest.id} item={quest} />
        ))}
      </div>
    </section>
  );
}
