import { ChevronDownIcon } from "@radix-ui/react-icons";
import * as Tabs from "@radix-ui/react-tabs";
import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import useUserProfileDetails from "hooks/useUserProfileDetails";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Drawer, DrawerContent, DrawerTrigger } from "../../components/Drawer";
import { EditProfile } from "../MyAccount/EditProfile";
import MyAccount from "../MyAccount/MyAccount";
import MyLibrary from "../MyLibrary/MyLibrary";
import { NavMenu } from "./NavMenu";
import { ProfileCard } from "./ProfileCard";

export type TabSection = AccountSection | LibrarySection;
export interface TabContent {
  id: TabSection;
  label: string;
  renderIcon: (activeSection: TabSection) => JSX.Element;
}

export enum TabPage {
  MyAccount = "account",
  MyLibrary = "library",
}

export enum AccountSection {
  Profile = "profile",
  SecuritySettings = "settings",
}

export enum LibrarySection {
  Completed = "completed",
  InProgress = "in-progress",
  AssignedToYou = "assigned",
}

const LABELS: Record<string, string> = {
  [TabPage.MyAccount.valueOf()]: "My account",
  [TabPage.MyLibrary.valueOf()]: "My library",
  [AccountSection.Profile.valueOf()]: "My profile",
  [AccountSection.SecuritySettings.valueOf()]: "Security settings",
  [LibrarySection.AssignedToYou.valueOf()]: "Assigned to me",
  [LibrarySection.Completed.valueOf()]: "Completed",
  [LibrarySection.InProgress.valueOf()]: "In progress",
};

export const Profile = ({ page }: { page: TabPage }) => {
  const { profileData, loading, error } = useUserProfileDetails();
  const [activeTab, setActiveTab] = useState<TabPage>(page);
  const { section } = useParams();
  const [activeSection, setActiveSection] = useState<TabSection>(AccountSection.Profile);
  const [editProfilePageOpen, showEditProfilePage] = useState(false);

  useEffect(() => {
    setActiveTab(page);
  }, [page]);

  useEffect(() => {
    if (section) {
      switchSection(section as TabSection);
    } else {
      if (activeTab === TabPage.MyAccount) {
        switchSection(AccountSection.Profile);
      } else {
        switchSection(LibrarySection.InProgress);
      }
    }
  }, [section]);

  const handleTabSwitch = (tab: TabPage) => {
    if (tab === TabPage.MyAccount) {
      switchSection(AccountSection.Profile);
    } else {
      switchSection(LibrarySection.InProgress);
    }
    setActiveTab(tab);
  };

  const PROFILE_PAGE_SECTIONS = {
    [TabPage.MyAccount]: [
      {
        id: AccountSection.Profile,
        label: LABELS[AccountSection.Profile],
        renderIcon: (activeSection: string) => {
          if (activeSection === AccountSection.Profile) {
            return <Icons name="user-filled" height={20} width={20} fill="#D03C2F" />;
          }
          return <Icons name="user-outlined" height={20} width={20} fill="#292D38" />;
        },
      } as TabContent,
      {
        id: AccountSection.SecuritySettings,
        label: LABELS[AccountSection.SecuritySettings],
        renderIcon: (activeSection: string) => {
          if (activeSection === AccountSection.SecuritySettings) {
            return <Icons name="lock-filled" height={20} width={20} fill="#D03C2F" />;
          }
          return <Icons name="lock-outlined" height={20} width={20} fill="black" />;
        },
      } as TabContent,
    ],
    [TabPage.MyLibrary]: [
      {
        id: LibrarySection.InProgress,
        label: LABELS[LibrarySection.InProgress],
        renderIcon: (activeSection: string) => {
          if (activeSection === LibrarySection.InProgress) {
            return <Icons name="user-time-filled" height={20} width={20} fill="#D03C2F" />;
          }
          return <Icons name="user-time-outlined" height={20} width={20} fill="#292D38" />;
        },
      } as TabContent,
      {
        id: LibrarySection.AssignedToYou,
        label: LABELS[LibrarySection.AssignedToYou],
        renderIcon: (activeSection: string) => {
          return <Icons name="calendar-reminder-outlined" width={20} height={20} fill="black" />;
          // if (activeSection === "assigned") {
          //   return <CalenderReminder color="#000" size={20}/>;
          // }
          // return <CalenderReminder color="red" size={20}/>;
        },
      } as TabContent,
      {
        id: LibrarySection.Completed,
        label: LABELS[LibrarySection.Completed],
        renderIcon: (activeSection: string) => {
          if (activeSection === LibrarySection.Completed) {
            return <Icons name="check-circle-filled" width={20} height={20} fill="#D03C2F" />;
          }
          return <Icons name="check-circle-filled" width={20} height={20} fill="#292D38" />;
        },
      } as TabContent,
    ],
  };

  const TabSections = ({
    activeTab,
    activeSection,
  }: {
    activeTab: TabPage;
    activeSection: TabSection;
  }) => {
    const sections = PROFILE_PAGE_SECTIONS[activeTab] ?? PROFILE_PAGE_SECTIONS[TabPage.MyAccount];
    return (
      <div className="py-2 space-y-4 hidden w-full lg:block flex gap-4" data-testid="profile-subtabs-wrapper">
        <div className="text-cool-grey-500 title-9 uppercase">{LABELS[activeTab]}</div>
        <div className="flex flex-col gap-1 self-stretch">
          {sections.map((section) => (
            <button
              onClick={() => switchSection(section.id)}
              type="button"
              key={section.id}
              className={cn(
                activeSection === section.id ? "bg-red-12a text-red-600" : "hover:bg-red-4a",
                "group p-2 gap-x-5 flex items-center rounded-lg cursor-pointer flex gap-x-5 px-2 rounded-[8px] py-2",
              )}
            >
              {section.renderIcon(activeSection)}
              <div className="button-text-medium">{section.label}</div>
            </button>
          ))}
        </div>
      </div>
    );
  };

  const switchTab = (tab: TabPage) => {
    setActiveTab(tab);
    switchSection(tab === TabPage.MyAccount ? AccountSection.Profile : LibrarySection.InProgress);
    showEditProfilePage(false);
  };

  const switchSection = (section: TabSection) => {
    setActiveSection(section);
    showEditProfilePage(false);
  };

  const MenuDrawer = ({
    children,
    trigger,
  }: {
    children: JSX.Element;
    trigger: JSX.Element;
  }) => {
    return (
      <Drawer>
        <DrawerTrigger asChild>{trigger}</DrawerTrigger>
        <DrawerContent>
          <div className="mx-auto w-full max-w-[640px]">{children}</div>
        </DrawerContent>
      </Drawer>
    );
  };

  return (
    <div className="py-20 pb-63 md:pb-[140px] data-[quests]:p-0">
      <div className="p-0 md:px-3 lg:px-6 flex flex-col lg:flex-row lg:bg-white gap-4 lg:gap-10">
        <div className={cn("space-y-4 hidden lg:block")}>
          <ProfileCard editProfile={editProfilePageOpen} profileData={profileData} />
          <TabSections activeTab={activeTab} activeSection={activeSection} />
        </div>

        {!editProfilePageOpen && (
          <div className="flex lg:hidden">
            <ProfileCard editProfile={editProfilePageOpen} profileData={profileData} />
          </div>
        )}

        <div className="w-full bg-white flex-1 pt-4 px-4 md:px-9 lg:p-0 rounded-t-2xl">
          <Tabs.Root
            defaultValue={TabPage.MyAccount}
            value={activeTab}
            className={cn("flex flex-col space-y-4 lg:space-y-8", editProfilePageOpen ? "hidden" : "")}
            onValueChange={(value) => handleTabSwitch(value as TabPage)}
          >
            <Tabs.List className={cn("flex flex-row gap-x-10 border-b border-cool-grey-300")}>
              <Tabs.Trigger
                value={TabPage.MyAccount}
                className="flex w-1/2 lg:w-auto gap-x-2 flex-row items-center pb-4 justify-center lg:justify-normal text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600"
              >
                <div className="flex gap-[6px]">
                  <Icons name="user-outlined" height={20} width={20} fill="#292D38" />
                  <div className="heading-7">{LABELS[TabPage.MyAccount]}</div>
                </div>
              </Tabs.Trigger>
              <Tabs.Trigger
                value={TabPage.MyLibrary}
                className="flex w-1/2 lg:w-auto gap-x-2 flex-row items-center pb-4 justify-center lg:justify-normal text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600"
              >
                <div className="flex gap-[6px]">
                  <Icons name="layers-outlined" height={20} width={20} fill="#595E67" />
                  <div className="heading-7">{LABELS[TabPage.MyLibrary]}</div>
                </div>
              </Tabs.Trigger>
            </Tabs.List>

            <div className="flex lg:hidden justify-between py-2 items-center sticky top-0 bg-white">
              <MenuDrawer
                trigger={
                  <button type="button" className="flex gap-[6px] items-center">
                    <div className="heading-5">
                      {PROFILE_PAGE_SECTIONS[activeTab].find((section) => section.id === activeSection)?.label}
                    </div>
                    <div>
                      <ChevronDownIcon />
                    </div>
                  </button>
                }
              >
                <NavMenu
                  currentOption={activeSection}
                  title={LABELS[activeTab]}
                  tabBtn={
                    <Button
                      className="w-full flex gap-3 rounded-[8px] border-cool-grey-400"
                      variant={"outline"}
                      onClick={() => switchTab(activeTab === TabPage.MyLibrary ? TabPage.MyAccount : TabPage.MyLibrary)}
                    >
                      <span>
                        {activeTab === TabPage.MyAccount ? (
                          <Icons name="layers-outlined" height={20} width={20} fill="#595E67" />
                        ) : (
                          <Icons name="user-outlined" height={20} width={20} fill="#292D38" />
                        )}
                      </span>
                      <div className="button-text-medium">
                        Go to {activeTab === TabPage.MyLibrary ? LABELS[TabPage.MyAccount] : LABELS[TabPage.MyLibrary]}
                      </div>
                    </Button>
                  }
                  options={PROFILE_PAGE_SECTIONS[activeTab]}
                  onSelectOption={switchSection}
                />
              </MenuDrawer>
              {activeSection === AccountSection.Profile && !editProfilePageOpen && (
                <Button
                  type="button"
                  variant={"link"}
                  className="bg-red-8a px-4 py-[6px] rounded-[100px]"
                  onClick={() => showEditProfilePage(true)}
                >
                  Edit
                </Button>
              )}
            </div>

            <div className={cn("pt-3 pr-3 flex gap-2 flex-col", !editProfilePageOpen ? "mt-8" : "")}>
              <Tabs.Content value={TabPage.MyAccount}>
                <MyAccount
                  section={activeSection}
                  editProfilePageOpen={editProfilePageOpen}
                  profileData={profileData}
                  showEditProfilePage={showEditProfilePage}
                />
              </Tabs.Content>

              <Tabs.Content value={TabPage.MyLibrary}>
                <MyLibrary section={activeSection} />
              </Tabs.Content>
            </div>
          </Tabs.Root>
          {editProfilePageOpen && (
            <EditProfile onBackBtnClick={() => showEditProfilePage(false)} profileData={profileData} />
          )}
        </div>
      </div>
    </div>
  );
};
