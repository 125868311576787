interface BannerImageProps {
  src?: string;
  alt: string;
}

export function BannerImage({ src, alt }: BannerImageProps) {
  if (!src) {
    return <div className="aspect-video rounded-2xl bg-gray-200" />;
  }

  return <img src={src} className="aspect-video rounded-2xl" alt={alt} />;
}
