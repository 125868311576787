export enum NavigationEnum {
  Home = "/",
  Configure = "/configure",
  Programs = "/programs",
  Meditations = "/meditations",
  StageView = "/stageView",
  AppInMeeting = "/appInMeeting",
  Library = "/library",
  Account = "/account",
  QuestConsumption = "/quests",
}
