import type { IGraphQLResponse } from "types/interfaces";
import proxyAxiosClient from "./proxyApiClient";

export const callApiViaBot = async (
  authToken: string | null,
  query: string,
  variables: Record<string, any> = {},
): Promise<IGraphQLResponse<any>> => {
  if (!authToken) {
    throw new Error("Missing Auth");
  }
  console.log("callApiViaBot");
  try {
    const url = process.env.GRAPHQL_ENDPOINT as string || "https://platform-api.mindvalley.com/v2/graph";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": "Bearer " + authToken,
    };

    const response = await proxyAxiosClient.post("/api/proxy", { url, query, variables, headers });
    return response.data;
  } catch (error) {
    throw error;
  }
};
