import { ChevronDownIcon } from "@radix-ui/react-icons";
import { Input } from "components/Input";
import { type ChangeEvent, useEffect, useRef, useState } from "react";
import type { IOption } from "types/interfaces";

export const Combobox = ({
  placeholderText = "",
  onSelect,
  selectedOption,
  options,
}: {
  selectedOption?: IOption;
  options?: IOption[];
  placeholderText: string;
  onSelect?: (option: IOption) => void;
}) => {
  const [allOptions, setAllOptions] = useState<IOption[]>([]);
  const [searchResults, setSearchResults] = useState<IOption[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(selectedOption?.value||"");
  const dropdownRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!options?.length) return;
    setAllOptions(options);
    setSearchResults(options);
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        event.target &&
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setDropdownOpen(true);
  };

  useEffect(() => {
    if (!allOptions?.length) return;
    if (searchQuery) {
      setSearchResults(
        allOptions.filter((tag) =>
          tag.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setSearchResults(allOptions);
    }
  }, [searchQuery]);

  const handleSelect = (tag: IOption) => {
    setSearchQuery(tag.name);
    onSelect?.(tag);
    setDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="py-1 flex flex-col space-y-2">
      <button
        type="button"
        className="w-full flex flex-col border-0 relative"
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        <div className="w-full px-2 flex justify-between items-center">
          <Input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            onFocus={() => setSearchQuery("")}
            placeholder={placeholderText}
            className="py-0 px-1 w-full body placeholder:text-cool-grey-450 text-cool-grey-600 ring-0 cursor-default hover:outline-none focus:ring-0 border-0 inline-block focus-visible:ring-0"
            autoComplete="off"
          />
          <div
            className={`transition-transform duration-700 ${
              dropdownOpen ? "rotate-180" : ""
            }`}
          >
            <ChevronDownIcon />
          </div>
        </div>
        {dropdownOpen && (
          <div
            className={`flex flex-col transition-transform duration-700 ${
              dropdownOpen ? "animate-accordion-down" : "animate-accordion-up"
            }`}
          >
            <div className="absolute w-full border border-medium border-cool-grey-300 bg-white shadow-light z-10 overflow-auto max-h-[235px]">
              <ul className="p-2">
                {searchResults.length === 0 ? (
                  <li
                    className="text-left px-4 py-3 body-small text-cool-grey-450"
                    data-testid="no-matching-text"
                  >
                    No matching results
                  </li>
                ) : (
                  <>
                    <li className="text-left px-2 py-1.5 body-2-xs text-cool-grey-450 rounded">
                      {searchResults.length} results
                    </li>
                    {searchResults.map((result: IOption) => (
                      <li
                        key={result.value}
                        className="px-2 py-1.5 rounded body-small text-cool-grey-600 hover:bg-red-100 hover:text-red-600 cursor-pointer"
                      >
                        <button
                          type="button"
                          className="text-left w-full"
                          onClick={() => handleSelect(result)}
                        >
                          {result.name}
                        </button>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        )}
      </button>
    </div>
  );
};
