import { Button } from "components/Button";
import { Tooltip } from "components/Tooltip";
import Icons from "components/icons/Icons";
import { useEffect, useRef, useState } from "react";
import type { IMarker } from "/types/interfaces";
import { secondsToMMSS } from "../QuestConsumption/utils";
import MediaPlayerV2SeekBar from "./MediaPlayerSeekBar";

interface MediaPlayerControlsProps {
  videoPlayerRef: any;
  isPreview?: boolean;
  markers?: IMarker[];
  hasCaptions?: boolean;
}

const MediaPlayerControls = ({ videoPlayerRef, isPreview = false, markers, hasCaptions }: MediaPlayerControlsProps) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [isPipMode, setPipMode] = useState(false);
  const [showCaptions, setShowCaptions] = useState(false);
  const [showMarkers, setShowMarkers] = useState(false);

  const updatePlayPauseState = (videoElement: HTMLVideoElement) => {
    setIsPlaying(!videoElement.paused);
  };

  const updateMuteState = (videoElement: HTMLVideoElement) => {
    setIsMuted(videoElement.muted);
  };

  useEffect(() => {
    const videoElement = videoPlayerRef.current.getVideoElement();
    if (videoElement) {
      const handlePlayPauseUpdate = () => updatePlayPauseState(videoElement);
      const handleMuteUpdate = () => updateMuteState(videoElement);

      videoElement.addEventListener("play", handlePlayPauseUpdate);
      videoElement.addEventListener("pause", handlePlayPauseUpdate);
      videoElement.addEventListener("volumechange", handleMuteUpdate);

      return () => {
        videoElement.removeEventListener("play", handlePlayPauseUpdate);
        videoElement.removeEventListener("pause", handlePlayPauseUpdate);
        videoElement.removeEventListener("volumechange", handleMuteUpdate);
      };
    }
  }, []);

  const toggleMarkers = () => {
    setShowMarkers(!showMarkers);
  };

  const togglePlay = () => {
    if (videoPlayerRef.current) {
      isPlaying ? videoPlayerRef.current.pause() : videoPlayerRef.current.play();
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    videoPlayerRef.current.mute(!isMuted);
    setIsMuted(!isMuted);
  };

  const toggleCaptions = () => {
    videoPlayerRef.current.toggleCaptions(!showCaptions);
    setShowCaptions((prev) => !prev);
  };

  const toggleFullScreen = () => {
    if (isFullScreen) {
      videoPlayerRef.current.exitFullScreen();
    } else {
      videoPlayerRef.current.enterFullScreen();
    }
    setFullScreen(!isFullScreen);
  };

  const togglePipMode = () => {
    if (!videoPlayerRef) return;
    if (isPipMode) {
      videoPlayerRef.current.enterPipMode();
    } else {
      videoPlayerRef.current.enterPipMode();
    }
    setPipMode(!isPipMode);
  };

  const [time, setTime] = useState({
    progress: 0,
    currentTime: 0,
    totalTime: 0,
  });

  const handleRewind = () => {
    if (!videoPlayerRef?.current) return;
    const videoElement = videoPlayerRef.current.getVideoElement();
    const SECONDS_TO_REWIND = 15;
    videoElement.currentTime = Math.max(videoElement.currentTime - SECONDS_TO_REWIND, 0);
  };

  const handleForward = () => {
    if (!videoPlayerRef?.current) return;
    const videoElement = videoPlayerRef.current.getVideoElement();
    const SECONDS_TO_FORWARD = 15;
    videoElement.currentTime = Math.min(videoElement.currentTime + SECONDS_TO_FORWARD, videoElement.duration);
  };

  const handleSeek = (value: number) => {
    if (!videoPlayerRef.current) return;
    const videoElement = videoPlayerRef.current.getVideoElement();

    const newTime = (videoElement.duration || 0) * (value / 100);
    videoElement.currentTime = newTime;

    setTime((t) => Object.assign({}, t, { progress: value }));
  };

  const optionsRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (optionsRef.current?.contains(event.target as Node) || modalRef.current?.contains(event.target as Node)) {
      return;
    }
    document.removeEventListener("mousedown", handleClickOutside);
  };

  const handleTimeUpdate = () => {
    const videoElement = videoPlayerRef.current.getVideoElement();
    if (videoElement) {
      const progress = Math.floor((videoElement.currentTime / videoElement.duration) * 100);
      setTime({
        progress: progress,
        currentTime: videoElement.currentTime,
        totalTime: videoElement.duration,
      });
    }
  };

  useEffect(() => {
    const videoElement = videoPlayerRef.current.getVideoElement();
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  }, []);

  const Markers = () => {
    return (
      <aside className="z-10 absolute top-0 right-0 bottom-0 h-full w-full max-w-[430px] rounded-tr-xl rounded-br-xl bg-cool-grey-650 text-white">
        <ul className="h-full overflow-y-auto">
          <li className="flex h-16 items-center border-b border-b-cool-grey-600 pl-6 text-base text-cool-grey-400 pr-4">
            JUMP TO
            <button type="button" className="ml-auto cursor-pointer" onClick={() => setShowMarkers(false)}>
              <Icons name="x-filled" height={24} width={24} />
            </button>
          </li>
          {markers &&
            [...markers]
              .sort((marker1, marker2) => marker1.time - marker2.time)
              .map((marker) => (
                <li key={marker.id} className="w-full">
                  <button
                    onClick={() => {
                      const videoElement = videoPlayerRef.current.getVideoElement();
                      if (!videoElement) return;
                      videoElement.currentTime = marker.time;
                      setShowMarkers(false);
                    }}
                    className="hover:bg-cool-grey-600 text-cool-grey-20 flex min-h-[72px] w-full cursor-pointer justify-between items-center py-4 pr-4"
                    type="button"
                  >
                    <div className="w-6 shrink-0">
                      <div className="hidden">
                        <Icons name="play-filled" className="h-10 w-10" />
                      </div>
                    </div>
                    <div className="grow pr-2 text-sm text-left">{marker.name}</div>
                    <div className="w-12 shrink-0 text-left text-sm">{secondsToMMSS(marker.time)}</div>
                  </button>
                </li>
              ))}
        </ul>
      </aside>
    );
  };

  return (
    <>
      {isPreview && (
        <div className="absolute top-20 md:top-4 rounded-md left-3 md:left-10 bg-cool-grey-150 px-4 py-2 button-text-small">
          Preview
        </div>
      )}
      {showMarkers && <Markers />}
      {markers && markers.length > 0 && (
        <Button
          onClick={toggleMarkers}
          variant={"outline"}
          className="absolute top-20 md:top-4 right-3 md:right-6 h-8 w-24 rounded-full border-2 border-white bg-black/[.3] text-sm text-white"
        >
          Jump to
        </Button>
      )}
      <div className="md:absolute inset-x-0 bottom-0 py-2 md:px-4 px-2 pt-6 bg-gradient-to-t from-black/100 via-black/52 to-black/0">
        <MediaPlayerV2SeekBar
          showDuration={true}
          progress={time?.progress}
          currentDuration={time.currentTime}
          totalDuration={time.totalTime}
          onValueChange={(value: number[]) => {
            handleSeek(value[0]);
          }}
        />
        <div className="relative w-full h-16 flex justify-between mt-5">
          <div className="flex px-10"> </div>
          <div className="flex gap-5 justify-center items-center">
            <Tooltip tooltipContent={<p className="caption-disclaimer">15 sec rewind</p>}>
              <Button variant="icon" size="icon" className="text-white z-10" onClick={handleRewind}>
                <Icons name="rewind-15-outlined" height={24} width={24} />
              </Button>
            </Tooltip>
            <Tooltip tooltipContent={<p className="caption-disclaimer">{isPlaying ? "Pause" : "Play"}</p>}>
              <Button variant="icon" size="icon" className="text-white z-10 scale-150" onClick={togglePlay}>
                {isPlaying ? (
                  <Icons name="pause-circle-filled" height={36} width={36} />
                ) : (
                  <Icons name="play-circle-filled" height={36} width={36} />
                )}
              </Button>
            </Tooltip>
            <Tooltip tooltipContent={<p className="caption-disclaimer">15 sec forward</p>}>
              <Button variant="icon" size="icon" className="text-white z-10" onClick={handleForward}>
                <Icons name="forward-15-outlined" height={24} width={24} />
              </Button>
            </Tooltip>
          </div>
          <div className="flex gap-5 items-center">
            {!isMuted && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Mute</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={toggleMute}>
                  <Icons name="volume-2-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}

            {isMuted && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Unmute</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={toggleMute}>
                  <Icons name="volume-x-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}

            {!showCaptions && hasCaptions && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Enable Captions</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={toggleCaptions}>
                  <Icons name="caption-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}
            {showCaptions && hasCaptions && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Disable Captions</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={toggleCaptions}>
                  <Icons name="caption-x-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}

            {!isPipMode && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Enable PiP</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={togglePipMode}>
                  <Icons name="pip-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}
            {isPipMode && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Disable PiP</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={togglePipMode}>
                  <Icons name="pip-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}

            {!isFullScreen && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Enter full screen</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={toggleFullScreen}>
                  <Icons name="maximize-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}
            {isFullScreen && (
              <Tooltip tooltipContent={<p className="caption-disclaimer">Exit full screen</p>}>
                <Button variant="icon" size="icon" className="text-white z-10" onClick={toggleFullScreen}>
                  <Icons name="maximize-outlined" height={24} width={24} />
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaPlayerControls;
