import type { IUserProgress } from "types/interfaces";

export const getLessonsStatus = (type: string, userProgress?: IUserProgress) => {
  switch (type) {
    case "weekly":
      return {
        completed: userProgress?.totalLessonsCompleted,
        total: userProgress?.totalLessons,
      };
    case "daily":
      return {
        completed: userProgress?.totalDaysCompleted,
        total: userProgress?.totalDays,
      };
    default:
      return {};
  }
};

export function secondsToMMSS(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
