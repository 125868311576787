import { Button } from "components/Button";
import { MediaPlayer } from "components/MediaPlayer";
import ProgressBar from "components/ProgressBar";
import { SheetClose } from "components/Sheet";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/Tabs";
import Icons from "components/icons/Icons";
import useQuestBySlugAndLanguage from "hooks/useQuestBySlugAndLanguage";
import useQuestConsumption from "hooks/useQuestConsumption";
import useScreenType from "hooks/useScreenType";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import { AssignedBanner } from "tabs/Home/components/AssignedBanner";
import ConfirmationModal, { ConfirmationModalType } from "tabs/Quest/QuestLanding/ConfirmationModal";
import type { IQuest, IQuestNode } from "types/interfaces";
import { convertSecondsToReadableFormat } from "utils/time";
import { Lessons } from "./Lessons";
import Overview from "./Overview";
import { Resources } from "./Resources";
import { Stories } from "./Stories";

const QuestLandingModal = ({ questData }: { questData: IQuest | IQuestNode }) => {
  const { data, loading, error, refetch: refetchQuest } = useQuestBySlugAndLanguage(questData.slug, questData.language);
  const {
    enrollUser,
    loading: enrollUserLoading,
    response: enrollUserResponse,
    error: enrollUserError,
  } = useQuestConsumption().enrollUser;
  const {
    unEnrollUser,
    loading: unEnrollUserLoading,
    response: unEnrollUserResponse,
    error: unEnrollUserError,
  } = useQuestConsumption().unEnrollUser;
  const releaseId = data?.releases ? data?.releases[0]?.id : "";
  const videoPlayerRef = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [showCaptions, setShowCaptions] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [confirmationModalType, setConfirmationModalType] = useState<ConfirmationModalType | null>(null);

  useEffect(() => {
    const videoElement = videoPlayerRef.current?.getVideoElement();
    if (videoElement) {
      const handlePlayPauseUpdate = () => updatePlayPauseState(videoElement);
      const handleMuteUpdate = () => updateMuteState(videoElement);

      videoElement.addEventListener("play", handlePlayPauseUpdate);
      videoElement.addEventListener("pause", handlePlayPauseUpdate);
      videoElement.addEventListener("volumechange", handleMuteUpdate);

      return () => {
        videoElement.removeEventListener("play", handlePlayPauseUpdate);
        videoElement.removeEventListener("pause", handlePlayPauseUpdate);
        videoElement.removeEventListener("volumechange", handleMuteUpdate);
      };
    }
  }, []);

  const updatePlayPauseState = (videoElement: HTMLVideoElement) => {
    setIsPlaying(!videoElement.paused);
  };

  const updateMuteState = (videoElement: HTMLVideoElement) => {
    setIsMuted(videoElement.muted);
  };
  const navigate = useNavigate();
  const handleStartProgram = async () => {
    await enrollUser({
      variables: {
        input: {
          releaseId,
        },
      },
    });
    const urlPath = new URL(questData.url).pathname;
    navigate(urlPath);
  };

  const togglePlay = () => {
    const videoElement = videoPlayerRef.current.getVideoElement();
    if (videoElement) {
      isPlaying ? videoElement.pause() : videoElement.play();
      setIsPlaying(!isPlaying);
    }
  };

  const toggleMute = () => {
    const videoElement = videoPlayerRef.current.getVideoElement();
    if (videoElement) {
      videoElement.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  const toggleCaptions = () => {
    videoPlayerRef.current.toggleCaptions(!showCaptions);
    setShowCaptions((prev) => !prev);
  };

  const handleFullScreen = () => {
    videoPlayerRef.current.enterFullScreen();
  };

  const getMaxHeightAndWidth = () => {
    return data.userProgress?.started ? "max-h-12 max-w-[200px]" : "max-h-20 max-w-80";
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (optionsRef.current?.contains(event.target as Node) || modalRef.current?.contains(event.target as Node)) {
      return;
    }

    setShowOptions(false);
    setConfirmationModalType(null);
    document.removeEventListener("mousedown", handleClickOutside);
  };

  const closeModal = () => {
    setShowOptions(false);
    setConfirmationModalType(null);
  };

  const onConfirmation = () => {
    if (confirmationModalType === ConfirmationModalType.Leave) leaveProgram();
    setShowOptions(false);
    setConfirmationModalType(null);
  };

  const leaveProgram = async () => {
    await unEnrollUser({
      variables: {
        input: {
          questId: questData.id,
        },
      },
    });
    await refetchQuest({
      slug: questData.slug,
      language: questData.language,
    });
  };

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
    if (!showOptions) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
  };

  const screenType = useScreenType();

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const assignment = (data as IQuest).currentAssignment;

  const getCompletedCount = () =>
    data.type === "weekly" ? data.userProgress?.totalLessonsCompleted : data.userProgress?.totalDaysCompleted;

  const getTotalCount = () => (data.type === "weekly" ? data.userProgress?.totalLessons : data.userProgress?.totalDays);

  return (
    <div id="quest-landing-modal-content">
      <div className="md:relative bg-black">
        <MediaPlayer
          mediaUrl={data.trailerAsset?.url}
          autoplay={true}
          muted={true}
          looped={true}
          playerClasses="aspect-auto relative"
          showVControls={false}
          ref={videoPlayerRef}
          captions={data.trailerAsset?.captions}
          thumbnailUrl={data.coverAsset?.url}
        />
        <div
          className={`${
            assignment ? "mb-0 md:mb-8" : ""
          } md:absolute inset-x-0 bottom-0 md:py-8 py-6 md:px-9 px-4 md:pb-8 pb-6 bg-black-90a bg-transparent pt-6 md:bg-gradient-to-t md:from-black/100 md:via-black/52 md:to-black/0`}
        >
          {!data.userProgress?.started && (
            <p className="heading-7 md:pb-4 pb-3 text-white-70a h-55 lowercase">{data.categories[0].name}</p>
          )}
          <div className="flex flex-col">
            <div className="flex flex-col md:flex-row gap-0 md:gap-6 items-start md:items-center space-y-3 ">
              {data.wordmarkAsset?.url ? (
                <img
                  src={data.wordmarkAsset.url}
                  className={`${getMaxHeightAndWidth()} lg:max-w-[360px] h-auto w-auto`}
                  alt="Quest Wordmark"
                />
              ) : (
                <p
                  className={`${
                    data.userProgress?.started ? "heading-7 max-w-[320px]" : "heading-2 max-w-[680px]"
                  } text-white`}
                >
                  {data.name}
                </p>
              )}
              {data.userProgress?.started && (
                <div className="flex gap-4 items-center w-[100%]">
                  <ProgressBar
                    progressClasses="!bg-yellow-500"
                    value={getCompletedCount()}
                    maxValue={getTotalCount()}
                  />
                  <p className="title-8 text-white text-nowrap">
                    {getCompletedCount()} / {getTotalCount()} completed
                  </p>
                  {data.userProgress?.completed && <p className="title-8">🎉</p>}
                </div>
              )}
            </div>
            {data.userProgress?.started && !data.userProgress?.completed && (
              <>
                <p className="heading-6 text-white mt-4">{data.userProgress.resumePage?.name}</p>
                <p className="caption-disclaimer text-cool-grey-400 mt-1">
                  {convertSecondsToReadableFormat(data.userProgress.resumePage.duration)}
                </p>
              </>
            )}
            {data.userProgress?.completed && (
              <p className="heading-5 text-white mt-3.5">Congratulations on completing the program.</p>
            )}
            {!data.userProgress?.started && (
              <p className="title-7 text-cool-grey-300 mt-3">with {data.authors[0].name}</p>
            )}
            <div className="flex items-center mt-5 gap-1.5">
              {data.userProgress?.completed ? (
                <div className={"flex flex-col md:flex-row gap-x-1.5 gap-y-2 w-full"}>
                  <Button
                    type="button"
                    id="landing-modal-explore-program-button"
                    onClick={() => navigate(NavigationEnum.Programs)}
                    className="flex rounded-full items-center justify-center enabled:cursor-pointer text-center focus:outline-none bg-red-600 text-white enabled:hover:gradient-red-wave-top-down disabled:bg-black-8a disabled:text-black-18a button-text-medium h-10 px-3.5 py-3 md:w-fit"
                  >
                    <p className="px-1.5">Explore similar programs</p>
                  </Button>
                  {/* <div className={"flex gap-x-1.5"}>
                    <Button
                      type="button"
                      id="landing-modal-restart-program-button"
                      variant={"neutral"}
                      className="flex rounded-full items-center justify-center enabled:cursor-pointer text-center focus:outline-none bg-white-10a text-white enabled:hover:bg-white-18a disabled:bg-black-8a disabled:text-black-18a button-text-medium h-10 px-3.5 py-3 w-full md:w-fit"
                    >
                      <Icons name="past-replay-filled" height={20} width={20} fill="#fff" />
                      <p className="px-1.5">Start from beginning</p>
                    </Button>
                    <Button
                      type="button"
                      id="landing-modal-restart-program-button"
                      variant={"neutral"}
                      className="flex rounded-full items-center justify-center enabled:cursor-pointer text-center focus:outline-none bg-white-10a text-white enabled:hover:bg-white-18a disabled:bg-black-8a disabled:text-black-18a button-text-medium h-10 px-3.5 py-3 w-10"
                    >
                      <Icons name="plus-filled" height={20} width={20} fill="#fff" />
                    </Button>
                  </div> */}
                </div>
              ) : (
                <div className={"flex gap-x-1.5 w-full"}>
                  {!data.userProgress?.started && (
                    <Button variant={"primary"} onClick={handleStartProgram} className={"w-full lg:w-[234px]"}>
                      <Icons name="play-filled" height={20} width={20} fill="#fff" />
                      <p className="px-1.5">Start program</p>
                    </Button>
                  )}
                  {data.userProgress?.started && (
                    <Link
                      to={new URL(data.userProgress.resumePage.url ?? "")?.pathname}
                      className={"w-full lg:w-[234px]"}
                    >
                      <Button variant={"primary"} className={"w-full lg:w-[234px]"}>
                        <Icons name="play-filled" height={20} width={20} fill="#fff" />
                        <p className="px-1.5">
                          Continue{data.type === "weekly" ? " lesson " : " day "}
                          {data.userProgress?.resumePage?.position}
                        </p>
                      </Button>
                    </Link>
                  )}
                  {/*<Button*/}
                  {/*  type="button"*/}
                  {/*  id="landing-modal-restart-program-button"*/}
                  {/*  variant={"neutral"}*/}
                  {/*  className="flex rounded-full items-center justify-center enabled:cursor-pointer text-center focus:outline-none bg-white-10a text-white enabled:hover:bg-white-18a disabled:bg-black-8a disabled:text-black-18a button-text-medium h-10 px-3.5 py-3 w-10"*/}
                  {/*>*/}
                  {/*  <Icons name="plus-filled" height={20} width={20} fill="#fff" />*/}
                  {/*</Button>*/}
                </div>
              )}
            </div>
          </div>
        </div>
        {assignment && <AssignedBanner assignment={assignment} className="bg-brown-900" />}
      </div>
      <div className="flex flex-col absolute top-0 w-full">
        <div className="flex md:px-10 px-4 md:py-4 py-2 w-full justify-between">
          <SheetClose asChild>
            <button
              type="button"
              className="flex items-center justify-between md:px-3 px-2 inset-x-0 md:py-1.5 py-1.5 text-center text-white"
              data-testid="quest-back-button"
            >
              <Icons name="arrow-left-circle-outlined" width={24} height={24} fill="#FFFFFF" />
              <h3 className="px-1 button-text-medium py-px hidden md:block">Back</h3>
            </button>
          </SheetClose>
          {(data.userProgress?.started || data.userProgress?.completed) && (
            <div className="absolute top-0 right-0 py-[7px] md:py-3.5 px-4 md:px-10" ref={optionsRef}>
              <button
                type="button"
                className="flex items-center justify-between md:px-3 px-2 inset-x-0 md:py-1.5 py-1.5 text-center text-white hover:bg-white-10a rounded-full"
                onClick={toggleOptions}
                data-testid="quest-back-button"
              >
                <Icons name={"more-horizontal-filled"} height={20} width={20} fill="#fff" />
                <h3 className="px-1 button-text-medium py-px hidden md:block">Options</h3>
              </button>
              {showOptions && (
                <div
                  id="quest-options-menu"
                  className="absolute right-[-30%] md:right-[15%] flex flex-col flex-start bg-white rounded-lg"
                >
                  <div className="relative">
                    {/*<div*/}
                    {/*    className="before:absolute before:w-0 before:h-0 before:border-x-8 before:border-b-8 before:border-transparent before:border-b-white before:block before:-top-[7px] before:right-[46px] before:content-['']">*/}
                    {/*</div>*/}
                    <Icons
                      name={"triangle-filled"}
                      fill={"white"}
                      width={18}
                      height={18}
                      className={"absolute right-[50px] top-[-15px]"}
                    />
                    {/* <button
                      type="button"
                      className="flex flex-start p-1 gap-2.5 bg-grey-100"
                      onClick={() => setConfirmationModalType(ConfirmationModalType.Reset)}
                    >
                      <h3 className="min-w-[182px] px-2 py-1.5 title-9 text-cool-grey-700 hover:text-red-600 hover:bg-red-8a rounded whitespace-nowrap text-left">
                        Reset program
                      </h3>
                    </button> */}
                    <button
                      type="button"
                      className="flex flex-start p-1 gap-2.5 bg-grey-100"
                      onClick={() => setConfirmationModalType(ConfirmationModalType.Leave)}
                    >
                      <h3 className="min-w-[182px] px-2 py-1.5 title-9 text-cool-grey-700 hover:text-red-600 hover:bg-red-8a rounded whitespace-nowrap text-left">
                        Leave program
                      </h3>
                    </button>
                  </div>
                  {confirmationModalType !== null && (
                    <ConfirmationModal
                      modalRef={modalRef}
                      type={confirmationModalType}
                      questName={data.name}
                      url={data.wordmarkAsset?.url}
                      authorName={data.authors[0].name}
                      onClose={closeModal}
                      onConfirmation={onConfirmation}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="flex md:px-10 px-4 justify-end">
          <div className="flex gap-2">
            <button type="button" onClick={togglePlay} className="w-9 h-9 p-2 bg-black/70 rounded-[100px]">
              <Icons name={isPlaying ? "pause-filled" : "play-filled"} height={20} width={20} fill="#fff" />
            </button>
            <button type="button" onClick={toggleMute} className="w-9 h-9 p-2 bg-black/70 rounded-[100px]">
              <Icons name={isMuted ? "volume-x-filled" : "volume-2-filled"} height={20} width={20} fill="#fff" />
            </button>
            {data.trailerAsset?.captions && data.trailerAsset?.captions?.length > 0 && (
              <button type="button" onClick={toggleCaptions} className="w-9 h-9 p-2 bg-black/70 rounded-[100px]">
                <Icons name={showCaptions ? "caption-filled" : "caption-x-filled"} height={20} width={20} fill="#fff" />
              </button>
            )}
            <button type="button" onClick={handleFullScreen} className="w-9 h-9 p-2 bg-black/70 rounded-[100px]">
              <Icons name="maximize-filled" height={20} width={20} fill="#fff" />
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white w-full top-0 z-10 ">
        <div className="space-x-8 w-full">
          <Tabs defaultValue="overview">
            <TabsList className="z-10 mb-6 h-[50px] pb-0 sticky top-0 bg-white px-4 md:px-10 gap-6 md:gap-8 justify-start border-b border-b-cool-grey-300 rounded-none w-full">
              <TabsTrigger
                value="overview"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Overview</div>
              </TabsTrigger>
              <TabsTrigger
                value="lessons"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Lessons</div>
              </TabsTrigger>
              <TabsTrigger
                value="resources"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Resources</div>
              </TabsTrigger>
              <TabsTrigger
                value="stories"
                className="px-0 py-3 flex gap-x-2 flex-row items-center text-cool-grey-500 data-[state=active]:border-b-[3px] data-[state=active]:border-red-600 hover:text-cool-grey-600 data-[state=active]:shadow-none"
              >
                <div className="heading-8">Stories</div>
              </TabsTrigger>
            </TabsList>
            <TabsContent value="overview">
              <Overview {...data} />
            </TabsContent>
            <TabsContent value="lessons">
              <Lessons {...data} />
            </TabsContent>
            <TabsContent value="resources">
              <Resources {...data} />
            </TabsContent>
            <TabsContent value="stories">
              <Stories />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default QuestLandingModal;
