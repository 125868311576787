import { Button } from "@fluentui/react-components";
import type { IMeditation } from "types/interfaces";
import { durationToMinutes } from "utils/time";

export default function MeditationCardSidePanelView({
  meditation,
  handleClick,
  frameContext,
}: {
  meditation: IMeditation;
  handleClick: () => void;
  frameContext: string | null;
}) {
  const authorName = meditation.resource.author?.name || "Unknown author";
  const duration = durationToMinutes(meditation.resource.mediaAsset?.duration);

  return (
    <div className="flex space-x-3">
      <figure className="shrink-0">
        <img
          src={meditation.resource.coverAsset?.url}
          alt={meditation.resource.title}
          role="presentation"
          className="size-[100px] rounded-lg"
        />
        <figcaption className="sr-only">{meditation.resource.title}</figcaption>
      </figure>

      <div className="grid space-y-2 mt-1.5">
        <div className="grid sapce-y-1">
          <h3 className="text-[14px] font-semibold">{meditation.resource.title}</h3>
          <div className="flex items-center space-x-2 text-[10px] text-[#616161]">
            <p>{authorName}</p>
            <div>•</div>
            <p>{duration}</p>
          </div>
        </div>

        <div className="flex space-x-1">
          <div>
            <Button onClick={handleClick} className="shrink-0" size="small">
              {frameContext === "content" ? " Listen" : " Listen together"}
            </Button>
          </div>
          {/* <Button size="small">Add to queue</Button> */}
        </div>
      </div>
    </div>
  );
}
