import { app, stageView } from "@microsoft/teams-js";
import { teamsConfig } from "config/teamsConfig";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import type { IPage, IQuest, ISection } from "types/interfaces";
import { QuestCategoryEnum } from "types/interfaces";

export default function PopularWithIndustryItemCard({
  item,
}: {
  item: IQuest;
}) {
  const { authors, wordmarkAsset, coverAsset, description, name, pages } = item;
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);
  const categoryMatch = QuestCategoryEnum.Career;
  const [isMuted, setIsMuted] = useState(true); // State to track mute/unmute status
  const handleMuteToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsMuted(!isMuted);
  };

  const mediaAsset = pages
    ?.find((page: IPage) =>
      page.sections.find(
        (section: ISection) => section.primaryAsset && section.primaryAsset.contentType === "video/mp4",
      ),
    )
    ?.sections.find(
      (section: ISection) => section.primaryAsset && section.primaryAsset.contentType === "video/mp4",
    )?.primaryAsset;

  function handleClick() {
    if (mediaAsset) {
      app.initialize().then(() => {
        if (stageView.isSupported()) {
          stageView
            .open({
              appId: teamsConfig.appId,
              contentUrl: `${window.location.origin}/stageView?mediaUrl=${
                mediaAsset.url
              }&thumbnailUrl=${encodeURIComponent(coverAsset?.url ?? "")}&autoplay=true`,
              title: name,
              websiteUrl: window.location.origin,
              openMode: stageView.StageViewOpenMode.modal,
              threadId: "",
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          navigate(
            `/stageView?mediaUrl=${encodeURIComponent(mediaAsset.url)}&thumbnailUrl=${encodeURIComponent(
              coverAsset?.url ?? "",
            )}&autoplay=true&mimeType=${mediaAsset.contentType}&isStageViewSupported=false`,
          );
        }
      });
    }
  }

  return (
    <button type="button" className="" onClick={handleClick}>
      <div className="flex rounded-2xl relative overflow-hidden ">
        <div className="w-full overflow-hidden h-full">
          <img
            alt={item.name}
            src={coverAsset?.url}
            className="overflow-hidden transition ease-in-out duration-500 hover:scale-105 object-center rounded-3xl w-full aspect-[4/3]"
          />
        </div>
        <div
          className="rounded-2xl absolute bottom-0 left-0 w-full"
          style={{
            background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)",
          }}
        >
          <div className="flex flex-col pt-4 px-6 pb-4 w-full gap-2 text-left">
            <div className="heading-8 lowercase text-white/70">{item.categories[0].name}</div>
            <div className="w-full h-full">
              <img
                src={wordmarkAsset?.url}
                alt={item.name}
                className="object-cover max-h-14 max-w-[280px] lg:max-h-16 lg:max-w-[300px]"
              />
            </div>
            <div className="title-10 text-cool-grey-300/90">with {authors[0].name}</div>
          </div>
        </div>
      </div>
    </button>
  );
}
