export function durationToMinutes(seconds = 0) {
  if (seconds < 60) {
    return `${Math.round(seconds)}s`;
  }
  return `${Math.round(seconds / 60)}m`;
}

export const convertSecondsToReadableFormat = (seconds = 0): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const hoursString = hours > 0 ? `${hours} hour${hours > 1 ? 's' : ''}` : '';
  const minutesString = minutes > 0 ? `${minutes} min${minutes > 1 ? 's' : ''}` : '';

  return [hoursString, minutesString].filter(Boolean).join(' ');
};

