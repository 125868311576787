import { Button } from "@fluentui/react-components";
import useEmblaCarousel from "embla-carousel-react";
import useQuest from "hooks/useQuest";
import { cn } from "libs/classMerger";
import { useCallback, useState } from "react";
import {
  type IQuestNode,
  type PageI,
  type PrimaryAssetI,
  QuestCategoryEnum,
  type QuestI,
  type SectionI,
} from "types/interfaces";
import QuestCardSidePanelView from "../components/QuestCardSidePanelView";

export default function QuestListView(props: {
  shareSpecificPart: (mediaUrl: PrimaryAssetI, thumbnailUrl: string) => void;
  frameContext: string | null;
}) {
  const [emblaRef] = useEmblaCarousel({
    loop: false,
  });
  const [selectedQuest, setSelectedQuest] = useState<QuestCategoryEnum>(QuestCategoryEnum.Career);
  // TODO: We need all here instead of career. Need to see api integration as well.
  const { questData } = useQuest(selectedQuest);

  function getQuestCategory(category: QuestCategoryEnum) {
    setSelectedQuest(category);
  }

  // Temporary function till design and flow done
  const getFirstMediaAsset = useCallback((pages: PageI[]) => {
    const mediaAsset = pages
      .flatMap((page: PageI) => page.sections)
      .find((section: SectionI) => section.primaryAsset?.contentType === "video/mp4")?.primaryAsset;

    if (!mediaAsset) {
      console.error("No media asset found");
      return null;
    }

    return mediaAsset;
  }, []);

  const handleQuestClick = useCallback(
    (quest: QuestI) => {
      const mediaAsset = getFirstMediaAsset(quest.pages);
      if (mediaAsset) {
        props.shareSpecificPart(mediaAsset, quest.coverAsset.url);
      }
    },
    [getFirstMediaAsset, props],
  );

  return (
    <>
      <div ref={emblaRef} className="mt-3">
        <div className="flex flex-wrap gap-1.5">
          {Object.values(QuestCategoryEnum).map((category) => (
            <Button
              key={category}
              onClick={() => getQuestCategory(category)}
              size="small"
              className={cn("capitalize shrink-0", category === selectedQuest && "!bg-[#5B5FC7] !text-white")}
            >
              {category}
            </Button>
          ))}
        </div>
      </div>
      <div className="grid space-y-6 mt-4">
        {questData.map((quest: IQuestNode) => (
          <QuestCardSidePanelView
            key={quest.id}
            handleClick={() => console.log("test")}
            quest={quest}
            frameContext={props.frameContext}
          />
        ))}
      </div>
    </>
  );
}
