interface IconProps {
  size?: number;
}

export function MeditationIcon({ size = 24 }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Meditation icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.73619 18.0644C6.52951 18.2739 6.5078 18.4069 6.5078 18.4615C6.5078 18.5161 6.52951 18.6492 6.73619 18.8586C6.94699 19.0722 7.30008 19.309 7.81291 19.5288C8.83511 19.9669 10.3148 20.2615 12.0001 20.2615C13.6854 20.2615 15.1651 19.9669 16.1873 19.5288C16.7001 19.309 17.0532 19.0722 17.264 18.8586C17.4707 18.6492 17.4924 18.5161 17.4924 18.4615C17.4924 18.4069 17.4707 18.2739 17.264 18.0644C17.0532 17.8508 16.7001 17.614 16.1873 17.3942C15.1651 16.9562 13.6854 16.6615 12.0001 16.6615C10.3148 16.6615 8.83511 16.9562 7.81291 17.3942C7.30008 17.614 6.94699 17.8508 6.73619 18.0644ZM7.04931 15.6125C8.36572 15.0483 10.1168 14.7231 12.0001 14.7231C13.8835 14.7231 15.6345 15.0483 16.9509 15.6125C17.6074 15.8939 18.2006 16.2538 18.6437 16.7028C19.091 17.156 19.4309 17.7514 19.4309 18.4615C19.4309 19.1716 19.091 19.7671 18.6437 20.2203C18.2006 20.6693 17.6074 21.0292 16.9509 21.3105C15.6345 21.8747 13.8835 22.2 12.0001 22.2C10.1168 22.2 8.36572 21.8747 7.04931 21.3105C6.39283 21.0292 5.79964 20.6693 5.3565 20.2203C4.90924 19.7671 4.56934 19.1716 4.56934 18.4615C4.56934 17.7514 4.90924 17.156 5.3565 16.7028C5.79964 16.2538 6.39283 15.8939 7.04931 15.6125Z"
        fill="#292D38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.39852 11.0769C8.40312 11.0723 8.40807 11.0675 8.41339 11.0625C8.52825 10.9539 8.74647 10.8114 9.0965 10.6714C9.79118 10.3935 10.8173 10.2 12.0001 10.2C13.1829 10.2 14.209 10.3935 14.9037 10.6714C15.2537 10.8114 15.472 10.9539 15.5868 11.0625C15.5921 11.0675 15.5971 11.0723 15.6017 11.0769C15.5971 11.0815 15.5921 11.0863 15.5868 11.0913C15.472 11.1999 15.2537 11.3424 14.9037 11.4824C14.209 11.7603 13.1829 11.9538 12.0001 11.9538C10.8173 11.9538 9.79118 11.7603 9.0965 11.4824C8.74647 11.3424 8.52825 11.1999 8.41339 11.0913C8.40807 11.0863 8.40312 11.0815 8.39852 11.0769ZM8.37657 8.87157C9.35233 8.48127 10.6339 8.26153 12.0001 8.26153C13.3663 8.26153 14.6479 8.48127 15.6236 8.87157C16.1088 9.06565 16.5665 9.32099 16.9188 9.6541C17.273 9.98916 17.5847 10.4696 17.5847 11.0769C17.5847 11.6842 17.273 12.1647 16.9188 12.4997C16.5665 12.8328 16.1088 13.0882 15.6236 13.2822C14.6479 13.6725 13.3663 13.8923 12.0001 13.8923C10.6339 13.8923 9.35233 13.6725 8.37657 13.2822C7.89138 13.0882 7.4337 12.8328 7.08145 12.4997C6.72717 12.1647 6.41549 11.6842 6.41549 11.0769C6.41549 10.4696 6.72717 9.98916 7.08145 9.6541C7.4337 9.32099 7.89138 9.06565 8.37657 8.87157Z"
        fill="#292D38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.73494 4.15147C9.29425 4.4033 9.27703 4.60349 9.27703 4.61537C9.27703 4.62725 9.29425 4.82745 9.73494 5.07927C10.1482 5.31543 10.7838 5.4923 11.5386 5.4923C12.2933 5.4923 12.9289 5.31543 13.3422 5.07927C13.7829 4.82745 13.8001 4.62725 13.8001 4.61537C13.8001 4.60349 13.7829 4.4033 13.3422 4.15147C12.9289 3.91532 12.2933 3.73845 11.5386 3.73845C10.7838 3.73845 10.1482 3.91532 9.73494 4.15147ZM8.7732 2.46842C9.52923 2.0364 10.509 1.79999 11.5386 1.79999C12.5681 1.79999 13.5479 2.0364 14.3039 2.46842C15.0326 2.88477 15.7386 3.60765 15.7386 4.61537C15.7386 5.62309 15.0326 6.34598 14.3039 6.76233C13.5479 7.19435 12.5681 7.43076 11.5386 7.43076C10.509 7.43076 9.52923 7.19435 8.7732 6.76233C8.04458 6.34598 7.33857 5.62309 7.33857 4.61537C7.33857 3.60765 8.04458 2.88477 8.7732 2.46842Z"
        fill="#292D38"
      />
    </svg>
  );
}
