interface IconProps {
  size?: number;
}

export function MicrosoftIcon({ size = 24 }: IconProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Microsoft icon</title>
      <g clipPath="url(#clip0_3481_36255)">
        <path
          d="M0.895996 0.895996H8.70653C8.70607 3.49951 8.70653 6.10302 8.70607 8.70654H0.895996V0.895996Z"
          fill="#F25022"
        />
        <path
          d="M9.29297 0.895996H17.1035C17.1035 3.49951 17.104 6.10302 17.1035 8.70654C14.5005 8.70654 11.897 8.70654 9.29391 8.70654C9.29297 6.10302 9.29344 3.49951 9.29344 0.895996H9.29297Z"
          fill="#80BA01"
        />
        <path
          d="M0.895996 9.29297C3.49951 9.29391 6.10302 9.2925 8.70653 9.29391C8.70653 11.8974 8.70653 14.5005 8.70653 17.104H0.895996V9.29297Z"
          fill="#02A4EF"
        />
        <path
          d="M9.29344 9.29339C11.8965 9.29245 14.5 9.29339 17.1035 9.29339V17.1039H9.29297C9.29344 14.5004 9.29297 11.8969 9.29344 9.29386V9.29339Z"
          fill="#FFB902"
        />
      </g>
      <defs>
        <clipPath id="clip0_3481_36255">
          <rect width="16.2077" height="16.2079" fill="white" transform="translate(0.895996 0.895996)" />
        </clipPath>
      </defs>
    </svg>
  );
}
