import { Button } from "@fluentui/react-components";
import type { SeriesI } from "types/interfaces";

export default function MeditationSeriesListCardView({
  curated,
  handleClick,
}: {
  curated: SeriesI;
  handleClick: () => void;
}) {
  return (
    <div>
      <figure>
        <img src={curated.coverAsset.url} alt={curated.title} role="presentation" className="aspect-video rounded-lg" />
        <figcaption className="sr-only">{curated.title}</figcaption>
      </figure>

      <div className="mt-1.5 grid gap-3">
        <div>
          <p className="text-[14px] font-semibold">{curated.title}</p>
          <div className="flex items-center space-x-2 text-[10px] text-[#616161]">
            <p>Vishen</p>
            <div>•</div>
            <p>{curated.media.length} Meditations</p>
          </div>
        </div>
        <div className="flex space-x-1">
          <Button onClick={handleClick} size="small">
            Open
          </Button>
          {/* <Button size="small">Add to queue</Button> */}
        </div>
      </div>
    </div>
  );
}
