import { Sheet, SheetContent, SheetTrigger } from "components/Sheet";
import type { IQuest, IQuestNode } from "types/interfaces";
import QuestLandingModal from "./QuestLandingModal";

export const QuestLandingPage = ({ trigger, questData }: { trigger: JSX.Element; questData: IQuest | IQuestNode }) => {
  return (
    <Sheet>
      <SheetTrigger>{trigger}</SheetTrigger>
      <SheetContent
        side={"bottom"}
        className="bg-white shadow-zinc-700/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl  shadow-lg ring-1 transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide"
      >
        <QuestLandingModal questData={questData} />
      </SheetContent>
    </Sheet>
  );
};
