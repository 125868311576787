import { ApolloProvider } from "@apollo/client";
import type { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import {
  FluentProvider,
  type Theme,
  teamsDarkTheme,
  teamsHighContrastTheme,
  teamsLightTheme,
  tokens,
} from "@fluentui/react-components";
import type { TeamsUserCredential } from "@microsoft/teamsfx";
import StickyBar from "components/StickyBar";
import { TeamsFxProvider } from "context/teamsFxContext";
import { UserStateProvider } from "context/userContext";
import { cn } from "libs/classMerger";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { createApolloClient } from "services/graphql/client";
import { Toaster } from "../components/Toaster";
import Footer from "./Footer";
import Navbar from "./Navbar";

export default function Layout({
  children,
  themeString,
  teamsUserCredential,
  theme,
}: {
  children: React.ReactNode;
  teamsUserCredential?: TeamsUserCredential;
  themeString: string;
  theme?: Theme;
}) {
  const { instance } = useMsal();
  const location = useLocation();

  const isSidePanelView = location.pathname.startsWith("/appInMeeting") || location.pathname.startsWith("/configure");
  const isQuestsPath = location.pathname.startsWith("/quests");
  const isAuthenticated = useIsAuthenticated();
  const isStageView = location.pathname.startsWith("/stageView");
  const isInterestForm = location.pathname === "/interestform";

  const currentTheme = useMemo(() => {
    if (themeString === "dark" && isSidePanelView) return teamsDarkTheme;
    if (themeString === "contrast") return teamsHighContrastTheme;

    return {
      ...teamsLightTheme,
      colorNeutralBackground3: isSidePanelView || isStageView ? "transparent" : "#ffffff",
    };
  }, [themeString, isSidePanelView, isStageView]);

  const graphqlClient = createApolloClient(instance as PublicClientApplication);

  return (
    <TeamsFxProvider value={{ theme, themeString, teamsUserCredential }}>
      <FluentProvider theme={currentTheme} style={{ background: tokens.colorNeutralBackground3 }}>
        <ApolloProvider client={graphqlClient}>
          <UserStateProvider>
            <Toaster />
            {!isStageView && <Navbar />}
            <div
              className={cn(
                "flex flex-col min-h-screen max-w-[2400px] mx-auto overflow-hidden md:overflow-visible",
                isSidePanelView && "pb-11",
                isInterestForm && "bg-brown-50",
              )}
            >
              <div className={cn("flex-grow", isStageView && "flex items-center justify-center w-full")}>
                {children}
              </div>
              {!isSidePanelView && !isStageView && !isQuestsPath && <Footer />}
            </div>
            {!isAuthenticated && !isInterestForm && (
              <div className="fixed bottom-0">
                <StickyBar />
              </div>
            )}
          </UserStateProvider>
        </ApolloProvider>
      </FluentProvider>
    </TeamsFxProvider>
  );
}
