import { ENV_ENUMS } from "config/teamsConfig";
import { loadConfig } from "utils/loadEnv";

export const apiConfig = {
  storiesApiEndpoint:
    process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT
      ? "/proxy/api/stories"
      : loadConfig("REACT_APP_STORIES_API_ENDPOINT"),
  platformApiEndpoint:
    process.env.NODE_ENV === ENV_ENUMS.DEVELOPMENT ? "/proxy/graphql" : loadConfig("REACT_APP_PLATFORM_API_ENDPOINT"),
};
