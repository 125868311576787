import AssignedToYouSection from "./sections/AssignedToYouSection";
import ContinueLearningSection from "./sections/ContinueLearningSection";
import { HeroSection } from "./sections/HeroSection";
import PopularInYourIndustrySection from "./sections/PopularInYourIndustrySection";
import RecommendedForYou from "./sections/RecommendedForYou";

export default function HomeTab() {
  return (
    <div className="flex flex-col space-y-16 pt-20">
      <HeroSection />
      <div className="px-6 flex flex-col space-y-10">
        <ContinueLearningSection />
      </div>
      <div className="px-6 flex flex-col space-y-10">
        <AssignedToYouSection />
      </div>
      <div className="px-6 flex flex-col space-y-10">
        <PopularInYourIndustrySection />
      </div>
      {/* <div className="px-6 flex flex-col space-y-20 ">
        <MeditationSeriesSection />
        <MeditationSection />
      </div> */}
      <RecommendedForYou />
    </div>
  );
}
