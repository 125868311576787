import type { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import AppRoute from "router/router";

export default function App({ pca }: { pca: PublicClientApplication }) {
  console.log("THIS IS STAGING");
  return (
    <MsalProvider instance={pca}>
      <AppRoute />
    </MsalProvider>
  );
}
