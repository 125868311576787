import { HoverCard, HoverCardContent, HoverCardTrigger } from "@radix-ui/react-hover-card";
import { Sheet, SheetClose, SheetContent, SheetTrigger } from "components/Sheet";
import { useState } from "react";
import type { ICategoryNode, IQuest, IQuestNode } from "/types/interfaces";
import Quest from "../Home/components/Quest";
import QuestLandingModal from "../Quest/QuestLanding/QuestLandingModal";
import { QuestHoverCard } from "./components/QuestHoverCard";

const ProgramQuest = ({ questData, category }: { questData: IQuest | IQuestNode; category: ICategoryNode }) => {
  const [isSheetOpen, setSheetOpen] = useState(false);
  return (
    <HoverCard openDelay={0} closeDelay={0}>
      <Sheet open={isSheetOpen} onOpenChange={setSheetOpen}>
        <HoverCardTrigger asChild>
          <SheetTrigger asChild>
            <button
              type="button"
              onClick={() => setSheetOpen(true)}
              onPointerEnter={(e) => e.stopPropagation()}
              className="common-trigger"
            >
              <Quest quest={questData as IQuestNode} />
            </button>
          </SheetTrigger>
        </HoverCardTrigger>

        <HoverCardContent
          className="z-[40] animate-fade-in shadow-md fixed"
          side="top"
          align="start"
          avoidCollisions={true}
          hideWhenDetached={true}
          collisionPadding={5}
        >
          <QuestHoverCard key={questData.id} questData={questData as IQuestNode} category={category} />
        </HoverCardContent>

        <SheetContent
          side={"bottom"}
          className="bg-white shadow-zinc-700/10 ring-zinc-700/10 top-12 md:top-16 lg:top-20 rounded-2xl  shadow-lg ring-1 transition mx-auto lg:max-w-[1000px] xs:w-full xs:max-w-[1000px] !p-0 !rounded-t-3xl !rounded-b-none !overflow-y-scroll md:max-h-[calc(100dvh-64px)] max-h-[calc(100dvh-48px)] scrollbar-hide"
        >
          <SheetClose asChild>
            <QuestLandingModal questData={questData} />
          </SheetClose>
        </SheetContent>
      </Sheet>
    </HoverCard>
  );
};

export default ProgramQuest;
