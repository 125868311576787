import type { MediaI } from "types/interfaces";
import { durationToMinutes } from "utils/time";

export function MeditationMedia({
  media,
  handleClick,
}: {
  media: MediaI;
  handleClick: () => void;
}) {
  return (
    <button type="button" onClick={handleClick} className="w-full flex flex-col md:flex-row md:items-center gap-3">
      <img
        src={media.coverAsset?.url}
        alt={media.title}
        className="h-[100px] object-cover aspect-video md:aspect-square rounded-lg "
      />
      <div className="flex flex-col gap-1 items-start">
        <h6 className="title-8 mt-3 w-full truncate text-left">{media.title}</h6>
        <p className="body-small mt-1 w-full truncate text-left">
          {media.author?.name} &bull; {durationToMinutes(media.mediaAsset.duration)}
        </p>
      </div>
    </button>
  );
}
