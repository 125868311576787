import Quest from "components/Quest";
import useLibrary from "hooks/useLibrary";
import type { IQuestEdge } from "types/interfaces";
import EmptyState from "../components/EmptyState";

const Completed = ({ isEmpty }: { isEmpty?: boolean }) => {
  const status = "completed";
  const { data, loading, error } = useLibrary(status);
  const completedQuests = data?.quests.edges;

  if (loading) return <div>Loading</div>;
  console.log(completedQuests);

  return (
    <div className="flex flex-col gap-8 ">
      <p className="heading-5 hidden lg:block">Completed</p>
      {completedQuests.length === 0 ? (
        <EmptyState section="completed" />
      ) : (
        <div className="items-stretch space-y-2">
          <p className="heading-7">{`Quests(${completedQuests.length})`}</p>
          <div className={`md:flex hidden ${" md:flex-wrap"} gap-5`}>
            {completedQuests.map((quest: IQuestEdge) => (
              <div key={quest.node.id}>
                <Quest
                  name={quest.node.name}
                  author={quest.node.authors[0].name}
                  coverUrl={quest.node.coverAsset?.url}
                  pages={quest.node.resources}
                  className={"text-left"}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Completed;
