import type { Theme } from "@fluentui/react-components";
import type { TeamsUserCredential } from "@microsoft/teamsfx";
import { createContext, useContext } from "react";

interface TeamsFxContextType {
  theme?: Theme;
  themeString: string;
  teamsUserCredential?: TeamsUserCredential;
}

export const TeamsFxContext = createContext<TeamsFxContextType>({
  theme: undefined,
  themeString: "",
  teamsUserCredential: undefined,
});

export const TeamsFxProvider = TeamsFxContext.Provider;

export const useTeamsFxContext = (): TeamsFxContextType => {
  const context = useContext(TeamsFxContext);
  if (!context) {
    throw new Error("useTeamsFxContext must be used within a TeamsFxProvider");
  }
  return context;
};
