import { useLogout } from "hooks/useLogout";
import { useState } from "react";
import { Link } from "react-router-dom";
import Icons from "./icons/Icons";
import MicrosoftFullLogo from "./logo/MicrosoftFullLogo";

enum DropdownSections {
  profile = "profile",
  supportLogout = "support-logout",
}

const NavDropdownMenu = ({
  userData,
  setIsMenuOpen,
}: {
  userData: any;
  setIsMenuOpen: () => void;
}) => {
  const logout = useLogout();
  const [currentItemLabel, setCurrentItemLabel] = useState("");

  const links = () => {
    return [
      {
        icon:
          currentItemLabel === "My profile" ? (
            <Icons name="user-outlined" height={20} width={20} fill="#d03c2f" />
          ) : (
            <Icons name="user-outlined" height={20} width={20} fill="#292D38" />
          ),
        label: "My profile",
        section: DropdownSections.profile,
        link: "/account",
        onClick: setIsMenuOpen,
      },
      {
        icon:
          currentItemLabel === "My library" ? (
            <Icons name="layers-outlined" height={20} width={20} fill="#d03c2f" />
          ) : (
            <Icons name="layers-outlined" height={20} width={20} fill="#292D38" />
          ),
        label: "My library",
        section: DropdownSections.profile,
        link: "/library",
        onClick: () => setIsMenuOpen,
      },
      {
        icon:
          currentItemLabel === "Support" ? (
            <Icons name="life-buoy-help-outlined" height={20} width={20} fill="#d03c2f" />
          ) : (
            <Icons name="life-buoy-help-outlined" height={20} width={20} fill="#292D38" />
          ),
        label: "Support",
        section: DropdownSections.supportLogout,
        link: "/",
        onClick: () => setIsMenuOpen,
      },
      {
        hoverIcon: <Icons name="log-out-filled" height={20} width={20} fill="#d03c2f" />,
        icon:
          currentItemLabel === "Log out" ? (
            <Icons name="log-out-filled" height={20} width={20} fill="#d03c2f" />
          ) : (
            <Icons name="log-out-filled" height={20} width={20} fill="#292D38" />
          ),
        label: "Log out",
        section: DropdownSections.supportLogout,
        link: "/",
        onClick: () => logout(),
      },
    ];
  };

  return (
    <div className="nav-dropdown-menu w-[288px] absolute z-10 right-0 top-10 border border-cool-grey-200 border border-light rounded-2xl shadow-light  bg-white">
      {userData.customizedLogo && (
        <div className="py-3 px-4 border-b border-cool-grey-200">
          <MicrosoftFullLogo />
        </div>
      )}
      <div className="flex flex-col gap-2 px-4 py-2 border-b border-cool-grey-200">
        <div className="text-base font-semibold text-cool-grey-600">{userData?.name}</div>
        <div className="text-sm text-cool-grey-500">{userData?.email}</div>
      </div>
      {userData.isAdmin && (
        <div className="px-4 py-2 border-b border-cool-grey-200">
          <div className="py-2 text-center bg-cool-grey-200 rounded-lg">
            <Link className="text-cool-grey-600 body-sm font-semibold cursor-pointer" to={userData.adminPanelURL}>
              Go to admin panel
            </Link>
          </div>
        </div>
      )}
      <div className="px-2 py-2  border-b border-cool-grey-200">
        {links()
          .filter(({ section }) => section === DropdownSections.profile)
          .map((el) => (
            <Link
              to={el.link}
              key={el.label}
              className="flex gap-3 py-2 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg"
              onMouseEnter={() => setCurrentItemLabel(el.label)}
              onMouseLeave={() => setCurrentItemLabel("")}
              onClick={el.onClick}
            >
              <div>{el.icon}</div>
              <div className="font-semibold">{el.label}</div>
            </Link>
          ))}
      </div>
      <div className="px-4 py-2">
        {links()
          .filter(({ section }) => section === DropdownSections.supportLogout)
          .map((el) => (
            <Link
              to={el.link}
              key={el.label}
              className="flex gap-3 py-2 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-1 rounded-lg"
              onMouseEnter={() => setCurrentItemLabel(el.label)}
              onMouseLeave={() => setCurrentItemLabel("")}
              onClick={el.onClick}
            >
              <div>{el.icon}</div>
              <div className="font-semibold">{el.label}</div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default NavDropdownMenu;
