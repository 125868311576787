import { useMsal } from "@azure/msal-react";
import { authentication } from "@microsoft/teams-js";
import { authUrl, authConnectionBuilder } from "config/msalConfig";

export function useMicrosoftLogin() {
  const { instance } = useMsal();

  async function handleLogin() {
    try {
      const account = instance.getActiveAccount();
      if (account) {
        const tokenRequest = authConnectionBuilder(account);
        await instance.acquireTokenSilent(tokenRequest);
      } else {
        authentication
          .authenticate({
            url: authUrl,
            width: 600,
            height: 535,
          })
          .then((result: string) => {
            const data = JSON.parse(result) as Record<string, string>;

            for (const [key, value] of Object.entries(data.sessionStorage)) {
              sessionStorage.setItem(key, value);
            }

            window.location.reload(); // TEMPORARY SOLUTION
          })
          .catch((error) => {
            // Handle popup cancellation specifically. NNot ideal to show big error when its not an error at all

            console.error("Error during authentication:", error);
          });
      }
    } catch (error) {
      console.warn("Error during login:", error);
    }
  }

  return handleLogin;
}
