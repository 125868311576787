export function WorkwayLogoFull({
  width = "100%",
  height = "auto",
  color = "#E85546",
}) {
  return (
    <svg
      viewBox="0 0 296 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height }}
    >
      <title>workway logo</title>
      <path
        d="M9.51251 36.5432L0.919922 8.14815H8.22856L13.6607 27.4074L21.8088 8.14815H26.994L34.5495 27.4568L41.0187 8.14815H48.1298L38.1545 36.5432H31.8335L24.2779 17.5802L15.9323 36.5432H9.51251Z"
        fill={color}
      />
      <path
        d="M65.5233 37.3827C54.6097 37.3827 49.4739 32 49.4739 22.7654V22.321C49.4739 12.8395 56.0912 7.35802 65.6221 7.35802C75.3011 7.35802 81.7702 12.7901 81.7702 22.321V22.7654C81.7702 31.8519 75.9431 37.3827 65.5233 37.3827ZM65.6221 31.2593C71.4986 31.2593 74.4122 28.1481 74.4122 22.716V22.321C74.4122 16.5926 71.3505 13.3827 65.6715 13.3827C60.19 13.3827 56.8813 16.4938 56.8813 22.321V22.716C56.8813 28.1975 59.8937 31.2593 65.6221 31.2593Z"
        fill={color}
      />
      <path
        d="M86.9269 36.5432V8.14815H92.9515L93.8404 15.3086C95.075 10.5679 98.2849 7.95061 104.112 7.95061H106.729V15.1111H103.519C95.9639 15.1111 94.1367 17.6296 94.1367 24.8889V36.5432H86.9269Z"
        fill={color}
      />
      <path
        d="M111.185 0.148146H118.198V21.7778L132.568 8.14815H141.951L130.346 19.1111L142.296 36.5432H133.901L124.864 23.0123L118.198 29.2346V36.5432H111.185V0.148146Z"
        fill={color}
      />
      <path
        d="M150.041 36.5432L141.448 8.14815H148.757L154.189 27.4074L162.337 8.14815H167.523L175.078 27.4568L181.547 8.14815H188.658L178.683 36.5432H172.362L164.806 17.5802L156.461 36.5432H150.041Z"
        fill={color}
      />
      <path
        d="M199.839 37.3827C193.024 37.3827 189.419 34.5185 189.419 29.5802C189.419 26.3704 190.802 23.9506 193.419 22.5185C196.036 21.037 198.407 20.5926 205.271 19.9012C210.802 19.358 212.728 18.7654 212.728 16.642V16.5926C212.728 14.4198 210.999 12.7901 205.913 12.7901C200.234 12.7901 198.012 14.6667 197.715 18.1235H190.209C190.901 11.2593 196.234 7.35802 205.419 7.35802C215.345 7.35802 219.691 10.6667 219.691 17.9753V36.5432H213.814L213.073 30.4691C210.999 34.4691 206.604 37.3827 199.839 37.3827ZM202.16 32C208.481 32 212.777 27.5556 212.777 23.4074V22.4198C211.691 23.358 209.617 23.9012 206.407 24.2963C202.16 24.7407 200.283 25.0864 198.95 25.8765C197.715 26.6173 197.222 27.4074 197.222 28.6914V28.7407C197.222 30.8148 198.703 32 202.16 32Z"
        fill={color}
      />
      <path
        d="M223.029 48V41.7284H226.634C230.881 41.7284 232.164 40.642 234.041 36.9877L234.732 35.5062L221.498 8.14815H229.3L238.041 27.1605L238.337 28.2963L238.683 27.2099L247.325 8.14815H255.029L240.658 38.2222C236.51 46.7654 234.683 48 226.436 48H223.029Z"
        fill={color}
      />
      <path
        d="M256.969 0C258.278 0.107752 259.582 0.275706 260.881 0.464163C262.752 0.735506 264.615 1.0749 266.451 1.52685C268.19 1.95393 270.021 2.41373 271.599 3.28753C274.347 4.81132 274.902 7.9959 274.902 10.896V16.7901C262.379 10.3791 256.969 0 256.969 0Z"
        fill={color}
      />
      <path
        d="M295.07 0C293.762 0.107752 292.458 0.275706 291.159 0.464163C289.288 0.735506 287.425 1.0749 285.588 1.52685C283.85 1.95393 282.018 2.41373 280.44 3.28753C277.692 4.81132 277.137 7.9959 277.137 10.896V16.7901C289.661 10.3791 295.07 0 295.07 0Z"
        fill={color}
      />
    </svg>
  );
}
