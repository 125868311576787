import { MinusIcon, PlusIcon } from "@radix-ui/react-icons";
import { Slider } from "components/Slider";
import { useState } from "react";
import Cropper, { type Area } from "react-easy-crop";

const ImageCrop = ({
  imageSrc,
  setCropImageUrl,
}: {
  imageSrc: string | ArrayBuffer;
  setCropImageUrl: (croppedImg: string) => void;
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const MAX_ZOOM = 4;
  const MIN_ZOOM = 1;
  const zoomIn = () =>
    setZoom((prevZoom) =>
      prevZoom + 0.1 <= MAX_ZOOM ? prevZoom + 0.1 : prevZoom
    );
  const zoomOut = () =>
    setZoom((prevZoom) =>
      prevZoom - 0.1 >= MIN_ZOOM ? prevZoom - 0.1 : prevZoom
    );

  const createImage = async (img: string | ArrayBuffer) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = img.toString();
    });

  const onCropComplete = async (
    _croppedArea: Area,
    croppedAreaPixels: Area
  ) => {
    const imageRef = (await createImage(imageSrc)) as HTMLImageElement;
    const croppedUrl = getCroppedImg(imageRef, croppedAreaPixels);
    setCropImageUrl(croppedUrl);
  };

  const getCroppedImg = (image: HTMLImageElement, crop: Area) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx?.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg");
  };

  return (
    <div className="flex flex-col gap-8">
      <div className="w-full min-h-[400px] max-w-[600px] relative">
        {imageSrc && (
          <div className="flex overflow-hidden h-full">
            <Cropper
              image={imageSrc.toString()}
              crop={crop}
              zoom={zoom}
              objectFit={"contain"}
              aspect={1}
              style={{
                cropAreaStyle: {
                  boxShadow: "0 0 2000px 2000px #ffffffe6",
                },
              }}
              minZoom={MIN_ZOOM}
              maxZoom={MAX_ZOOM}
              showGrid={false}
              zoomWithScroll
              cropShape={"round"}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
        )}
      </div>
      <div className="flex gap-2 w-full mb-8 rounded-[5px] h-2 items-center align-middle justify-center">
        <button
          onClick={zoomOut}
          type="button"
          className="flex w-6 h-6 items-center"
        >
          <MinusIcon />
        </button>
        <div className="flex w-[376px] h-5 items-center">
          <Slider
            value={[zoom]}
            max={MAX_ZOOM}
            min={MIN_ZOOM}
            step={0.1}
            className="w-full"
            onValueChange={(value: number[]) =>
              setZoom(
                value[0] >= MIN_ZOOM && value[0] <= MAX_ZOOM ? value[0] : zoom
              )
            }
          />
        </div>
        <button
          onClick={zoomIn}
          type="button"
          className="flex w-6 h-6 items-center"
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  );
};

export default ImageCrop;
