const MicrosoftFullLogo = () => {
  return (
    <svg width="113" height="24" viewBox="0 0 113 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Microsoft Logo</title>
      <g id="Microsoft logo" clipPath="url(#clip0_3114_3846)">
        <g id="Microsoft">
          <path
            d="M102.957 4.35012C104.009 3.66509 105.369 3.60132 106.556 3.92772C106.566 4.63263 106.557 5.33823 106.56 6.04383C105.997 5.7956 105.334 5.63377 104.74 5.8772C104.27 6.06372 103.99 6.54646 103.917 7.0292C103.828 7.64155 103.886 8.26212 103.871 8.87789C105.075 8.88063 106.279 8.87789 107.483 8.87926C107.492 8.09755 107.474 7.31515 107.494 6.53343C108.312 6.30029 109.122 6.03903 109.937 5.79355C109.944 6.82349 109.927 7.85412 109.946 8.88475C110.76 8.87309 111.574 8.882 112.389 8.87926V10.8843C111.572 10.8507 110.753 10.8754 109.936 10.8726C109.94 12.0253 109.937 13.178 109.937 14.3314C109.95 14.9698 109.903 15.6116 109.978 16.2479C110.023 16.623 110.16 17.0386 110.511 17.2326C111.1 17.5625 111.848 17.4185 112.389 17.0564V19.0813C111.683 19.3878 110.895 19.4811 110.132 19.4269C109.407 19.3734 108.657 19.1101 108.185 18.5341C107.639 17.8834 107.498 17.0022 107.487 16.178C107.481 14.4089 107.488 12.6397 107.485 10.8699C106.28 10.8692 105.075 10.8685 103.871 10.8699C103.87 13.6463 103.87 16.4228 103.87 19.1993H101.401C101.401 16.4235 101.398 13.6484 101.402 10.8726C100.83 10.8617 100.257 10.8754 99.684 10.8637C99.686 10.2047 99.6888 9.54509 99.6826 8.88543C100.252 8.8724 100.823 8.87789 101.394 8.882C101.436 8.00566 101.302 7.10395 101.564 6.25023C101.786 5.47949 102.278 4.78417 102.957 4.34943V4.35012Z"
            fill="#777777"
          />
          <path
            d="M50.0897 4.49412C50.5875 4.41869 51.1265 4.58875 51.4563 4.97823C51.843 5.40475 51.9144 6.0836 51.6277 6.58212C51.3109 7.1444 50.6019 7.40566 49.9841 7.27057C49.3429 7.15537 48.8088 6.55949 48.8376 5.89503C48.819 5.1956 49.404 4.57229 50.0897 4.49412Z"
            fill="#777777"
          />
          <path
            d="M31.166 4.79995H34.7571C35.8748 7.63812 37.0008 10.4735 38.1198 13.3117C38.4058 14.0214 38.6732 14.7394 38.9729 15.4436C40.4108 11.8971 41.8693 8.35949 43.2963 4.80818C44.4476 4.78966 45.5996 4.80406 46.7516 4.80132C46.7489 9.60063 46.7502 14.3999 46.7516 19.1993C45.9205 19.1972 45.0901 19.2061 44.2597 19.1945C44.2686 15.6527 44.259 12.111 44.2638 8.56863C44.2652 8.40955 44.2597 8.25046 44.2488 8.09137C44.2001 8.16543 44.1569 8.24223 44.1192 8.32178C42.6922 11.9471 41.2378 15.5622 39.819 19.191C39.228 19.2123 38.6362 19.1924 38.0444 19.202C36.5722 15.5766 35.1185 11.943 33.6538 8.31492C33.6154 8.23949 33.5742 8.16612 33.5297 8.09549C33.4995 9.64726 33.5249 11.2004 33.5166 12.7522C33.5173 14.9012 33.5153 17.0502 33.5173 19.1993C32.7336 19.2013 31.9498 19.1993 31.1667 19.1999C31.1667 14.3999 31.1653 9.59995 31.1667 4.79995H31.166Z"
            fill="#777777"
          />
          <path
            d="M56.8851 8.86966C58.0769 8.53023 59.3619 8.54395 60.553 8.88063C60.7985 8.9492 61.0364 9.04452 61.2572 9.17412C61.2435 9.96063 61.2572 10.7478 61.2497 11.535C60.4536 10.9268 59.4435 10.5414 58.4314 10.669C57.6277 10.7417 56.8611 11.1586 56.3804 11.8079C55.7626 12.613 55.62 13.682 55.7262 14.6667C55.8058 15.4374 56.1246 16.2075 56.724 16.7183C57.3466 17.2717 58.2195 17.4678 59.0341 17.4061C59.8392 17.3135 60.5989 16.9727 61.2497 16.4969C61.2565 17.2422 61.2462 17.9876 61.2552 18.733C60.2458 19.3337 59.0355 19.4886 57.8801 19.4317C56.6979 19.3693 55.5192 18.9215 54.6634 18.0905C53.7336 17.1942 53.2289 15.9161 53.1692 14.6358C53.1089 13.3049 53.3797 11.9163 54.1457 10.8047C54.7875 9.8564 55.7914 9.18509 56.8851 8.86966Z"
            fill="#777777"
          />
          <path
            d="M83.8577 8.71263C84.9994 8.51172 86.1946 8.65915 87.2753 9.06852C87.2766 9.82555 87.2753 10.5819 87.276 11.3382C86.521 10.8191 85.5987 10.5401 84.6826 10.5723C84.2211 10.5901 83.7137 10.7855 83.5032 11.2244C83.3358 11.6585 83.4517 12.2276 83.8611 12.4875C84.5612 12.9558 85.3964 13.1547 86.1219 13.5743C86.6931 13.8953 87.2472 14.3149 87.5365 14.9197C88.0803 16.0525 87.8499 17.5494 86.894 18.3942C85.9813 19.2473 84.6668 19.4831 83.4579 19.4379C82.5994 19.3823 81.7368 19.213 80.9475 18.8619C80.9509 18.0658 80.9427 17.2703 80.9516 16.4749C81.6154 16.957 82.3813 17.3019 83.1918 17.4418C83.7562 17.5337 84.3699 17.5378 84.8959 17.2854C85.3992 17.0351 85.4897 16.3199 85.1907 15.8825C84.9109 15.5451 84.5002 15.3593 84.1128 15.1769C83.3852 14.8498 82.6207 14.5762 81.9816 14.0859C81.5324 13.7355 81.1923 13.2418 81.0593 12.685C80.8618 11.8642 80.9235 10.9391 81.4008 10.2219C81.9459 9.386 82.8929 8.88475 83.8577 8.71263Z"
            fill="#777777"
          />
          <path
            d="M66.7786 9.03766C67.4602 8.62829 68.3262 8.594 69.0702 8.84429C69.0668 9.66783 69.0702 10.4914 69.0682 11.3149C68.5813 10.9995 67.9738 10.8644 67.3992 10.9227C66.6984 11.0022 66.1429 11.5405 65.8549 12.1577C65.5422 12.8111 65.4833 13.5503 65.5032 14.2649C65.5032 15.9099 65.5038 17.5549 65.5032 19.1999H63.0689C63.0668 15.7618 63.0716 12.3229 63.0668 8.88475C63.8794 8.87446 64.692 8.87857 65.5045 8.88269C65.5011 9.47309 65.5045 10.0635 65.5025 10.6546C65.7665 10.0121 66.1594 9.38806 66.7786 9.03766Z"
            fill="#777777"
          />
          <path
            d="M49.0618 8.88269C49.8805 8.87926 50.7 8.87103 51.5187 8.8868C51.5091 12.3243 51.518 15.7625 51.5146 19.1999H49.0632C49.0611 15.7611 49.0632 12.3222 49.0618 8.88269Z"
            fill="#777777"
          />
          <path
            d="M79.3059 11.2889C78.8773 10.3357 78.1038 9.5396 77.1493 9.10897C76.0494 8.6084 74.7994 8.5412 73.6145 8.69206C72.3699 8.84909 71.1582 9.43469 70.3683 10.4269C69.6469 11.3129 69.2958 12.4511 69.2286 13.5805C69.1292 14.9115 69.3514 16.3241 70.1331 17.4349C70.7989 18.3935 71.8501 19.0525 72.9857 19.2953C73.9347 19.4948 74.9276 19.4955 75.8766 19.298C76.9868 19.0697 78.0236 18.4553 78.7114 17.5487C79.3834 16.695 79.7208 15.6185 79.7969 14.5439C79.8744 13.4434 79.7633 12.3037 79.3059 11.2889ZM76.921 16.0258C76.6851 16.5565 76.2531 17.005 75.708 17.2175C75.0702 17.4623 74.3537 17.4767 73.6954 17.306C73.0254 17.1298 72.457 16.6457 72.1477 16.0292C71.75 15.2351 71.6904 14.317 71.7727 13.4461C71.8474 12.6541 72.123 11.8395 72.7265 11.293C73.164 10.8843 73.7605 10.6847 74.3509 10.6518C75.0929 10.6031 75.8828 10.813 76.4163 11.3526C76.9868 11.9135 77.2076 12.7275 77.2686 13.5037C77.3125 14.3533 77.2776 15.2386 76.921 16.0258Z"
            fill="#777777"
          />
          <path
            d="M99.0812 11.5871C98.7013 10.5627 97.9409 9.67675 96.9603 9.18646C95.8357 8.61869 94.5274 8.53572 93.2952 8.68863C92.3016 8.81617 91.3251 9.19332 90.5667 9.85915C89.641 10.6566 89.0979 11.8292 88.9381 13.0285C88.7859 14.2278 88.8373 15.4909 89.3263 16.6134C89.7953 17.7147 90.721 18.6089 91.8346 19.047C92.9338 19.4797 94.1557 19.5284 95.3125 19.3412C96.4954 19.1458 97.6159 18.5238 98.3482 17.5666C99.1306 16.5778 99.4673 15.2982 99.4652 14.0516C99.4824 13.2171 99.3781 12.3709 99.0812 11.5871ZM96.854 15.0857C96.7594 15.6287 96.5715 16.1705 96.2156 16.6004C95.857 17.0324 95.3235 17.2957 94.7729 17.3766C94.1818 17.4651 93.5592 17.4226 93.0065 17.1846C92.4099 16.9323 91.9477 16.4159 91.7043 15.8221C91.4115 15.1145 91.3532 14.3307 91.4115 13.5737C91.4677 12.8406 91.6755 12.0884 92.1596 11.5186C92.6012 10.9865 93.2787 10.6923 93.9617 10.6539C94.6591 10.6052 95.4037 10.7663 95.94 11.2374C96.4344 11.6475 96.708 12.2619 96.8335 12.8804C96.9747 13.6059 96.9733 14.3567 96.854 15.0857Z"
            fill="#777777"
          />
        </g>
        <path id="Vector" d="M0 0H11.4171C11.4165 3.80571 11.4171 7.61143 11.4165 11.4171H0V0Z" fill="#F25022" />
        <path
          id="Vector_2"
          d="M12.583 0H24.0002C24.0002 3.80571 24.0008 7.61143 24.0002 11.4171C20.1951 11.4171 16.3894 11.4171 12.5844 11.4171C12.583 7.61143 12.5837 3.80571 12.5837 0H12.583Z"
          fill="#80BA01"
        />
        <path
          id="Vector_3"
          d="M0 12.5822C3.80571 12.5835 7.61143 12.5815 11.4171 12.5835C11.4171 16.3892 11.4171 20.1943 11.4171 24H0V12.5822Z"
          fill="#02A4EF"
        />
        <path
          id="Vector_4"
          d="M12.5837 12.5835C16.3887 12.5821 20.1944 12.5835 24.0002 12.5835V24.0006H12.583C12.5837 20.1949 12.583 16.3892 12.5837 12.5842V12.5835Z"
          fill="#FFB902"
        />
      </g>
      <defs>
        <clipPath id="clip0_3114_3846">
          <rect width="112.389" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MicrosoftFullLogo;
