import { useQuery } from "@apollo/client";
import { useIsAuthenticated } from "@azure/msal-react";
import { type ReactNode, createContext, useContext, useEffect, useReducer } from "react";
import gqlQueries from "services/graphql/queries";

export enum UserAccessStateEnum {
  Loading = "loading",
  Unauthenticated = "unauthenticated",
  Freemium = "freemium",
  Premium = "premium",
}

interface OrganizationData {
  division?: string;
  logoFullUrl?: string;
  logoSquareUrl?: string;
  name: string;
  role: string;
}

interface UserStateContextProps {
  userAccessState: UserAccessStateEnum;
  organizationData: OrganizationData | null;
  loading: boolean;
  error: Error | null;
}

type Action =
  | { type: "SET_LOADING" }
  | { type: "SET_AUTHENTICATED"; payload: OrganizationData | null }
  | { type: "SET_UNAUTHENTICATED" }
  | { type: "SET_ERROR"; payload: Error };

function userStateReducer(state: UserStateContextProps, action: Action): UserStateContextProps {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Loading,
        loading: true,
      };
    case "SET_AUTHENTICATED":
      return {
        ...state,
        userAccessState: action.payload ? UserAccessStateEnum.Premium : UserAccessStateEnum.Freemium,
        organizationData: action.payload,
        loading: false,
        error: null,
      };
    case "SET_UNAUTHENTICATED":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Unauthenticated,
        organizationData: null,
        loading: false,
        error: null,
      };
    case "SET_ERROR":
      return {
        ...state,
        userAccessState: UserAccessStateEnum.Unauthenticated,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
}

const initialState: UserStateContextProps = {
  userAccessState: UserAccessStateEnum.Loading,
  organizationData: null,
  loading: true,
  error: null,
};

export const UserStateContext = createContext<UserStateContextProps | undefined>(undefined);

interface UserStateProviderProps {
  children: ReactNode;
}

export const UserStateProvider = ({ children }: UserStateProviderProps) => {
  const isAuthenticated = useIsAuthenticated();
  const [state, dispatch] = useReducer(userStateReducer, initialState);

  const { loading, data, error } = useQuery<{
    currentOrganization: OrganizationData | null;
  }>(gqlQueries.getCurrentOrg);

  useEffect(() => {
    if (loading) {
      dispatch({ type: "SET_LOADING" });
    } else if (!isAuthenticated || error) {
      dispatch({ type: "SET_UNAUTHENTICATED" });
    } else if (data?.currentOrganization) {
      dispatch({
        type: "SET_AUTHENTICATED",
        payload: data.currentOrganization,
      });
    } else {
      dispatch({ type: "SET_AUTHENTICATED", payload: null });
    }
  }, [isAuthenticated, loading, data, error]);

  return <UserStateContext.Provider value={state}>{children}</UserStateContext.Provider>;
};

export const useUserState = () => {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error("useUserState must be used within a UserStateProvider");
  }
  return context;
};
