import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";

export function useLogout() {
  const { instance } = useMsal();

  const logout = useCallback(() => {
    instance
      .logoutPopup({
        postLogoutRedirectUri: window.location.origin,
      })
      .catch((e) => {
        console.error("Logout error:", e);
      });
  }, [instance]);

  return logout;
}
