const MicrosoftLogo = () => {
  return (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <title>Microsoft Logo</title>
      <g clipPath="url(#clip0_3114_4375)">
        <path d="M0.0800781 0H13.5732C13.5724 4.4977 13.5732 8.9954 13.5724 13.4931H0.0800781V0Z" fill="#F25022" />
        <path
          d="M14.5859 0H28.079C28.079 4.4977 28.0798 8.9954 28.079 13.4931C23.5821 13.4931 19.0844 13.4931 14.5876 13.4931C14.5859 8.9954 14.5867 4.4977 14.5867 0H14.5859Z"
          fill="#80BA01"
        />
        <path
          d="M0.0800781 14.5061C4.57778 14.5077 9.07548 14.5053 13.5732 14.5077C13.5732 19.0054 13.5732 23.5023 13.5732 28H0.0800781V14.5061Z"
          fill="#02A4EF"
        />
        <path
          d="M14.5867 14.5068C19.0836 14.5052 23.5813 14.5068 28.079 14.5068V27.9999H14.5859C14.5867 23.5022 14.5859 19.0045 14.5867 14.5076V14.5068Z"
          fill="#FFB902"
        />
      </g>
      <defs>
        <clipPath id="clip0_3114_4375">
          <rect width="27.9995" height="28" fill="white" transform="translate(0.0800781)" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default MicrosoftLogo;
