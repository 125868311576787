import axios from "axios";
import { Combobox } from "components/ComboBox";
import { Dialog, DialogClose, DialogContent } from "components/Dialog";
import Icons from "components/icons/Icons";
import { apiConfig } from "config/apiConfig";
import type React from "react";
import { useEffect, useState } from "react";
import type { IOption } from "types/interfaces";

interface Author {
  designation: string | null;
  location: string;
  name: string;
}

interface Country {
  iso2_code: string;
  iso3_code: string;
  name: string;
}

interface Media {
  name: string;
  url: string;
}

interface Story {
  id: number;
  author: Author;
  country: Country | null;
  excerpt: string;
  title: string;
  media: Media;
}

const Story = ({ story }: { story: Story }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <div className="break-inside-avoid">
      <div className="rounded-2xl border border-medium max-w-full border-cool-grey-300">
        <div className="p-6">
          <p className="title-7 text-cool-grey-700 pb-2">{story.title}</p>
          <button type="button" className="button-text-small text-red-600 pb-6" onClick={() => setIsModalOpen(true)}>
            Read more
          </button>
          <div className="flex space-x-3">
            <img alt="" src={story.media.url} data-testid="story-author-image" className="rounded-md w-16 h-16" />
            <div className="flex flex-col justify-center">
              <h3 className="title-8 text-cool-grey-700" data-testid="story-author-name">
                {story.author.name}
              </h3>
              <h2 className="title-9 text-cool-grey-500" data-testid="story-author-designation">
                {story.author.designation}
              </h2>
              <h3 className="caption-disclaimer text-cool-grey-450" data-testid="story-author-location">
                {story.author.location}
              </h3>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && <StoryDetailsModal story={story} onClose={() => setIsModalOpen(false)} />}
    </div>
  );
};

interface StoryDetailsModalProps {
  story: Story;
  onClose: () => void;
}

const StoryDetailsModal: React.FC<StoryDetailsModalProps> = ({ story, onClose }) => {
  return (
    <Dialog open={true} onOpenChange={onClose}>
      <DialogContent className="fixed flex items-center justify-center p-6 z-[70]">
        <div className="relative w-full max-w-2xl bg-white rounded-2xl">
          <div className="flex justify-end items-start">
            <DialogClose asChild>
              <button
                type="button"
                className="flex items-center text-cool-grey-700 button-text-small md:pb-5 xs:pb-3"
                aria-label="Close"
              >
                Close
                <Icons name={"x-outlined"} height={20} width={20} />
              </button>
            </DialogClose>
          </div>
          <div className="relative">
            {/* <div className="absolute bottom-0 bg-gradient-to-t from-white w-full h-[33px] z-100"> </div> */}
            <div className="body-small text-cool-grey-600 max-h-[65vh] overflow-hidden overflow-y-auto pb-[33px]">
              {story.excerpt}
            </div>
          </div>
          <div className="pt-4">
            <div className="flex space-x-3">
              <img alt="" src={story.media.url} className="rounded-lg w-16 h-16" />
              <div className="flex flex-col justify-center">
                <h3 className="title-8 text-cool-grey-700">{story.author.name}</h3>
                <h2 className="title-9 text-cool-grey-500">{story.author.designation}</h2>
                <h3 className="caption-disclaimer text-cool-grey-450">{story.author.location}</h3>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const Stories = () => {
  type Country = {
    name: string;
    code: string;
  };
  type RequestParam = {
    per_page: number;
    platform_pid: number;
    country?: string;
  };
  const [countries, setCountries] = useState<Country[]>([]);
  const [selectedOption, setSelectedOption] = useState<IOption>({
    name: "All countries",
    value: "",
  });
  const [stories, setStories] = useState<Story[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const onCitySelect = (selected: IOption) => {
    setSelectedOption(selected);
  };
  const fetchStories = async (request: RequestParam) => {
    const response = await axios.get(apiConfig.storiesApiEndpoint, {
      params: request,
    });
    return response.data.data;
  };

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = "https://secure.mindvalley.com/v3/countries.js";
    script.async = true;

    script.onload = () => {
      if (window.countries) {
        setCountries([{ name: "All countries", code: "" }, ...window.countries]);
      }
    };
    document.body.appendChild(script);
    return script;
  };

  useEffect(() => {
    const script = loadScript();
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  useEffect(() => {
    let request: RequestParam = { platform_pid: 132, per_page: 50 };
    if (selectedOption.name !== "All countries") {
      request = { ...request, country: selectedOption.value };
    }
    fetchStories(request)
      .then((fetchedStories) => {
        setStories(fetchedStories);
        setIsLoading(false);
      })
      .catch((error) => {
        if (axios.isAxiosError(error) && error.response) {
          setError(error.response.data.message || "Failed to fetch stories");
        } else {
          setError("An unexpected error occurred");
        }
        setIsLoading(false);
      });
  }, [selectedOption.name]);
  return (
    <>
      {isLoading ? (
        <div>loading</div>
      ) : error ? (
        <div>{error}</div>
      ) : (
        <div data-testid="quest-landing-stories-tab" className="xl:px-10 md:px-9 px-4 md:pb-8 pb-10">
          <div data-phx-id="m38-phx-F_lhawmvA_6QimSS" className="space-y-6">
            <div className="mt-4">
              <div data-phx-id="m39-phx-F_lhawmvA_6QimSS" className="flex justify-end" data-testid="country-dropdown">
                <p className="py-4 pr-3 title-8 text-cool-grey-600 text-center" data-testid="country-dropdown-title">
                  Read stories from
                </p>
                <div className="border border-cool-grey-300 rounded-[8px]">
                  <Combobox
                    options={countries.map((country) => ({
                      value: country.code,
                      name: country.name,
                    }))}
                    onSelect={onCitySelect}
                    placeholderText="Select your country"
                    selectedOption={{
                      name: "All countries",
                      value: "All countries",
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="lg:columns-3 md:columns-2 xs:columns-1 md:gap-4 space-y-4">
              {stories.map((story) => (
                <Story key={story.id} story={story} />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
