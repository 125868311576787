import useMeditation from "hooks/useMeditation";
import { MeditationCard } from "tabs/Home/components/MeditationCard";

export function SelectedMeditationsSection() {
  const { meditationData } = useMeditation();
  return (
    <div>
      <div className="mt-10 flex flex-col md:flex-row items-end gap-10">
        <h3 className="heading-3 w-full md:w-2/5">Meditations</h3>
        <p className="body w-full md:w-3/5">
          Dive into our meditations and discover a wealth of tools to enhance your daily life. Focusing on various
          themes from stress relief to self-love, grounding to success, each session offers expert guidance for profound
          results. Elevate your daily routine and experience the transformative power of consistent meditation practice.
        </p>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 mt-8">
        {meditationData.map((data) => (
          <div key={data.resource.id} className="size-full">
            <MeditationCard item={data.resource} className="w-full" />
          </div>
        ))}
      </div>
    </div>
  );
}
