import * as SliderPrimitive from "@radix-ui/react-slider";
import { cn } from "libs/classMerger";
import type React from "react";
import { useEffect, useState } from "react";

interface MediaPlayerV2SeekBarProps {
  min?: number;
  max?: number;
  step?: number;
  progress?: number;
  showDuration?: boolean;
  onValueChange: (value: number[]) => void;
  className?: string;
  currentDuration?: number;
  totalDuration?: number;
  customStyle?: React.CSSProperties;
}

const MediaPlayerV2SeekBar = ({
  showDuration,
  min = 0,
  max = 100,
  step = 1,
  progress = 0,
  className,
  currentDuration = 0,
  totalDuration = 0,
  onValueChange,
}: MediaPlayerV2SeekBarProps) => {
  const [progressValue, setProgress] = useState<number>(progress);

  useEffect(() => {
    setProgress(progress);
  }, [progress]);

  const onSliderValueChange = (value: number[]) => {
    onValueChange(value);
  };

  const formatTime = (totalSeconds: number): string => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = Math.floor(totalSeconds % 60);

    const formattedHours = !Number.isNaN(hours) ? hours.toString().padStart(2, "0") : "00";
    const formattedMinutes = !Number.isNaN(minutes) ? minutes.toString().padStart(2, "0") : "00";
    const formattedSeconds = !Number.isNaN(seconds) ? seconds.toString().padStart(2, "0") : "00";

    if (hours > 0) {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  return (
    <div className="w-full flex items-center justify-between gap-4 px-2">
      {showDuration ? (
        <div className="text-white caption-disclaimer w-[50px] text-left">{formatTime(currentDuration)}</div>
      ) : null}
      <SliderPrimitive.Root
        className={cn("relative flex w-full touch-none select-none items-center bg-cool-grey-200", className)}
        defaultValue={[progressValue]}
        min={min}
        max={max}
        step={step}
        onValueChange={onSliderValueChange}
        value={[progressValue]}
      >
        <SliderPrimitive.Track className="relative h-2 w-full grow overflow-hidden rounded-full bg-cool-grey-200 h-[3px]">
          <SliderPrimitive.Range className="absolute h-full bg-primary bg-red-500" />
        </SliderPrimitive.Track>
        <SliderPrimitive.Thumb
          title={formatTime(currentDuration)}
          className="cursor-pointer block h-5 w-5 rounded-full bg-red-500 border-red border-2 ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
        />
      </SliderPrimitive.Root>
      {showDuration ? (
        <div className="text-white caption-disclaimer w-[50px] text-right">{formatTime(totalDuration)}</div>
      ) : null}
    </div>
  );
};

export default MediaPlayerV2SeekBar;
