import { Button } from "components/Button";
import { Dialog, DialogContent } from "components/Dialog";
import Icons from "components/icons/Icons";
import useQuestConsumption from "hooks/useQuestConsumption";
import type { IQuest } from "types/interfaces";

interface StartProgramModalProps {
  quest: IQuest;
  onClose: () => void;
}

export const StartProgramModal = (data: StartProgramModalProps) => {
  const { enrollUser, loading, response, error } = useQuestConsumption().enrollUser;
  const handleStartProgram = () => {
    const releaseId = data?.quest?.releases ? data?.quest?.releases[0].id : "";
    enrollUser({
      variables: {
        input: {
          releaseId,
        },
      },
    });
  };
  return (
    <Dialog open={true} onOpenChange={data.onClose}>
        <DialogContent className="fixed flex items-center justify-center p-4 lg:p-10 z-[70] w-[360px] lg:w-[460px] rounded-[16px]">
          <div className="relative w-full rounded-2xl">
            <div className={"flex gap-6 flex-col"}>
              <div className={"flex gap-3 flex-col items-start"}>
                {data.quest.wordmarkAsset?.url ? (
                  <img
                    src={data.quest.wordmarkAsset?.url}
                    className={"invert max-h-[80px] max-w-[300px] lg:max-h-[96px] lg:max-w-[380px] "}
                    alt="Quest Wordmark"
                  />
                ) : (
                  <p className={"heading-3"}>{data.quest.name}</p>
                )}
                <p className={"title-7 w-full"}>with {data.quest.authors[0].name}</p>
              </div>
              <div className={"flex flex-col gap-4"}>
                <p className={"body"}>Start this program now to unlock all learnings and track your progress.</p>
                <Button type="button" id="leave-program-button" className={"w-[165px]"} onClick={handleStartProgram}>
                  <Icons name={"play-filled"} width={20} height={20} className={"mr-1.5"} />
                  <p>Start program</p>
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
    </Dialog>
  );
};
