import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { DataService } from "services/dataService";
import type { SeriesI } from "types/interfaces";

function useCuratedMeditationSeries() {
  const { instance } = useMsal();
  const [curatedData, setCuratedData] = useState<SeriesI[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    const fetchQuest = async () => {
      const dataService = new DataService(instance);
      try {
        const data = await dataService.getCuratedSeries();
        setCuratedData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchQuest();
  }, []);

  return { curatedData, loading, error };
}

export default useCuratedMeditationSeries;
