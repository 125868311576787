import axios from "axios";

// Create an Axios instance with a custom domain
const proxyAxiosClient = axios.create({
  baseURL: "http://localhost:3978", // Replace with your custom domain
  headers: {
    "Content-Type": "application/json",
    // Add other custom headers here
  },
});

// Optionally add interceptors
proxyAxiosClient.interceptors.request.use(
  (config) => {
    // Modify config before sending the request
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  },
);

proxyAxiosClient.interceptors.response.use(
  (response) => {
    // Modify response before returning it
    return response;
  },
  (error) => {
    // Handle response error
    return Promise.reject(error);
  },
);

export default proxyAxiosClient;
