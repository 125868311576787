import { Button } from "components/Button";
import { MediaPlayer } from "components/MediaPlayer";
import Icons from "components/icons/Icons";
import { useTeamsFxContext } from "context/teamsFxContext";
import { cn } from "libs/classMerger";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function StageView() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isStageViewSupported = queryParams.get("isStageViewSupported") ?? false;
  const { themeString } = useTeamsFxContext();

  return (
    <div className="p-6">
      {/* TODO:: Temporary styling and back function for the stage view */}
      {isStageViewSupported && (
        <Button
          onClick={() => navigate(-1)}
          variant="ghost"
          className={cn(
            "absolute md:static top-10 md:top-0 flex items-center gap-2",
            themeString === "dark" ? "text-white hover:bg-white-10a" : "text-grey-700 hover:bg-black-8a",
          )}
        >
          <Icons
            name="arrow-left-circle-outlined"
            width={24}
            height={24}
            className={cn(themeString === "dark" ? "dark:fill-white" : "fill-[#0F131A]")}
          />
          Back
        </Button>
      )}

      <MediaPlayer
        playerClasses="rounded-2xl overflow-hidden mt-6"
        videoClasses="object-contain lg:object-cover max-w-[1280px]" // Hard max width for now to avoid stretching
      />
    </div>
  );
}
