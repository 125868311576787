import { type AuthenticationResult, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "config/msalConfig";
import { createRoot } from "react-dom/client";
import App from "./App";
import "styles/globals.css";
import "styles/helper.css";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  console.log("MSAL instance initialized", msalInstance);

  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    console.log("Event callback called with event: ", event);
    if (
      event.eventType === EventType.LOGIN_SUCCESS &&
      event.payload &&
      (event.payload as AuthenticationResult).account
    ) {
      const account = (event.payload as AuthenticationResult).account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  // biome-ignore lint/style/noNonNullAssertion: <explanation>
  const root = createRoot(container!);
  root.render(<App pca={msalInstance} />);
});
