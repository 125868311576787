import { useQuery } from "@apollo/client";
import gqlQuery from "services/graphql/queries";
import type { IQuestEdge } from "types/interfaces";

function useQuest(categoryId?: string) {
  const { loading, error, data } = useQuery(gqlQuery.getQuestByCategory, {
    skip: !categoryId,
    variables: categoryId ? { category: categoryId } : {},
  });
  return { questData: data?.quests?.edges?.map((edge: IQuestEdge) => edge.node) || [], loading, error };
}

export default useQuest;
