import "tabs/Home/components/Quest.css";
import { app, stageView } from "@microsoft/teams-js";
import { teamsConfig } from "config/teamsConfig";
import { cn } from "libs/classMerger";
import dateFormater from "libs/dateFormater";
import { useNavigate } from "react-router-dom";
import type { IResource } from "types/interfaces";
import Icons from "./icons/Icons";
import { Label } from "./ui/label";

export default function Quest(props: {
  coverUrl?: string;
  name?: string;
  author?: string;
  pages?: IResource[];
  className?: string;
  isEnrolled?: boolean;
  availableDays?: number;
}) {
  const { coverUrl, name, author, pages, availableDays, className } = {
    name: "Calm Mind",
    author: "Dr. Caroline Leaf",
    coverUrl: "https://assets.mindvalley.com/api/v1/assets/96164bb0-7364-42cd-b046-9c02fe9b0c09.jpg",
    pages: [],
    availableDays: 0,
    ...props,
  };

  const navigate = useNavigate();
  const formattedDueDate = dateFormater(availableDays);

  //const student_numbers = 30;
  function handleClick() {
    if (!pages || !Array.isArray(pages) || pages.length === 0) {
      console.error("Pages array is empty or invalid");
      return;
    }

    // const mediaAsset = pages
    //   .flatMap((page) => page.sections) // Flatten the array of arrays
    //   .find((section) => section.primaryAsset && section.primaryAsset.contentType === "video/mp4")?.primaryAsset;

    const mediaAsset = pages[0].mediaAsset;
    if (!mediaAsset) {
      console.error("No media asset found");
      return;
    }

    app.initialize().then(() => {
      if (stageView.isSupported()) {
        stageView
          .open({
            appId: teamsConfig.appId,
            contentUrl: `${window.location.origin}/stageView?mediaUrl=${mediaAsset.url}&thumbnailUrl=${coverUrl}&autoplay=true`,
            title: name,
            websiteUrl: window.location.origin,
            openMode: stageView.StageViewOpenMode.modal,
            threadId: "",
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // [ ] replace with quest landing modal
        navigate(
          `/stageView?mediaUrl=${encodeURIComponent(mediaAsset.url)}&thumbnailUrl=${encodeURIComponent(
            coverUrl,
          )}&autoplay=true&mimeType=${mediaAsset.contentType}&isStageViewSupported=false`,
        );
      }
    });
  }

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cn("cursor-pointer group flex flex-row gap-3 sm:gap-0 sm:flex-col", className)}
    >
      <div className="rounded-2xl w-full relative flex-grow h-auto mb-3 mr-3 md:mr-0 overflow-hidden">
        <img
          className="rounded-2xl object-contain transition-all ease-in-out duration-500 hover:scale-105 "
          src={coverUrl}
          alt={name}
        />
        <Label className=" absolute flex w-full bottom-0 bg-black-6a backdrop-blur-[6px] rounded-b-2xl  gap-2 self-stretch  lg:px-2 px-2  lg:py-3 py-1">
          <Icons name="calendar-reminder-outlined" width={14} height={14} fill="white" />
          <p className="lg:title-10 title-11 text-white">Complete by {formattedDueDate}</p>
        </Label>
      </div>

      <div className="flex flex-col w-full">
        <h6 className="lg:title-7 title-8 mb-1 line-clamp-2 sm:line-clamp-1">{name}</h6>
        <p className="lg:body-small caption-disclaimer text-cool-grey-450 mb-2 line-clamp-1">{author}</p>
      </div>
    </button>
  );
}
