import Icons from "components/icons/Icons";
import type { IQuest } from "types/interfaces";
import { convertSecondsToReadableFormat } from "utils/time";
import Outcomes from "./Outcomes";
import TrainerInfo from "./TrainerInfo";
import { formattedNumber } from "utils/numbers";

const Overview = (quest: IQuest) => {
  const Spacer = () => <div className="p-6 mb-2" />;
  const parseContent = (htmlString: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const items: {
      title: string
      description: string
    }[] = [];
    const listItems = doc.querySelectorAll('li');
    listItems.forEach((li) => {
      const title = li.textContent?.trim() || '';
      let description = '';
      let nextNode = li.parentElement?.nextSibling;
      while (nextNode) {
        if (nextNode?.nodeType === Node.TEXT_NODE && nextNode.textContent?.trim()) {
          description += nextNode.textContent.trim();
          break;
        }
        nextNode = nextNode.nextSibling;
      }
      items.push({
        title,
        description: description.trim(),
      });
    });
    return items;
  };
  const getWhatYouWillLearn = () => quest.pages.find(page => page.type === 'info')?.sections.find(section => section.type === 'text')?.info.body;

  const content = parseContent(getWhatYouWillLearn() ?? "");
  return (
    <div>
      <section className="pb-4 md:pb-9 lg:pb-10 px-4 md:px-9 lg:px-10 space-y-8">
        <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-6">
          <div className="heading-8 text-cool-grey-700 w-full md:w-[180px]">Description</div>
          <p className="body-small text-cool-grey-600 flex-1">{quest.description}</p>
        </div>
        <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-6">
          <div className="heading-8 text-cool-grey-700 w-full md:w-[180px]">Stats</div>
          <div className="flex-1 space-y-4">
            <div className="flex flex-row gap-x-1.5 items-center">
              <Icons name="book-open-outlined" />
              <p className="title-8 text-cool-grey-600">{quest.type === 'daily' ? quest.userProgress?.totalDays : quest.userProgress?.totalLessons} lessons</p>
            </div>
            <div className="flex flex-row gap-x-1.5 items-center">
              <Icons name="clock-outlined" />
              <p className="title-8 text-cool-grey-600">{convertSecondsToReadableFormat(quest.duration)}</p>
            </div>
            {quest.trailerAsset?.captions && quest.trailerAsset?.captions?.length > 0 && <div className="flex flex-row gap-x-1.5 items-center">
              <Icons name="caption-outlined" />
              <p className="title-8 text-cool-grey-600">languages</p>
            </div>}
            <div className="flex flex-row gap-x-1.5 items-center">
              <Icons name="users-outlined" />
              <p className="title-8 text-cool-grey-600">{formattedNumber(quest.enrollmentsCount ?? 0)} enrolled</p>
            </div>
          </div>
        </div>

        <Outcomes outcomes={quest.outcomes ?? []} />

        {content.length > 0 && <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-6">
          <div className="heading-8 text-cool-grey-700 w-full md:w-[180px]">What you’ll learn</div>
          <div className="flex flex-col flex-1 body-small gap-y-5">
            {content.map(({ title, description }) => (
              <div key={title} className="flex flex-col">
                <ul className="flex gap-x-2 items-center">
                  <Icons name={"check-outlined"} fill="#18C176" />
                  <li className="title-8 text-cool-grey-700">{title}</li>
                </ul>
                <p className="body-small text-cool-grey-600 mt-1 pl-6">{description}</p>
              </div>
            ))}
          </div>
        </div>}

        <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-6">
          <div className="heading-8 text-cool-grey-700 w-full md:w-[180px]">About the trainer</div>
          <TrainerInfo authors={quest.authors ?? []} />
        </div>
        {/*<div className="border-t border-cool-grey-250 w-full"></div>*/}
        {/*<TrailerAndPreview/>*/}
      </section>
      <Spacer />
    </div>
  );
};

export default Overview;
