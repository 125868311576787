import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { useState } from "react";
import type { ICurrentProfile } from "types/interfaces";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "../../components/Dialog";

const PasswordUpdatedDialog = ({
  open = false,
  onCancel,
}: {
  open?: boolean;
  onConfirm?: () => void;
  onCancel: () => void;
}) => {
  return (
    <Dialog open={open}>
      <DialogContent className="z-100 p-10 flex flex-col gap-4">
        <DialogHeader>
          <DialogTitle>
            <div className="title-6">Password reset request received</div>
          </DialogTitle>
        </DialogHeader>
        <p className="body text-cool-grey-600">A password reset email has been sent. Please check your inbox.</p>
        <DialogFooter className="flex justify-start">
          <Button size={"link"} className="py-3 px-[14px]" onClick={onCancel} variant={"neutral"} type="submit">
            Okay
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const SecuritySettings = ({
  profileData,
}: {
  profileData?: ICurrentProfile;
}) => {
  const [openPasswordUpdateDialog, showPasswordUpdateDialog] = useState(false);
  return (
    <div className="flex flex-col gap-8 self-stretch">
      <PasswordUpdatedDialog open={openPasswordUpdateDialog} onCancel={() => showPasswordUpdateDialog(false)} />
      <div className="hidden lg:heading-5">Security settings</div>
      <div className="heading-6">Login</div>

      <div className="flex gap-x-6 self-stretch">
        <div className="flex w-full flex-col gap-2">
          <div className="flex gap-2">
            <div className="title-7 text-cool-grey-700">Email address</div>
          </div>
          <div className="title-7 text-cool-grey-500 break-words">{profileData?.email}</div>
        </div>
      </div>
      <div className="flex w-full flex-col gap-2">
        <div className="flex justify-between">
          <div className="heading-7">Password</div>
          <button
            type="button"
            className="rounded-full flex align-middle py-1 px-[10px] items-center align-middle"
            onClick={() => showPasswordUpdateDialog(true)}
          >
            <div className="button-text-medium text-red-600">Reset password</div>
          </button>
        </div>
        <div className="body-small">*************</div>
      </div>
      <div className="my-4 border-b border-medium border-cool-grey-300" />
      <div className="flex flex-col gap-4">
        <div className="flex gap-2 items-center">
          <Icons name="user-outlined" height={24} width={24} fill="#292D38" />
          <div className="heading-7">Data Management</div>
        </div>
        <div className="body-small">
          Data and security is managed by your organization admin. Please contact them for further information.
        </div>
      </div>
    </div>
  );
};
