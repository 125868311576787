import Icons from "components/icons/Icons";
import type { IAuthor } from "types/interfaces";

interface TrainerInfoProps {
  authors: IAuthor[];  // Expect outcomes to be an array
}
const TrainerInfo = ({ authors }: TrainerInfoProps) => {
  const author = authors[0]
  const trainerCredentials = [
    "Naveen has received numerous awards throughout his career, including Ernst and Young’s Entrepreneur of the Year, Silicon India’s Most Admired Serial Entrepreneur, and the Albert Einstein Technology Medal for his pioneering work in technology.",
    "Naveen is vice chairman of the board at Singularity University, where he educates leaders to address humanity’s greatest challenges through technology.",
    "Naveen’s company, Moon Express, is the only one in the world with permission to harvest resources from the moon.",
    "Viome, Naveen’s company with a goal to make illness optional, has developed the world’s largest database of microbial gene expression - allowing them to predict chronic disease through a person’s gut.",
  ];
  const getTrainerCredentials = () => {
    return (
      <div className="flex flex-col gap-y-4 ">
        <h6 className="title-8 text-cool-grey-600 ">Trainer credentials</h6>
        {trainerCredentials.map((credentials) => (
          <div key={credentials} className="flex gap-y-4 gap-x-3">
            <div className="flex py-1">
              <Icons name={"star-filled"} fill="#E8AD11" />
            </div>
            <p className="body-small">{credentials}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="flex-1 space-y-5">
      <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
        <img
          src={author.bannerAsset?.url || author.avatarAsset?.url}
          className="w-full h-56 md:h-40 md:w-72 rounded-2xl object-cover"
          alt="Trainer"
        />
        <div className="flex flex-col gap-y-1">
          <h4 className="heading-5 text-cool-grey-700">{author.name}</h4>
          <h6 className="title-8 text-cool-grey-600">{author.headline}</h6>
        </div>
      </div>
      <p className="body-small text-cool-grey-600">
        {author.description}
      </p>
      {/*{getTrainerCredentials()}*/}
    </div>
  );
};

export default TrainerInfo;
