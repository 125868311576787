import { HeroCarousel } from "tabs/Home/components/HeroCarousel";
import { HeroHeading } from "../components/HeroHeading";

export function HeroSection() {
  return (
    <section className="flex flex-col gap-8">
      <div className="px-6 ">
        <HeroHeading />
      </div>
      <HeroCarousel />
    </section>
  );
}
