const dateFormater = (availableDays?: number) => {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + (availableDays ?? 0));
  const formattedDueDate = currentDate.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  return formattedDueDate;
};

export default dateFormater;
