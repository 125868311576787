const WorkwaySmallLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="16" viewBox="0 0 37 16" fill="none">
      <title>Workway Small Logo</title>
      <g clipPath="url(#clip0_3114_981)">
        <path
          d="M0 0C1.24714 0.102681 2.49013 0.262731 3.72729 0.44232C5.5107 0.700894 7.2858 1.02432 9.03596 1.455C10.693 1.86198 12.4377 2.30015 13.9414 3.13282C16.5604 4.58491 17.0892 7.61962 17.0892 10.3833V16C5.15485 9.89067 0 0 0 0Z"
          fill="url(#paint0_linear_3114_981)"
        />
        <path
          d="M36.3085 0C35.0613 0.102681 33.8187 0.262731 32.5816 0.44232C30.7982 0.700894 29.0231 1.02432 27.2725 1.455C25.6159 1.86198 23.8707 2.30015 22.3671 3.13282C19.7481 4.58491 19.2193 7.61962 19.2193 10.3833V16C31.1536 9.89067 36.3085 0 36.3085 0Z"
          fill="url(#paint1_linear_3114_981)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_3114_981"
          x1="0.373032"
          y1="8.46667"
          x2="36.3085"
          y2="8.46667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6D5C" />
          <stop offset="1" stopColor="#D03C2F" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3114_981"
          x1="0.373032"
          y1="8.46667"
          x2="36.3085"
          y2="8.46667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6D5C" />
          <stop offset="1" stopColor="#D03C2F" />
        </linearGradient>
        <clipPath id="clip0_3114_981">
          <rect width="36.3085" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WorkwaySmallLogo;
