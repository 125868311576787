import { ChevronDownIcon, Cross1Icon } from "@radix-ui/react-icons";
import { type ChangeEvent, useEffect, useRef, useState } from "react";
import type { IOption } from "types/interfaces";
import { Input } from "./Input";

export const TagInput = ({
  placeholderText = "",
  initialValues = [],
  onChange,
  options,
}: // onSearch
{
  initialValues?: string[];
  options?: IOption[];
  placeholderText: string;
  // onSearch: (query: string) => void;
  onChange?: (option: IOption[]) => void;
}) => {
  const [tags, setTags] = useState<IOption[]>([]);
  const [tagsSelected, setTagsSelected] = useState<IOption[]>([]);
  const initialized = useRef(false);

  const [searchResults, setSearchResults] = useState<IOption[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (initialized.current) return;
    const initialTags = tags.filter((tag) => initialValues.includes(tag.value));
    if (initialTags.length === initialValues.length) {
      setTagsSelected(initialTags);
      initialized.current = true;
    }
  }, [tags, initialValues]);

  useEffect(() => {
    if (!options) return;
    setSearchResults(options);
    setTags(options);
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        event.target &&
        dropdownRef.current &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setDropdownOpen(true);
  };

  useEffect(() => {
    if (searchQuery) {
      setSearchResults(
        tags.filter((tag) =>
          tag.name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    } else {
      setSearchResults(tags);
    }
  }, [searchQuery]);

  const handleSelect = (tag: IOption) => {
    setDropdownOpen(false);
    setSearchQuery("");
    const duplicateTag = tagsSelected.find((item) => tag.value === item.value);
    if (duplicateTag) return;
    const updatedSelection = [...tagsSelected, tag];
    setTagsSelected(updatedSelection);
    onChange?.(updatedSelection);
  };

  const handleRemove = (tag: IOption) => {
    setTagsSelected(tagsSelected.filter((item) => item.value !== tag.value));
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="py-1 flex flex-col space-y-2">
      <button
        type="button"
        className="w-full flex flex-col border-0 relative gap-2"
        onClick={toggleDropdown}
        ref={dropdownRef}
      >
        <div className="w-full px-2 flex justify-between items-center">
          <ul className="w-full flex items-center flex-wrap w-full gap-2">
            {tagsSelected.map((tag: IOption) => (
              <li
                key={tag.value}
                className="flex items-center h-8 py-1 px-2 border-[1px] border-medium border-cool-grey-300 rounded-full bg-brown-90a"
              >
                <div className="button-text-small text-cool-grey-600 px-2">
                  {tag.name}
                </div>
                <button type="button" onClick={() => handleRemove(tag)}>
                  <div className="w-4 h-4 relative inline-block bg-white-90a rounded-full cursor-pointer">
                    <Cross1Icon />
                  </div>
                </button>
              </li>
            ))}
            <li className="grow">
              <Input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                onFocus={() => setSearchQuery("")}
                placeholder={placeholderText}
                className="py-0 px-1 w-full body placeholder:text-cool-grey-450 text-cool-grey-600 ring-0 cursor-default hover:outline-none focus:ring-0 border-0 inline-block focus-visible:ring-0"
                autoComplete="off"
              />
            </li>
          </ul>
          <div
            className={`transition-transform duration-700 ${
              dropdownOpen ? "rotate-180" : ""
            }`}
          >
            <ChevronDownIcon />
          </div>
        </div>
        {dropdownOpen && (
          <div
            className={`flex flex-col transition-transform duration-700 ${
              dropdownOpen ? "accordion-down" : "accordion-up"
            }`}
          >
            <div className="absolute w-full border border-medium border-cool-grey-300 bg-white shadow-light z-10 overflow-auto max-h-[235px]">
              <ul className="p-2">
                {searchResults.length === 0 ? (
                  <li
                    className="text-left px-4 py-3 body-small text-cool-grey-450"
                    data-testid="no-matching-text"
                  >
                    No matching results
                  </li>
                ) : (
                  <>
                    <li className="text-left px-2 py-1.5 body-2-xs text-cool-grey-450 rounded">
                      {searchResults.length} results
                    </li>
                    {searchResults.map((result: IOption) => (
                      <li
                        key={result.value}
                        className="px-2 py-1.5 rounded body-small text-cool-grey-600 hover:bg-red-100 hover:text-red-600 cursor-pointer"
                      >
                        <button
                          type="button"
                          className="text-left w-full"
                          onClick={() => handleSelect(result)}
                        >
                          {result.name}
                        </button>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            </div>
          </div>
        )}
      </button>
    </div>
  );
};
