import { useMutation } from "@apollo/client";

import gqlQuery from "services/graphql/queries";

function useQuestConsumption() {
  const [enrollUser, { data: enrollUserResponse, loading: enrollUserLoading, error: enrollUserError }] = useMutation(
    gqlQuery.enrollUser,
  );
  const [unEnrollUser, { data: unEnrollUserResponse, loading: unEnrollUserLoading, error: unEnrollUserError }] = useMutation(
    gqlQuery.unEnrollUser,
  );

  const [
    markPageAsCompleted,
    { data: markPageAsCompletedResponse, loading: markPageAsCompletedLoading, error: markPageAsCompletedError },
  ] = useMutation(gqlQuery.markPageAsCompleted);

  const [
    markTaskAsCompleted,
    { data: markTaskAsCompletedResponse, loading: markTaskAsCompletedLoading, error: markTaskAsCompletedError },
  ] = useMutation(gqlQuery.markTaskAsCompleted);

  return {
    enrollUser: {
      enrollUser,
      loading: enrollUserLoading,
      response: enrollUserResponse,
      error: enrollUserError,
    },
    unEnrollUser: {
      unEnrollUser,
      loading: unEnrollUserLoading,
      response: unEnrollUserResponse,
      error: unEnrollUserError,
    },
    markPageAsCompleted: {
      markPageAsCompleted,
      response: markPageAsCompletedResponse,
      loading: markPageAsCompletedLoading,
      error: markPageAsCompletedError,
    },
    markTaskAsCompleted: {
      markTaskAsCompleted,
      response: markTaskAsCompletedResponse,
      loading: markTaskAsCompletedLoading,
      error: markTaskAsCompletedError,
    },
  };
}

export default useQuestConsumption;
