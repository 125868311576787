import { Spinner } from "@fluentui/react-components";

export const CenteredOverlaySpinner = ({ show = false }) => {
  if (!show) return <></>;
  return (
    <div className="fixed inset-0 z-[70] bg-black-70a flex items-center justify-center">
      <div className="absolute z-[110] inset-y-1/2 translate-x-1/2 right-1/2">
        <Spinner />
      </div>
    </div>
  );
};
