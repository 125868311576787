import useCuratedMeditationSeries from "hooks/useCuratedMeditationSeries";
import { useEffect, useState } from "react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { FeaturedMeditationsSection } from "tabs/Meditations/sections/FeaturedMeditationsSection";
import { MoreCuratedSection } from "tabs/Meditations/sections/MoreCuratedSection";
import { SelectedMeditationsSection } from "tabs/Meditations/sections/SelectedMeditationsSection";

export default function MeditationsTab() {
  const location = useLocation();
  const { curatedData } = useCuratedMeditationSeries();
  const [selectedSeriesId, setSelectedSeriesId] = useState("");

  const handleSetSelectedSeriesId = (id: string) => {
    setSelectedSeriesId(id);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (location.state?.subPageId) {
      setSelectedSeriesId(location.state.subPageId);
    }
  }, [location]);

  // Temporary solution
  const getSeries = useCallback(() => {
    return selectedSeriesId ? curatedData.find((series) => series.id === selectedSeriesId) : curatedData[0];
  }, [curatedData, selectedSeriesId]);

  const series = getSeries();

  return (
    <div className="flex flex-col gap-10 pt-20 px-6">
      <FeaturedMeditationsSection series={series} />
      <div id="meditations">
        <MoreCuratedSection handleSelect={handleSetSelectedSeriesId} />
      </div>

      <div id="meditations_series">
        <SelectedMeditationsSection />
      </div>
    </div>
  );
}
