import { MediaPlayer } from "components/MediaPlayer";
import { CenteredOverlaySpinner } from "components/Spinner";
import Icons from "components/icons/Icons";
import { useToast } from "hooks/use-toast";
import useQuestBySlugAndLanguage from "hooks/useQuestBySlugAndLanguage";
import useQuestConsumption from "hooks/useQuestConsumption";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import type { ICaption, IMarker, IPage, IQuest, ITask } from "types/interfaces";
import { QuestLandingPage } from "../QuestLanding/QuestLandingPage";
import MediaPlayerControls from "../components/MediaPlayerControls";
import { QuestConsumptionSkeleton } from "./QuestConsumptionSkeleton";
import QuestDetails from "./QuestDetails";
import QuestSideBar from "./QuestSideBar";
import { getLessonsStatus } from "./utils";

const StickyFooter = ({
  page,
  onMarkPageAsCompleted,
}: {
  page?: IPage;
  onMarkPageAsCompleted: (page?: IPage) => void;
}) => {
  return (
    <div className="fixed w-full bottom-0 flex lg:hidden h-12 z-30">
      {page?.tasks && page?.tasks.length > 0 && (
        <div className="flex flex-1 bg-cool-grey-600 gap-x-1 items-center justify-center">
          <Icons name="edit-outlined" fill="white" />
          <p className="text-cool-grey-100 title-9">
            {page?.tasks?.filter((task) => task.completed).length}/{page?.tasks?.length} tasks completed
          </p>
        </div>
      )}

      {page?.completed && (
        <div className="title-9 flex flex-1 items-center justify-center gap-x-1 bg-green-50 text-green-600">
          <Icons name="checklist-circle-filled" className="text-green" />
          <p>Completed</p>
        </div>
      )}

      {!page?.completed && (
        <button
          type="button"
          className="title-9 flex flex-1 gap-x-1 items-center justify-center bg-red-600 text-white active:text-white/80"
          onClick={() => onMarkPageAsCompleted(page)}
        >
          Mark as complete
          <Icons name="chevron-right-filled" />
        </button>
      )}
    </div>
  );
};

export interface ICurrentMedia {
  posterUrl: string;
  mediaUrl: string;
  captions: ICaption[];
  preview?: boolean;
  markers?: IMarker[];
}

const QuestConsumption = () => {
  const { slug, lang } = useParams();
  const { toast } = useToast();
  const urlPath = useLocation().pathname;
  const [currentPage, setCurrentPage] = useState<IPage>();
  const [currentMedia, setCurrentMedia] = useState<ICurrentMedia>();
  const [questData, setQuestData] = useState<IQuest>();
  const { data, loading: questLoading, error: questDataError } = useQuestBySlugAndLanguage(slug ?? "", lang ?? "");

  useEffect(() => {
    if (!data) return;
    setQuestData(data);
  }, [data]);

  useEffect(() => {
    if (!questData) return;
    if (currentPage) {
      setCurrentPage(questData.pages.find((page) => currentPage.id === page.id));
    } else {
      const page =
        questData.pages.find((pg) => new URL(pg.url ?? "").pathname === urlPath) ||
        questData.pages.find((pg) => pg.position === 1);
      setCurrentPage(page);
    }
  }, [questData]);

  useEffect(() => {
    if (!currentPage) return;
    const mediaUrl =
      currentPage?.sections?.find((section) => section.type === "video")?.primaryAsset?.url ||
      currentPage?.sections?.find((section) => section.type === "video")?.media?.mediaAsset.url;
    const posterUrl = currentPage?.sections?.find((section) => section.type === "video")?.coverAsset?.url;
    setCurrentMedia({
      posterUrl,
      mediaUrl,
      captions: currentPage?.sections.find((section) => section.type === "video")?.primaryAsset?.captions,
      preview: currentPage.preview,
      markers: currentPage?.sections.find((section) => section.type === "video")?.primaryAsset?.markers,
    } as ICurrentMedia);
  }, [currentPage?.id]);

  const onLessonSelect = (pageSelected: IPage) => {
    setCurrentPage(pageSelected);
  };

  const {
    enrollUser,
    loading: enrollUserLoading,
    response: enrollUserResponse,
    error: enrollUserError,
  } = useQuestConsumption().enrollUser;
  const handleStartProgram = async () => {
    const releaseId = questData?.releases ? questData?.releases[0].id : "";
    if (!releaseId) return;
    try {
      const { data } = await enrollUser({
        variables: {
          input: {
            releaseId,
          },
        },
      });
      if (!data || !data.enrollUser.successful) throw Error();
    } catch (err) {
      toast({
        variant: "destructive",
        description: "Failed to enroll user. Please try again!",
      });
      return;
    }
    const group = questData?.groups?.find((grp) => grp.position === 1);
    const page = group && questData?.pages.find((page) => page.position === 1);
    setCurrentPage(page);
  };

  const {
    markTaskAsCompleted,
    loading: markTaskAsCompletedLoading,
    error: markTaskAsCompletedError,
  } = useQuestConsumption().markTaskAsCompleted;
  const handleMarkTaskAsCompleted = async (task: ITask) => {
    if (!task) return;
    try {
      const { data } = await markTaskAsCompleted({
        variables: {
          input: {
            taskID: task.id,
          },
        },
      });
      if (!data) throw Error();
      if (data.markTaskAsCompleted.successful) {
        setQuestData((prevState?: IQuest) => {
          if (!currentPage || !prevState) return;
          const updatedTasks = currentPage?.tasks?.map((t) => (task.id === t.id ? { ...t, completed: true } : t));
          const updatedPages = prevState?.pages?.map((p) =>
            p.id === currentPage?.id ? { ...p, tasks: updatedTasks } : p,
          );
          return {
            ...prevState,
            pages: updatedPages,
          };
        });
      } else {
        throw Error();
      }
    } catch (err) {
      toast({
        variant: "destructive",
        className: "bg-red-500",
        description: "Failed to mark the task as completed. Please try again!",
      });
      return;
    }
  };

  const {
    markPageAsCompleted,
    response: markPageAsCompletedResponse,
    loading: markPageAsCompletedLoading,
    error: markPageAsCompletedError,
  } = useQuestConsumption().markPageAsCompleted;
  const handleMarkLessonAsComplete = async (page?: IPage) => {
    if (!page) return;
    try {
      const { data } = await markPageAsCompleted({
        variables: {
          input: {
            pageId: page?.id,
          },
        },
      });
      if (!data) return;
      if (data.markPageAsCompleted.successful) {
        setQuestData((prevState?: IQuest) => {
          if (!currentPage || !prevState) return;
          const updatedPages = prevState?.pages?.map((p) => (p.id === currentPage?.id ? { ...p, completed: true } : p));
          return {
            ...prevState,
            pages: updatedPages,
          };
        });
      } else {
        throw new Error();
      }
    } catch (err) {
      toast({
        variant: "destructive",
        className: "bg-red-500",
        description: "Failed to mark lesson as completed. Please try again!",
      });
      return;
    }
    const nextPage = data.pages.find((page) => page.id === currentPage?.nextPage?.id);
    if (nextPage) {
      setCurrentPage(nextPage);
    }
  };

  useCallback(() => {
    if (!(markPageAsCompletedError || markTaskAsCompletedError || enrollUserError || questDataError)) return;
    toast({
      variant: "destructive",
      description: "Internal server occured. Please try again!",
    });
  }, [markPageAsCompletedError, markTaskAsCompletedError, enrollUserError, questDataError]);

  const videoPlayerRef = useRef<any>(null);
  if (questLoading) return <QuestConsumptionSkeleton />;

  if (!questData) return <div className="text-white">No Quest found</div>;

  const { total } = getLessonsStatus(questData.type, questData.userProgress);

  return (
    <section className="bg-white flex flex-col h-screen lg:overflow-auto mt-16 w-full">
      <CenteredOverlaySpinner
        show={markPageAsCompletedLoading || markTaskAsCompletedLoading || enrollUserLoading || questLoading}
      />
      <div className="flex-1 flex flex-col lg:flex-row overflow-y-auto w-full">
        <main className="lg:h-[calc(100dvh-20px)] flex flex-col lg:flex-1 lg:overflow-y-auto no-scrollbar sticky lg:static top-0 z-10 touch-auto">
          <div className="lg:hidden px-4 py-3 flex items-center justify-between bg-white">
            <div>
              <div className="title-8 text-cool-grey-700">{questData.name}</div>
              <div className="title-10 text-cool-grey-500 pt-0.5 capitalize">
                lesson {currentPage?.position} of {total}
              </div>
            </div>
            <QuestLandingPage
              questData={questData}
              trigger={
                <button type="button">
                  <Icons name="more-vertical-outlined" />
                </button>
              }
            />
          </div>
          <div className="flex flex-1 lg:flex-none lg:max-w-[1330px] w-full">
            <div className="md:relative bg-black w-full">
              <MediaPlayer
                thumbnailUrl={currentMedia?.posterUrl || questData.coverAsset?.url}
                showVControls={false}
                autoplayVisible={true}
                playerClasses="overflow-hidden object-cover aspect-video"
                videoClasses="object-contain lg:object-cover"
                mediaUrl={currentMedia?.mediaUrl}
                ref={videoPlayerRef}
                captions={currentMedia?.captions}
              />
              {currentMedia?.mediaUrl && (
                <MediaPlayerControls
                  markers={currentMedia.markers}
                  isPreview={currentMedia?.preview}
                  videoPlayerRef={videoPlayerRef}
                  hasCaptions={currentMedia?.captions?.length > 0}
                  key={currentMedia.mediaUrl}
                />
              )}
            </div>
          </div>

          <div className="hidden lg:block m-6 ml-10">
            <QuestDetails
              quest={questData}
              page={currentPage}
              onMarkLessonAsComplete={handleMarkLessonAsComplete}
              onStartProgram={handleStartProgram}
            />
          </div>
        </main>
        <div className="flex h-full overflow-y-auto">
          <QuestSideBar
            quest={questData}
            page={currentPage}
            onLessonSelect={onLessonSelect}
            onStartProgram={handleStartProgram}
            onMarkTaskAsCompleted={handleMarkTaskAsCompleted}
          />
        </div>
        <StickyFooter page={currentPage} onMarkPageAsCompleted={handleMarkLessonAsComplete} />
      </div>
    </section>
  );
};

export default QuestConsumption;
