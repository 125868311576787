import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import type { PublicClientApplication } from "@azure/msal-browser";
import { apiConfig } from "config/apiConfig";
import { authentication } from "@microsoft/teams-js";
import { authConnectionBuilder, authUrl } from "config/msalConfig";

export async function getToken(instance: PublicClientApplication) {
  const account = instance.getActiveAccount();
  if (!account) return null;
  try {
    const tokenRequest = authConnectionBuilder(account);
    const response = await instance.acquireTokenSilent(tokenRequest);
    return response.accessToken;
  } catch (silentError) {
    console.error(silentError);
    console.warn("Silent token acquisition failed; attempting popup...");
    try {
      authentication
        .authenticate({
          url: authUrl,
          width: 600,
          height: 535,
        })
        .then((result: string) => {
          const data = JSON.parse(result) as Record<string, string>;

          for (const [key, value] of Object.entries(data.sessionStorage)) {
            sessionStorage.setItem(key, value);
          }
          window.location.reload(); // TEMPORARY SOLUTION
        })
        .catch((error) => {
          console.error("Error during authentication:", error);
        });
    } catch (popupError) {
      console.error("Token acquisition failed via popup as well:", popupError);
      return null;
    }
  }
}

export function createApolloClient(instance: PublicClientApplication) {
  const httpLink = createHttpLink({
    uri: apiConfig.platformApiEndpoint,
  });

  const authLink = setContext(async (_, { headers }) => {
    const token = await getToken(instance);

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}
