import { cn } from "../libs/classMerger";

const ProgressBar = ({
  value,
  maxValue,
  progressClasses,
  containerClasses,
  progressColor = "bg-blue-600", // Default progress color
}: {
  value?: number;
  maxValue?: number;
  progressClasses?: string;
  containerClasses?: string;
  progressColor?: string; // New prop for progress color
}) => {
  const calculatePercentage = (): number => {
    const maximumValue = maxValue || 0;
    const currentValue = value || 0;
    if (maximumValue === 0) {
      return 0; // Avoid division by zero
    }
    return ((maximumValue - currentValue) / maximumValue) * 100;
  };

  return (
    <div
      className={cn(
        "w-full h-1 bg-cool-grey-300 rounded-full relative flex flex-col gap-2",
        containerClasses
      )}
    >
      <div
        className={cn(
          "absolute top-0 bottom-0 left-0 rounded-full",
          progressClasses,
          progressColor
        )} // Apply progress color
        style={{ right: `${calculatePercentage()}%` }}
      />
    </div>
  );
};

export default ProgressBar;
