import Icons from "components/icons/Icons";

export type QuestData = {
  id: number;
  title: string;
  author: string;
  image: string;
  endDate?: string;
};

export const QuestCard = ({ card }: { card: QuestData }) => {
  return (
    <div className="rounded-xl flex-col justify-start items-start inline-flex overflow-hidden">
      <button type="button">
        <div className="rounded-xl overflow-hidden mb-3 relative">
          <img
            src={card.image}
            alt={card.title}
            className="overflow-hidden transition ease-in-out duration-500 hover:scale-105"
          />
          {card.endDate && (
            <div className="absolute bottom-0 bg-black/40 overlay-blur w-full text-white py-[6px] px-4 flex justify-between">
              <div className="flex items-center gap-2">
                <Icons name="calendar-reminder-outlined" width={14} height={14} fill="white" />
                <div className="title-10">Complete by {card.endDate}</div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1 self-stretch pl-1">
          <div className="flex flex-row justify-between gap-2 overflow-hidden">
            <p
              className="title-8 text-cool-grey-700 overflow-hidden text-ellipsis line-clamp-2 text-ellipsis text-left"
              data-testid="quest-title"
            >
              {card.title}
            </p>
          </div>
          <div className="max-h-5 text-left overflow-hidden text-cool-grey-450 text-ellipsis caption-disclaimer">
            {card.author}
          </div>
        </div>
      </button>
    </div>
  );
};
