import { Card } from "components/Card";
import Quest from "components/Quest";
import useLibrary from "hooks/useLibrary";
import type { IQuestNode } from "types/interfaces";
import EmptyState from "../components/EmptyState";

const AssignedToMe = () => {
  const status = "available";
  const { data, loading } = useLibrary(status);
  const assignedTomeData = data?.quests.edges;

  if (loading) return <div>Loading</div>;
  const unEnrolled: IQuestNode[] = [];
  const inProgress: IQuestNode[] = [];

  assignedTomeData.forEach((quest: any) => {
    const { userProgress } = quest.node;

    // Check the 'started' property and separate the quests
    if (userProgress.started) {
      inProgress.push(quest.node);
    } else {
      unEnrolled.push(quest.node);
    }
  });

  return (
    <div className="flex flex-col gap-8 ">
      <div className="flex flex-col w-fit gap-1">
        <p className="heading-5 hidden lg:block">Assigned to me</p>
        <p className="body-small">
          These learning materials are assigned by your organization for completion by a specific deadline. Your
          progress is tracked to keep you on track.
        </p>
      </div>
      {assignedTomeData === 0 ? (
        <EmptyState section="completed" />
      ) : (
        <div className=" flex flex-col gap-y-12">
          <div className="items-stretch flex flex-col gap-y-6">
            <p className="heading-7">{`Unenrolled(${unEnrolled.length})`}</p>
            <div className={"flex flex-col md:flex gap-5"}>
              <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-x-5 gap-y-4 sm:gap-y-9 md:gap-x-6 md:gap-y-8">
                {unEnrolled.map((quest) => (
                  <div key={quest.id}>
                    <Quest
                      name={quest.name}
                      author={quest.authors[0].name}
                      coverUrl={quest.coverAsset?.url}
                      pages={quest.resources}
                      availableDays={quest.userProgress?.totalDays}
                      className={"text-left"}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className={`items-stretch ${inProgress.length > 0 ? "flex" : " hidden"}  flex-col gap-y-6`}>
            <p className="heading-7">{`In progress(${inProgress.length})`}</p>
            <div className={"flex flex-col md:flex gap-5"}>
              {inProgress?.map((quest) => (
                <Card
                  key={quest.id}
                  variant={`${"programCardInprogress"}`}
                  title={quest.name}
                  author={quest.authors[0].name}
                  imageUrl={quest.coverAsset?.url}
                  totalLessons={quest.userProgress?.totalLessons}
                  totalLessonsCompleted={quest.userProgress?.totalLessonsCompleted}
                  pages={quest.resources}
                  availableDays={quest.userProgress?.totalDays}
                  // duration={
                  //   quest.resources[
                  //     quest.userProgress?.totalLessonsCompleted ?? 0
                  //   ].mediaAsset?.duration
                  // }
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssignedToMe;
