export function EmptyStateInProgress() {
  return (
    <svg
      width="238"
      height="160"
      viewBox="0 0 238 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Isolation_Mode" clip-path="url(#clip0_9473_7343)">
        <path
          id="Vector"
          d="M233.189 73.5808C233.061 68.0723 232.439 62.5605 231.098 57.2089C229.768 51.8931 227.683 46.8257 225.094 41.9968C222.544 37.2398 219.601 32.6853 216.511 28.2583C213.715 24.2494 210.723 20.3615 207.43 16.7414C202.324 11.1316 195.349 10.5893 188.398 8.96548C181.051 7.25021 173.847 4.81942 166.355 3.76085C150.444 1.51628 134.529 4.56784 119.208 8.76945C104.739 12.7358 90.2532 16.5421 75.7184 20.221C54.6978 25.54 34.8211 37.6776 24.1731 56.954C21.2236 62.2992 18.274 67.6443 15.3244 72.9927C12.4502 78.1973 9.25489 83.3431 6.85263 88.7863C4.62407 93.8341 4.0276 98.9995 4.85676 104.446C5.62692 109.507 7.15087 114.555 9.06481 119.305C10.3888 122.589 11.9914 125.787 14.007 128.708C14.5084 129.437 15.0393 130.149 15.5965 130.842C15.6128 130.861 15.6292 130.884 15.6456 130.904C21.1252 137.703 27.1129 144.329 35.4143 147.609C40.9103 149.785 46.7635 150.863 52.5479 151.971C57.1132 152.843 61.7047 153.565 66.3191 154.114C66.8632 154.182 67.4105 154.245 67.9578 154.307C74.7844 155.061 81.6536 155.444 88.5261 155.404C89.388 155.401 90.2434 155.404 91.0955 155.414C94.1466 155.453 97.1224 155.591 100.069 155.77C100.219 155.78 100.37 155.787 100.524 155.8C106.436 156.162 112.237 156.679 118.284 156.897C120.106 156.963 121.951 157.002 123.826 157.002C126.716 157.002 129.623 156.992 132.54 156.976C133.884 156.969 135.231 156.963 136.578 156.95C144.132 156.888 151.732 156.757 159.26 156.505C161.498 156.43 163.737 156.345 165.978 156.247C166.522 156.224 167.07 156.202 167.617 156.172C170.799 156.028 173.985 155.855 177.164 155.656C181.588 155.378 186.868 155.326 191.581 154.898C192.597 154.806 193.583 154.699 194.53 154.565C203.323 153.336 213.709 151.706 219.365 145.302C220.221 144.332 220.968 143.254 221.581 142.051C221.633 141.953 221.683 141.855 221.732 141.754C221.751 141.715 221.771 141.676 221.791 141.633C225.999 133.001 232.632 121.478 233.209 105.965C233.733 91.8901 233.438 84.7807 233.186 73.5808H233.189Z"
          fill="#F9F8F4"
        />
        <g id="Group">
          <path
            id="Vector_2"
            d="M172.701 128.473C172.581 128.473 172.486 128.375 172.486 128.258V75.8565C172.486 75.7358 172.584 75.6415 172.701 75.6415C172.818 75.6415 172.916 75.7396 172.916 75.8565V128.258C172.916 128.379 172.818 128.473 172.701 128.473Z"
            fill="#DE9581"
          />
          <path
            id="Vector_3"
            d="M170.789 134.248C165.482 134.248 159.311 132.819 159.311 130.978C159.311 129.137 164.84 128.043 170.042 128.043C175.621 128.043 181.637 129.167 181.637 130.978C181.637 132.788 176.568 134.248 170.789 134.248ZM170.042 128.473C163.973 128.473 159.744 129.793 159.744 130.978C159.744 132.23 164.946 133.814 170.789 133.814C176.632 133.814 181.207 132.26 181.207 130.978C181.207 129.82 176.33 128.473 170.042 128.473Z"
            fill="#DE9581"
          />
          <path
            id="Vector_4"
            d="M181.426 73.3783C180.811 76.0112 179.216 78.0632 176.579 78.8101C175.795 79.0288 174.98 79.1533 174.169 79.2137C170.435 79.4777 166.893 79.9568 163.275 78.7912C160.921 78.033 158.092 74.4194 158.092 71.5564V69.1988C158.092 66.4792 158.353 64.6271 161.117 64.1933C163.882 63.7595 169.491 63.7972 171.196 63.7972C172.901 63.7972 176.749 63.9443 178.156 64.1933C178.891 64.3215 180.031 64.7327 180.744 65.2306C180.857 65.3098 180.955 65.3853 181.042 65.4682C181.136 65.555 181.223 65.6531 181.302 65.7662C182.532 67.4788 181.837 71.6544 181.43 73.3783H181.426Z"
            fill="#FFC663"
          />
          <path
            id="Vector_5"
            d="M170.762 74.4005C164.704 74.4005 160.385 72.9369 160.34 72.9218C160.227 72.8841 160.166 72.7596 160.208 72.6465C160.249 72.5333 160.37 72.473 160.483 72.5107C160.532 72.5296 165.549 74.2232 172.343 73.9328C181.052 73.5593 181.633 71.9562 181.637 71.9411C181.656 71.8242 181.765 71.7487 181.882 71.7676C181.999 71.7865 182.078 71.9034 182.063 72.0203C182.033 72.2202 181.501 73.9705 172.362 74.3628C171.818 74.3854 171.287 74.3967 170.766 74.3967L170.762 74.4005Z"
            fill="#DE9581"
          />
          <path
            id="Vector_6"
            d="M181.298 65.7624C181.317 66.9469 180.004 67.7843 178.77 68.0408C177.556 68.2973 176.285 68.2445 175.051 68.252C173.501 68.2595 173.252 68.2558 171.702 68.2331C168.744 68.1916 165.791 68.086 162.833 67.9314C162.558 67.9201 162.558 67.4863 162.833 67.4976C167.262 67.7315 170.404 67.8446 174.84 67.8182C176.281 67.8107 181.913 68.0294 180.736 65.223C180.849 65.3022 180.947 65.3777 181.034 65.4607C181.128 65.5474 181.215 65.6455 181.294 65.7587L181.298 65.7624Z"
            fill="#D68925"
          />
          <path
            id="Vector_7"
            d="M178.997 65.506C178.997 66.366 175.602 66.5056 169.548 66.5056C163.494 66.5056 158.375 66.366 158.375 65.506C158.375 64.6459 163.282 64.5328 169.333 64.5328C175.383 64.5328 178.993 64.6459 178.993 65.506H178.997Z"
            fill="#574E48"
          />
          <g id="Group_2">
            <path
              id="Vector_8"
              d="M193.089 37.6267C188.419 42.1985 179.562 51.8211 177.635 53.9259C177.386 54.1899 177.254 54.3408 177.254 54.3408C177.254 54.3408 177.261 54.0768 177.292 53.5939C177.514 49.7276 178.955 31.8366 186.627 23.8775C190.298 20.0677 196.974 18.5476 198.955 21.6181C198.996 21.6784 199.034 21.7425 199.072 21.8067C199.102 21.867 199.136 21.9274 199.166 21.9877C200.833 25.3637 199.094 31.7612 193.085 37.623L193.089 37.6267Z"
              fill="#94934B"
            />
            <path
              id="Vector_9"
              d="M199.17 21.9877C196.235 23.8398 193.802 26.3256 191.659 29.0453C187.544 34.2771 184.036 40.1201 181.075 46.0724C179.8 48.6299 178.646 51.2552 177.639 53.9258C176.209 57.7055 175.07 61.5982 174.3 65.5778C174.244 65.8494 173.814 65.8003 173.87 65.5212C174.621 61.6586 175.7 57.8639 177.073 54.1748C177.145 53.9786 177.22 53.7863 177.295 53.5939C179.743 47.1587 183.033 41.0631 186.763 35.2805C190.075 30.1467 193.715 24.9261 198.958 21.618C199 21.6784 199.038 21.7425 199.075 21.8066C199.106 21.867 199.139 21.9274 199.17 21.9877Z"
              fill="#4F4F13"
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_10"
              d="M173.365 47.0832C173.301 47.351 173.244 47.6037 173.188 47.83C173.188 47.83 173.082 47.5094 172.894 46.9361C171.792 43.6355 167.797 32.0062 164.105 25.4654C159.857 17.9439 150.917 3.43276 157.421 0.577299C157.556 0.520718 157.688 0.464136 157.835 0.418872H157.839C165.131 -1.99903 172.041 9.40395 174.165 14.8697C178.416 25.82 174.832 40.9422 173.365 47.0832Z"
              fill="#94934B"
            />
            <path
              id="Vector_11"
              d="M172.743 65.3363C172.72 65.6078 172.29 65.6116 172.309 65.3363C172.792 59.9497 173.011 54.5481 172.935 49.1465C172.928 48.411 172.912 47.6716 172.894 46.9361C172.682 38.5508 171.845 30.139 169.08 22.1837C166.372 14.4095 162.211 7.24256 157.424 0.577311C157.56 0.52073 157.692 0.464149 157.839 0.418884C160.457 4.07025 162.879 7.85364 164.984 11.8219C169.091 19.5584 171.698 27.8041 172.656 36.5138C173.041 40.0181 173.271 43.545 173.365 47.0832C173.524 53.1789 173.282 59.2896 172.743 65.3363Z"
              fill="#4F4F13"
            />
          </g>
          <g id="Group_4">
            <path
              id="Vector_12"
              d="M166.025 51.5644C165.84 51.2023 165.64 50.8326 165.417 50.463C160.084 41.2855 145.052 28.9923 130.824 24.4017C124.818 22.4629 116.614 18.8643 114.475 12.3047C114.181 11.3994 114.034 10.4262 114.219 9.49449C114.238 9.41151 114.257 9.32475 114.279 9.24931C114.321 9.10597 114.37 8.96263 114.434 8.83061C115.43 6.54096 118.059 4.94915 120.183 4.24C128.44 1.48638 138.032 4.53799 144.788 9.54353C158.48 19.6866 164.406 43.9184 165.727 50.1084C165.927 51.0476 166.021 51.572 166.021 51.572L166.025 51.5644Z"
              fill="#94934B"
            />
            <path
              id="Vector_13"
              d="M170.038 65.242C168.918 60.2062 167.333 55.2648 165.417 50.463C164.708 48.6712 163.95 46.9059 163.154 45.1594C158.533 35.0352 151.879 26.2199 142.728 19.7772C134.35 13.8814 124.483 10.1622 114.275 9.24554C114.317 9.1022 114.366 8.95887 114.43 8.82684C120.111 9.35116 125.69 10.7167 130.971 12.8705C140.638 16.801 149.284 23.2588 155.655 31.5423C159.303 36.2875 162.124 41.6024 164.478 47.0907C164.908 48.0903 165.323 49.0937 165.723 50.1009C167.669 54.9857 169.302 59.9912 170.445 65.1288C170.506 65.4004 170.091 65.5135 170.03 65.242H170.038Z"
              fill="#4F4F13"
            />
          </g>
          <path
            id="Vector_14"
            d="M159.65 130.774C159.65 130.774 159.639 130.774 159.631 130.774C159.51 130.763 159.424 130.661 159.431 130.54L163.792 75.4114C163.799 75.2907 163.912 75.204 164.026 75.2115C164.146 75.2228 164.233 75.3247 164.225 75.4454L159.865 130.574C159.857 130.687 159.763 130.774 159.65 130.774Z"
            fill="#DE9581"
          />
          <path
            id="Vector_15"
            d="M181.422 131.193C181.306 131.193 181.211 131.102 181.207 130.985L178.993 73.3519C178.99 73.2312 179.084 73.1331 179.201 73.1293C179.34 73.118 179.42 73.2199 179.427 73.3368L181.641 130.97C181.645 131.091 181.551 131.189 181.434 131.193H181.426H181.422Z"
            fill="#DE9581"
          />
        </g>
        <g id="Group_5">
          <path
            id="Vector_16"
            d="M73.1978 112.227C65.1784 109.217 62.9227 99.1794 60.7424 89.4738C60.3426 87.6972 59.9314 85.8564 59.4863 84.0949C57.5249 76.264 54.2394 72.2958 50.7615 68.0975C49.2829 66.3133 47.7552 64.4688 46.3482 62.3111C42.0028 55.6459 38.9776 46.1139 42.0066 39.8296C43.7002 36.314 45.7786 36.1179 48.4115 35.8651C50.3806 35.6765 52.8286 35.4427 55.8652 33.7679C56.6271 33.3454 57.3627 32.7947 58.0492 32.1232C60.2634 29.9694 61.644 27.0649 62.7039 24.5112C63.183 23.3569 63.6168 22.1574 64.0317 20.9956C64.6314 19.3246 65.2538 17.597 66.0309 15.9675C67.0682 13.791 68.3809 12.6782 69.935 12.6556C69.9425 12.6556 69.9538 12.6556 69.9614 12.6556C70.9648 12.6556 71.8248 13.4062 72.5566 14.1154L72.2548 14.4247C71.5871 13.7759 70.8026 13.0894 69.9614 13.0894C69.9538 13.0894 69.9501 13.0894 69.9425 13.0894C68.5657 13.1082 67.3813 14.138 66.4232 16.1523C65.6574 17.763 65.0388 19.4793 64.4428 21.139C64.0241 22.3045 63.5904 23.5116 63.1075 24.6734C62.0287 27.2686 60.6217 30.2183 58.3547 32.4288C57.638 33.1266 56.8723 33.7 56.0802 34.1413C52.9682 35.8614 50.4673 36.099 48.4606 36.2914C45.7711 36.5479 43.9907 36.7176 42.4026 40.0106C39.4453 46.144 42.4366 55.5026 46.7141 62.0622C48.1098 64.201 49.6299 66.0342 51.0973 67.8108C54.4544 71.862 57.9209 76.0528 59.9088 83.9817C60.3501 85.7508 60.7651 87.5916 61.1649 89.372C63.3225 98.9681 65.5518 108.889 73.3525 111.816L73.2016 112.219L73.1978 112.227Z"
            fill="#3C425C"
          />
          <path
            id="Vector_17"
            d="M70.6892 122.095C70.6477 121.963 66.4267 108.587 69.4519 97.1387C69.9499 95.2602 70.4251 93.6118 70.8853 92.0125C73.1938 84.0157 74.7178 78.731 72.8468 63.8275L73.2768 63.7747C75.1591 78.7687 73.6239 84.0836 71.304 92.1332C70.8438 93.7288 70.3686 95.3771 69.8744 97.2481C66.8794 108.576 71.0626 121.827 71.1079 121.959L70.6967 122.091L70.6892 122.095Z"
            fill="#AE9F95"
          />
          <g id="Group_6">
            <path
              id="Vector_18"
              d="M114.683 147.613C118.281 150.008 122.966 152.162 125.067 152.377C128.202 152.701 130.25 151.404 129.896 149.061C129.541 146.719 126.689 147.077 126.689 147.077C126.689 147.077 111.088 145.214 114.683 147.609V147.613Z"
              fill="#782B24"
            />
            <path
              id="Vector_19"
              d="M126.323 136.342C126.851 138.119 127.368 139.899 127.877 141.679C128.251 142.988 128.624 144.301 128.975 145.617C129.269 146.723 129.907 148.216 129.224 149.261C128.907 149.748 128.421 150.099 127.915 150.382C127.364 150.695 126.765 150.94 126.135 151.019C125.124 151.147 124.109 150.83 123.177 150.423C120.265 149.144 117.319 147.941 114.517 146.421C113.106 145.655 114.336 143.022 115.682 142.792C116.523 142.649 126.323 136.342 126.323 136.342Z"
              fill="#FFA68D"
            />
            <path
              id="Vector_20"
              d="M118.006 141.808C117.908 141.551 117.489 141.66 117.588 141.921C118.104 143.256 119.032 144.852 118.957 146.308C118.942 146.587 119.375 146.587 119.391 146.308C119.47 144.795 118.538 143.184 118.006 141.804V141.808Z"
              fill="#D66751"
            />
            <path
              id="Vector_21"
              d="M118.04 141.283C118.04 141.283 116.633 142.253 116.286 144.414C116.011 146.134 116.595 148.71 116.199 148.495C115.803 148.28 113.631 147.153 112.77 145.998C111.91 144.844 112.985 143.403 113.891 143.007C114.796 142.611 118.04 141.283 118.04 141.283Z"
              fill="#782B24"
            />
            <path
              id="Vector_22"
              d="M140.099 102.076L135.557 111.242L111.013 127.651C111.013 127.651 107.259 130.069 103.012 132.389C102.888 132.46 102.755 132.532 102.627 132.6C99.6284 134.222 96.441 135.757 94.1816 136.342C88.5876 137.787 89.3081 114.562 89.3081 114.562L95.7658 111.631L96.1619 111.454L128.847 96.6144L129.447 96.3428L132.517 94.9509L133.189 94.6453L135.192 93.7363L140.099 102.08V102.076Z"
              fill="#4E548F"
            />
            <path
              id="Vector_23"
              d="M103.008 132.389C102.884 132.46 102.752 132.532 102.623 132.6C100.447 126.063 97.203 116.074 95.7583 111.631L96.1544 111.454C97.5802 115.856 100.824 125.84 103.004 132.389H103.008Z"
              fill="#1F2547"
            />
            <path
              id="Vector_24"
              d="M131.37 97.5385C125.32 94.8188 109.066 92.7631 103.819 93.3213C98.9796 93.8381 92.2728 96.0334 94.6832 105.98C96.7541 114.539 99.783 122.989 102.661 131.299C104.106 135.474 104.887 142.011 109.73 143.614C115.049 145.376 120.322 142.23 124.102 138.684C124.882 137.952 125.652 137.179 126.327 136.342L120.205 108.983C120.205 108.983 137.425 100.262 131.374 97.5385H131.37Z"
              fill="#4E548F"
            />
            <path
              id="Vector_25"
              d="M124.886 115.543C124.886 115.543 124.871 115.543 124.864 115.543C121.733 115.233 115.962 113.389 111.673 111.895C108.844 110.911 106.603 110.058 105.521 109.639C102.797 108.583 102.793 108.53 102.786 108.361C102.782 108.255 102.861 108.153 102.967 108.134C103.046 108.119 103.121 108.149 103.171 108.206C104.057 108.677 119.247 114.558 124.905 115.113C125.022 115.124 125.113 115.23 125.101 115.35C125.09 115.463 124.996 115.546 124.886 115.546V115.543Z"
              fill="#1F2547"
            />
            <g id="Group_7">
              <path
                id="Vector_26"
                d="M136.836 52.6094C136.836 52.6094 141.619 56.6568 143.286 61.5039C144.954 66.351 136.987 63.4503 135.297 63.4428C133.611 63.4352 131.929 56.2796 131.929 56.2796L136.832 52.6056L136.836 52.6094Z"
                fill="#FFA68D"
              />
              <path
                id="Vector_27"
                d="M136.953 54.6425C136.953 54.6425 137.115 50.199 135.15 47.5284C133.185 44.8578 130.148 46.012 130.148 46.012L131.133 50.067C131.133 50.067 129.749 53.6505 130.545 54.4464C131.34 55.2423 134.675 57.4942 134.939 57.4829C135.203 57.4716 136.953 54.6463 136.953 54.6463V54.6425Z"
                fill="#FFA68D"
              />
              <g id="Group_8">
                <path
                  id="Vector_28"
                  d="M130.333 55.8194C129.002 56.4607 127.459 56.4871 126.074 55.9779C125.346 55.71 125.128 55.3668 125.086 54.9858L123.955 44.6088H132.389L131.257 54.9858C131.223 55.2989 130.831 55.5818 130.337 55.8232L130.333 55.8194Z"
                  fill="#FFE0D1"
                />
                <path
                  id="Vector_29"
                  d="M132.46 44.8238C132.46 45.4123 130.416 45.8875 127.892 45.8875C125.369 45.8875 123.758 45.4123 123.758 44.8238C123.758 44.7861 123.928 44.0166 124.003 43.6696C124.026 43.5639 124.037 43.4998 124.037 43.4998C124.037 43.4093 124.071 43.3225 124.139 43.2395C124.528 42.7756 125.965 42.4361 127.889 42.4361C129.812 42.4361 131.476 42.7303 132.057 43.1905C132.178 43.2886 132.253 43.3942 132.268 43.5036C132.268 43.5036 132.272 43.5262 132.276 43.5602C132.31 43.8016 132.453 44.7861 132.453 44.8276L132.46 44.8238Z"
                  fill="#914136"
                />
                <path
                  id="Vector_30"
                  d="M132.283 43.5564C131.483 44.0619 130.186 44.4655 128.092 44.4655C125.999 44.4655 124.799 44.1109 124.003 43.6696C124.026 43.564 124.037 43.4998 124.037 43.4998C124.037 43.4093 124.071 43.3226 124.139 43.2396C124.856 43.662 126.063 44.0317 128.089 44.0317C130.114 44.0317 131.329 43.6507 132.061 43.1868C132.181 43.2848 132.257 43.3905 132.272 43.4998C132.272 43.4998 132.276 43.5225 132.279 43.5564H132.283Z"
                  fill="#3D180F"
                />
              </g>
              <path
                id="Vector_31"
                d="M134.924 54.8802C134.92 54.0239 134.769 52.2548 133.551 52.4246C133.226 52.4698 132.883 52.5792 132.577 52.4623C132.366 52.3793 132.208 52.202 132.068 52.0247C131.461 51.2552 131.106 50.3122 130.469 49.5653C129.877 48.875 128.56 48.0942 127.73 48.8562C127.274 49.2749 127.436 50.1613 127.67 50.5989C128.587 52.3265 128.436 54.1522 129.911 55.9854C131.385 57.8186 134.943 57.4754 134.943 57.4754C134.943 57.4754 134.927 54.8839 134.927 54.8764L134.924 54.8802Z"
                fill="#FFA68D"
              />
            </g>
            <g id="Group_9">
              <path
                id="Vector_32"
                d="M100.885 116.893V148.809C100.885 149.39 100.477 149.891 99.9113 150.016L85.6868 153.131C85.4567 153.184 85.2153 153.165 84.9965 153.082C84.955 153.064 84.9098 153.048 84.8683 153.026L84.5665 152.871L64.435 142.656C64.02 142.449 63.7598 142.019 63.7598 141.559V110.341C63.7598 110.077 63.8428 109.832 63.9899 109.624C64.0766 109.5 64.1898 109.39 64.3218 109.304C64.4425 109.224 64.5821 109.164 64.733 109.13L78.9763 106.011C79.2441 105.95 79.5195 105.984 79.7684 106.101L100.179 115.777C100.451 115.905 100.662 116.127 100.779 116.395C100.839 116.531 100.873 116.667 100.885 116.814C100.888 116.84 100.888 116.867 100.888 116.893H100.885Z"
                fill="#D4C5BA"
              />
              <path
                id="Vector_33"
                d="M100.881 116.814L84.8195 119.783L84.5705 119.832L84.3895 119.741L63.9863 109.624C64.0731 109.5 64.1862 109.39 64.3183 109.304L84.6347 119.383L100.775 116.399C100.836 116.535 100.87 116.671 100.881 116.818V116.814Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_34"
                d="M84.9927 153.082C84.9512 153.063 84.9059 153.048 84.8644 153.026L84.5627 152.871L84.3892 119.737V119.601H84.8229V119.779L84.9965 153.079L84.9927 153.082Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_35"
                d="M87.1842 121.454C87.0824 121.454 86.9881 121.378 86.973 121.272C86.9541 121.156 87.0333 121.042 87.1503 121.024L98.972 119.005C99.0889 118.987 99.2021 119.066 99.2209 119.183C99.2398 119.3 99.1606 119.413 99.0436 119.432L87.2219 121.45C87.2219 121.45 87.1993 121.45 87.1842 121.45V121.454Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_36"
                d="M100.885 137.326V137.768L84.7289 140.574H84.6912C84.5856 140.574 84.495 140.502 84.4799 140.397C84.4573 140.28 84.5365 140.167 84.6535 140.144L100.885 137.323V137.326Z"
                fill="#AE9F95"
              />
            </g>
            <g id="Group_10">
              <g id="Group_11">
                <path
                  id="Vector_37"
                  d="M57.3135 141.66C57.2079 141.66 57.1136 141.581 57.0985 141.472L48.7245 81.1828C48.7094 81.0659 48.7886 80.9565 48.9093 80.9376C49.0225 80.9187 49.1357 81.0017 49.1545 81.1224L57.5285 141.411C57.5436 141.528 57.4644 141.638 57.3437 141.657C57.3324 141.657 57.3248 141.657 57.3135 141.657V141.66Z"
                  fill="#4F2E28"
                />
                <path
                  id="Vector_38"
                  d="M40.0825 144.938C40.0825 144.938 40.0636 144.938 40.0523 144.938C39.9354 144.923 39.8524 144.814 39.8675 144.693L48.7281 81.1262C48.7432 81.0093 48.8563 80.9263 48.9733 80.9414C49.0902 80.9565 49.1732 81.0659 49.1581 81.1866L40.2975 144.754C40.2824 144.863 40.1881 144.938 40.0825 144.938Z"
                  fill="#4F2E28"
                />
              </g>
              <g id="Group_12">
                <path
                  id="Vector_39"
                  d="M83.1824 154.47C83.0731 154.47 82.9825 154.391 82.9674 154.282L74.8386 87.1314C74.8235 87.0144 74.9103 86.905 75.0272 86.89C75.1442 86.8749 75.2535 86.9616 75.2686 87.0786L83.3975 154.229C83.4125 154.346 83.3258 154.455 83.2088 154.47C83.2013 154.47 83.19 154.47 83.1824 154.47Z"
                  fill="#4F2E28"
                />
                <path
                  id="Vector_40"
                  d="M66.7323 157.597C66.7323 157.597 66.7135 157.597 66.7059 157.597C66.589 157.582 66.5022 157.477 66.5173 157.356L74.8385 87.0786C74.8536 86.9616 74.963 86.8749 75.0799 86.89C75.1969 86.905 75.2836 87.0107 75.2685 87.1314L66.9473 157.409C66.936 157.518 66.8417 157.601 66.7323 157.601V157.597Z"
                  fill="#4F2E28"
                />
              </g>
              <path
                id="Vector_41"
                d="M67.2263 153.441C67.1923 153.441 67.1622 153.433 67.132 153.418L40.7237 140.446C40.6181 140.393 40.5728 140.265 40.6256 140.155C40.6785 140.05 40.8067 140.004 40.9161 140.057L67.3244 153.033C67.43 153.086 67.4753 153.214 67.4224 153.324C67.3847 153.399 67.3093 153.444 67.2263 153.444V153.441Z"
                fill="#4F2E28"
              />
              <path
                id="Vector_42"
                d="M82.6316 150.295C82.5976 150.295 82.5675 150.287 82.5335 150.272L56.593 137.202C56.4874 137.149 56.4421 137.017 56.4987 136.911C56.5515 136.806 56.6835 136.761 56.7891 136.817L82.7297 149.887C82.8353 149.94 82.8805 150.072 82.824 150.178C82.7862 150.253 82.7108 150.298 82.6316 150.298V150.295Z"
                fill="#4F2E28"
              />
            </g>
            <g id="Group_13">
              <g id="Group_14">
                <path
                  id="Vector_43"
                  d="M154.531 129.718C154.426 129.718 154.331 129.639 154.316 129.529L145.942 68.652C145.927 68.5351 146.01 68.4257 146.127 68.4068C146.24 68.388 146.353 68.4747 146.372 68.5917L154.746 129.469C154.761 129.586 154.678 129.695 154.561 129.714C154.55 129.714 154.543 129.714 154.531 129.714V129.718Z"
                  fill="#4F2E28"
                />
                <path
                  id="Vector_44"
                  d="M137.3 132.996C137.3 132.996 137.281 132.996 137.27 132.996C137.153 132.981 137.07 132.872 137.085 132.751L145.946 68.5954C145.961 68.4785 146.074 68.3955 146.191 68.4106C146.308 68.4257 146.391 68.5351 146.376 68.6558L137.515 132.811C137.5 132.921 137.406 132.996 137.3 132.996Z"
                  fill="#4F2E28"
                />
              </g>
              <g id="Group_15">
                <path
                  id="Vector_45"
                  d="M180.4 142.528C180.295 142.528 180.2 142.449 180.185 142.339L172.056 78.648C172.041 78.5311 172.124 78.4217 172.245 78.4066C172.358 78.3915 172.471 78.4745 172.486 78.5952L180.615 142.287C180.63 142.404 180.547 142.513 180.427 142.528C180.419 142.528 180.408 142.528 180.4 142.528Z"
                  fill="#4F2E28"
                />
                <path
                  id="Vector_46"
                  d="M163.95 145.655C163.95 145.655 163.931 145.655 163.924 145.655C163.807 145.64 163.72 145.531 163.735 145.414L172.06 78.5952C172.075 78.4783 172.185 78.3877 172.301 78.4066C172.418 78.4217 172.505 78.5311 172.49 78.648L164.165 145.467C164.15 145.576 164.06 145.655 163.95 145.655Z"
                  fill="#4F2E28"
                />
              </g>
              <path
                id="Vector_47"
                d="M164.444 141.498C164.41 141.498 164.38 141.491 164.35 141.476L137.941 128.5C137.836 128.447 137.791 128.319 137.843 128.209C137.896 128.104 138.024 128.058 138.134 128.111L164.542 141.087C164.648 141.14 164.693 141.268 164.64 141.378C164.602 141.453 164.527 141.498 164.444 141.498Z"
                fill="#4F2E28"
              />
              <path
                id="Vector_48"
                d="M179.849 138.352C179.815 138.352 179.785 138.345 179.751 138.33L153.811 125.26C153.705 125.207 153.66 125.075 153.716 124.969C153.769 124.863 153.901 124.818 154.007 124.875L179.947 137.945C180.053 137.998 180.098 138.13 180.042 138.235C180.004 138.311 179.929 138.356 179.849 138.356V138.352Z"
                fill="#4F2E28"
              />
            </g>
            <g id="Group_16">
              <path
                id="Vector_49"
                d="M180.472 82.7746V83.1933C180.472 83.7365 180.072 84.2005 179.532 84.2797L82.3562 96.3956L82.243 96.4069C82.1374 96.4258 82.028 96.4258 81.9224 96.4031C81.8885 96.3994 81.8545 96.3956 81.8243 96.3843L40.3617 80.4888C39.8789 80.3643 39.5356 79.9267 39.5356 79.425V78.6518C39.5356 78.6254 39.5356 78.599 39.5394 78.5726C39.547 78.4481 39.5734 78.3274 39.6262 78.2142C39.6337 78.1953 39.645 78.1765 39.6526 78.1576C39.7092 78.0407 39.7884 77.9388 39.8827 77.8521C40.0486 77.7012 40.2599 77.5918 40.4975 77.5654L143.864 64.6838C144.018 64.6612 144.18 64.68 144.331 64.7253L179.713 81.7298C179.879 81.7826 180.026 81.8693 180.143 81.9901C180.26 82.0994 180.351 82.2428 180.407 82.3974C180.456 82.5144 180.475 82.6426 180.475 82.7746H180.472Z"
                fill="#6F635C"
              />
              <path
                id="Vector_50"
                d="M180.404 82.3975L82.3565 93.9287L82.1264 93.9551L82.0925 93.9475L81.9227 93.9098L39.6265 78.2142C39.6265 78.2142 39.6453 78.1765 39.6529 78.1576C39.7095 78.0407 39.7887 77.9389 39.883 77.8521L82.1528 93.5175L180.14 81.9901C180.257 82.0995 180.347 82.2428 180.404 82.3975Z"
                fill="#3F3835"
              />
              <path
                id="Vector_51"
                d="M82.3562 93.7325V96.3918L82.243 96.4031C82.1374 96.422 82.028 96.422 81.9224 96.3993V93.7325H82.3562Z"
                fill="#3F3835"
              />
            </g>
            <g id="Group_17">
              <path
                id="Vector_52"
                d="M137.3 81.3714L96.4034 86.3053C95.5886 86.3996 94.7625 86.2487 94.0383 85.864L86.7469 82.0089C86.2263 81.7373 86.1509 81.1263 86.4187 80.7189C86.5017 80.5906 86.6186 80.4812 86.7695 80.4171C86.8525 80.3681 86.9393 80.3454 87.0373 80.3303L132.717 73.8348L137.3 81.3714Z"
                fill="white"
              />
              <path
                id="Vector_53"
                d="M131.371 81.1678L96.1357 85.3208C96.0451 85.3284 95.9584 85.3321 95.8679 85.3321C95.4718 85.3321 95.0719 85.2265 94.7211 85.0304L86.415 80.7227C86.498 80.5944 86.615 80.485 86.7658 80.4209L94.9286 84.6569C95.2832 84.8569 95.6906 84.9398 96.0829 84.8908L131.318 80.7415L131.371 81.1715V81.1678Z"
                fill="#D4BFBF"
              />
            </g>
            <path
              id="Vector_54"
              d="M114.185 84.4381C114.125 85.4679 113.348 85.4151 111.069 85.7735C108.795 86.1318 105.26 85.7433 103.857 84.4381C102.461 83.133 102.273 80.2398 102.273 80.2398C102.273 80.2398 101.677 80.4209 101.134 80.4209C100.59 80.4209 100.138 79.923 100.217 79.161C100.296 78.4028 101.186 77.4409 102.205 77.2938C102.929 77.1958 103.257 77.2109 103.389 77.2297H103.393C103.446 77.2373 103.469 77.2486 103.469 77.2486C103.469 77.2486 103.503 77.0901 103.638 76.8714C103.812 76.5847 104.159 76.2037 104.815 75.9812C105.819 75.6341 106.928 76.2414 107.199 76.3999C107.21 76.4074 107.222 76.4112 107.226 76.4187C107.252 76.4376 107.263 76.4451 107.263 76.4451C107.263 76.4451 107.384 76.3093 107.61 76.1698C108.059 75.8793 108.931 75.5474 110.017 76.1999C111.194 76.9091 111.884 78.0633 112.31 79.2025C112.363 79.3421 112.408 79.4779 112.454 79.6137C112.891 80.9829 112.978 82.239 113.102 82.5521C113.102 82.5521 114.242 83.4159 114.185 84.4457V84.4381Z"
              fill="#FFA68D"
            />
            <path
              id="Vector_55"
              d="M111.228 79.5156C111.228 79.5156 119.47 80.1078 122.419 79.6966C125.369 79.2855 128.53 86.9956 128.53 86.9956L126.531 93.0498C126.531 93.0498 121.88 95.7543 115.988 93.212C110.096 90.6696 104.31 84.3438 104.31 84.3438L111.231 79.5156H111.228Z"
              fill="#FFA68D"
            />
            <g id="Group_18">
              <g id="Group_19">
                <path
                  id="Vector_56"
                  d="M153.369 37.8191C152.829 35.5747 153.414 34.9863 151.751 33.3416C150.608 32.2176 151.068 33.5906 149.548 33.0889C145.051 31.6027 140.536 33.1908 140.148 37.5437C140.14 37.6569 140.129 37.7776 140.114 37.8945C140.076 38.2755 140.031 38.664 139.986 39.0639C139.963 39.2902 139.937 39.5203 139.914 39.7542C139.865 40.1766 139.82 40.6066 139.774 41.0367C139.661 42.2362 139.586 43.4546 139.642 44.639C139.646 44.7899 139.657 44.9408 139.672 45.0916C139.755 46.1667 139.963 47.1965 140.363 48.1395C140.849 49.2749 142.204 49.8709 143.362 50.0368C144.614 50.2179 145.923 50.0142 147.141 49.7124C147.903 49.5238 148.714 49.3616 149.344 48.8562C152.32 46.476 154.278 41.5761 153.369 37.8153V37.8191Z"
                  fill="#FFA68D"
                />
                <path
                  id="Vector_57"
                  d="M140.337 40.8028C140.337 40.8028 139.024 42.0325 138.967 42.7039C138.877 43.8016 140.635 44.1675 140.616 44.0468C140.597 43.9261 140.333 40.8028 140.333 40.8028H140.337Z"
                  fill="#FFA68D"
                />
                <path
                  id="Vector_58"
                  d="M145.006 48.7996C145.006 48.7996 145.859 52.5301 145.964 54.6274C146.07 56.7247 144.689 57.9996 144.689 57.9996L154.123 57.7922L156.221 55.7968C156.221 55.7968 153.716 50.5309 153.305 48.23C152.897 45.929 153.105 42.9792 153.105 42.9792L147.579 45.416L145.006 48.792V48.7996Z"
                  fill="#FFA68D"
                />
                <path
                  id="Vector_59"
                  d="M140.544 40.5161C140.533 40.6821 140.495 40.8519 140.397 40.9877C140.299 41.1197 140.121 41.2102 139.963 41.1612C139.888 41.1423 139.827 41.097 139.774 41.0367C139.82 40.6029 139.865 40.1729 139.914 39.7542C140.023 39.7051 140.144 39.7165 140.25 39.7693C140.359 39.8259 140.45 39.9239 140.499 40.0522C140.551 40.1993 140.555 40.3615 140.544 40.5199V40.5161Z"
                  fill="#2B1B18"
                />
                <path
                  id="Vector_60"
                  d="M140.691 44.1524C140.488 44.5522 140.114 44.8691 139.673 45.0917C139.661 44.9408 139.65 44.7899 139.643 44.639C139.937 44.4617 140.182 44.2467 140.314 43.9978C140.431 43.7639 140.812 43.9185 140.691 44.1524Z"
                  fill="#D66751"
                />
              </g>
              <path
                id="Vector_61"
                d="M141.087 39.0827C141.004 39.1695 140.876 39.1921 140.755 39.1846C140.642 39.1733 140.525 39.1355 140.412 39.1091C140.273 39.0752 140.129 39.0714 139.986 39.0601C140.031 38.6602 140.076 38.2717 140.114 37.8907C140.552 37.9285 141.004 38.1963 141.14 38.6301C141.189 38.781 141.197 38.9658 141.087 39.079V39.0827Z"
                fill="#4F2E28"
              />
              <path
                id="Vector_62"
                d="M154.021 42.8661C153.184 44.2203 153.146 46.2157 153.18 46.9324C153.195 47.3398 150.381 47.2568 149.038 46.9173C148.903 46.8834 148.782 46.8494 148.68 46.808C148.669 46.8042 148.65 46.7966 148.639 46.7929C147.978 46.5364 147.545 46.2799 147.145 46.0158C147.035 45.9404 146.922 45.8649 146.813 45.7857C146.666 45.6839 146.515 45.582 146.349 45.4726C145.7 45.0351 145.044 44.5975 144.391 44.1562C143.682 43.6809 143.41 43.2773 143.233 42.4588C143.082 41.7496 143.029 41.0216 142.992 40.3049C142.935 39.1506 143.173 37.9323 142.671 36.8949C141.981 35.4578 140.578 35.7935 140.578 35.7935C140.578 35.7935 140.966 33.8433 143.184 32.7344C144.086 32.2855 145.157 32.0969 146.183 32.0139C146.364 32.0026 146.541 31.9875 146.719 31.9799C146.798 31.9762 146.869 31.9762 146.945 31.9724C148.623 31.912 150.404 32.1572 151.864 33.0361C152.237 33.2662 152.584 33.5114 152.893 33.7792C153.033 33.8924 153.165 34.0169 153.29 34.1451C154.116 34.975 154.663 35.9746 154.885 37.1213C155.274 39.1657 155.115 41.097 154.021 42.8699V42.8661Z"
                fill="#4F2E28"
              />
              <path
                id="Vector_63"
                d="M141.823 39.8333C141.823 39.8333 143.184 37.3966 145.267 39.1846C147.345 40.9763 144.448 46.0535 142.404 44.8615C140.359 43.6695 141.823 39.8333 141.823 39.8333Z"
                fill="#FFA68D"
              />
              <path
                id="Vector_64"
                d="M143.825 42.4135C143.493 41.9193 143.486 41.2818 143.822 40.7877C143.969 40.5727 143.656 40.3086 143.509 40.5274C143.067 41.18 143.056 41.9985 143.494 42.6549C143.641 42.8737 143.973 42.636 143.825 42.4172V42.4135Z"
                fill="#D66751"
              />
              <path
                id="Vector_65"
                d="M153.709 27.3855C153.128 27.1403 152.49 27.0234 151.86 27.0574C151.445 27.08 151.015 27.1592 150.623 27.0272C150.178 26.8725 149.869 26.4727 149.473 26.2162C149.054 25.9484 148.518 25.8503 148.051 26.0163C147.507 26.2086 147.134 26.7066 146.84 27.2045C146.195 28.3021 145.731 29.728 146.214 30.9765C146.647 32.1006 147.639 32.9795 148.737 33.4284C149.838 33.881 151.068 33.9565 152.249 33.8282C153.286 33.7151 154.365 33.4133 155.089 32.6589C156.047 31.6593 156.142 29.9883 155.41 28.8114C155.01 28.1701 154.403 27.6835 153.705 27.3855H153.709Z"
                fill="#4F2E28"
              />
              <path
                id="Vector_66"
                d="M153.29 34.1375C152.456 34.2695 151.611 34.3337 150.774 34.2809C149.054 34.1752 147.247 33.3718 146.18 32.0063C146.361 31.995 146.538 31.9799 146.715 31.9724C147.153 32.4967 147.692 32.9342 148.254 33.2209C149.669 33.9451 151.298 33.9791 152.845 33.7829C152.86 33.7792 152.879 33.7792 152.894 33.7754C153.034 33.8886 153.166 34.013 153.29 34.1413V34.1375Z"
                fill="#2B1B18"
              />
              <path
                id="Vector_67"
                d="M147.145 46.0082C147.036 45.9328 146.922 45.8574 146.813 45.7782C148.575 42.1306 152.075 38.449 150.28 34.3525C150.174 34.1111 150.525 33.9037 150.63 34.1451C152.539 38.5018 148.91 42.1985 147.141 46.0082H147.145Z"
                fill="#2B1B18"
              />
              <path
                id="Vector_68"
                d="M150.148 43.3829C149.627 44.5447 149.189 45.6725 149.039 46.9136C148.903 46.8796 148.782 46.8457 148.68 46.8042C148.669 46.8004 148.65 46.7929 148.639 46.7891C148.797 45.5217 149.272 44.3561 149.797 43.1792C149.902 42.9416 150.253 43.149 150.148 43.3867V43.3829Z"
                fill="#2B1B18"
              />
            </g>
            <path
              id="Vector_69"
              d="M133.611 93.7325L132.63 95.0377L115.682 110.465C115.682 110.465 115.55 112.189 122.363 114.999C129.175 117.81 131.382 117.991 131.382 117.991L138.032 95.4413L133.607 93.7325H133.611Z"
              fill="#4E548F"
            />
            <path
              id="Vector_70"
              d="M133.894 93.3968L133.185 94.6416L132.129 96.4974C131.876 96.9388 131.412 97.1953 130.922 97.1953C130.778 97.1953 130.631 97.1726 130.488 97.1274C130.02 96.9765 129.42 96.7917 128.839 96.6181L129.439 96.3465C129.862 96.4748 130.277 96.6068 130.62 96.72C131.054 96.8633 131.525 96.6823 131.748 96.2862L132.51 94.9547L133.513 93.1893C133.573 93.0799 133.709 93.046 133.811 93.1063C133.917 93.1629 133.951 93.2949 133.89 93.4006L133.894 93.3968Z"
              fill="#1F2547"
            />
            <path
              id="Vector_71"
              d="M170.702 70.6663C170.487 78.3424 167.997 87.9688 166.881 90.2396C165.76 92.5104 146.044 94.023 139.989 95.0754C133.935 96.1315 132.962 94.6718 132.698 93.7363C132.438 92.797 133.177 92.186 132.698 88.942C132.223 85.698 130.043 83.4272 130.318 78.6216C130.601 73.816 130.284 65.9776 131.155 62.1904C132.377 56.902 142.532 55.3366 143.837 54.899C143.92 54.8764 143.965 54.85 143.977 54.8349C144.026 54.7406 144.094 54.5709 144.184 54.3596C144.414 53.8466 144.78 53.0884 145.285 52.6471C145.995 52.0247 150.857 50.9157 153.18 50.9157C155.504 50.9157 157.009 52.979 157.009 52.979C157.009 52.979 157.115 53.1035 157.345 53.3261C157.499 53.4656 157.718 53.6467 157.997 53.8542C158.835 54.4803 160.272 55.3517 162.513 56.1476C165.56 57.2302 168.706 60.4741 170.038 65.2081C170.211 65.8041 170.351 66.4265 170.453 67.0715C170.641 68.2031 170.728 69.4064 170.694 70.6701L170.702 70.6663Z"
              fill="#E85546"
            />
            <g id="Group_20">
              <path
                id="Vector_72"
                d="M103.287 76.1094L94.6491 77.0336L94.5586 77.0411C94.4417 77.0524 94.3285 77.0449 94.2153 77.0185C94.1173 76.9921 94.0267 76.9506 93.94 76.8978L89.8133 74.8269C89.3908 74.5516 89.1985 74.031 89.3494 73.5482L90.9789 68.2635L91.1185 67.8109L92.3406 63.8426L102.65 62.1867L101.345 66.5623L101.209 67.015L99.6396 72.2845C99.6132 72.3788 99.5905 72.4694 99.5792 72.5524C99.5604 72.7108 99.5754 72.8541 99.632 72.9861C99.6924 73.1257 99.8018 73.2464 99.9602 73.3596C100.975 73.7707 102.095 74.3667 103.121 74.7515H103.125C103.125 74.7515 103.133 74.7552 103.136 74.7552C103.351 74.8382 103.423 74.9665 103.449 75.1475C103.457 75.2268 103.461 75.306 103.461 75.404C103.461 75.6077 103.54 76.0792 103.283 76.1094H103.287Z"
                fill="#D4C5BA"
              />
              <path
                id="Vector_73"
                d="M101.345 66.5661L101.213 67.0187L90.9829 68.2635L91.1225 67.8109L101.345 66.5661Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_74"
                d="M94.6494 75.8944V77.0374L94.5588 77.0449C94.4419 77.0562 94.3287 77.0487 94.2156 77.0223V76.0227L90.9716 74.3102C90.568 74.1027 90.3756 73.635 90.5076 73.2012L92.107 68.0372C92.1409 67.9203 92.2616 67.8561 92.3786 67.8939C92.4917 67.9278 92.5559 68.0485 92.5219 68.1617L90.9225 73.3257C90.8471 73.5595 90.9527 73.816 91.1677 73.9254L94.4721 75.6681L94.5286 75.6983C94.6003 75.736 94.6456 75.8115 94.6456 75.8869L94.6494 75.8944Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_75"
                d="M103.453 75.1513L94.6531 76.0906L94.4569 76.1132H94.4343C94.3249 76.1132 94.2306 76.0302 94.2193 75.9246C94.208 75.8039 94.291 75.6983 94.4154 75.687L94.4796 75.6794H94.4833L103.125 74.759H103.129C103.129 74.759 103.136 74.7628 103.14 74.7628C103.355 74.8458 103.427 74.974 103.453 75.1551V75.1513Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_76"
                d="M99.6322 72.9862L90.9111 74.0197H90.8885C90.7791 74.0235 90.6848 73.9405 90.6735 73.8311C90.6546 73.7142 90.7414 73.6048 90.8583 73.5935L99.5793 72.5562C99.5605 72.7146 99.5756 72.8579 99.6322 72.9899V72.9862Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_77"
                d="M117.712 64.5707L72.9825 69.9459H72.9599C72.7336 69.976 72.5148 69.976 72.2998 69.9572C70.7683 69.8214 69.4971 68.6219 69.3274 67.0338L66.0231 35.7709C65.876 34.4092 66.5889 33.1493 67.7243 32.5382C67.9355 32.4213 68.1618 32.327 68.3995 32.2666C68.5805 32.2138 68.7654 32.1761 68.9577 32.1572L113.777 27.6232C115.577 27.4421 117.18 28.7435 117.372 30.5428L120.586 60.9646C120.775 62.7525 119.496 64.3556 117.716 64.5669L117.712 64.5707Z"
                fill="#D4C5BA"
              />
              <path
                id="Vector_78"
                d="M72.9638 69.9458C72.7375 69.976 72.5187 69.976 72.3037 69.9572C71.5644 69.2669 71.0589 68.3088 70.9382 67.2337L67.2453 35.0881C67.1435 34.1791 67.3283 33.3002 67.7282 32.542C67.9394 32.425 68.1657 32.3307 68.4034 32.2704C67.8451 33.0437 67.5584 34.0206 67.6716 35.0429L71.3644 67.1847C71.4927 68.3276 72.1 69.3084 72.9638 69.9496V69.9458Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_79"
                d="M118.772 57.8488C118.802 58.1468 118.591 58.4109 118.296 58.4448L73.0579 63.8012C72.7599 63.8351 72.4883 63.6201 72.4582 63.3183L71.9225 58.2109L71.881 57.7772L71.6509 55.6007L71.6019 55.1669L71.4887 54.0805L71.4397 53.6467L71.2134 51.4703L71.1643 51.0365L71.0248 49.72L70.9833 49.2862L70.7532 47.1097L70.7117 46.676L70.5721 45.3746L70.5306 44.9408L70.3005 42.7643L70.259 42.3305L70.1195 41.0028L70.0704 40.569L69.8403 38.3925L69.7988 37.9587L69.6744 36.778C69.6668 36.6988 69.663 36.6234 69.663 36.5479C69.6555 36.4008 69.663 36.2537 69.6819 36.1066C69.8403 34.76 70.9191 33.6585 72.3261 33.5152L72.6694 33.4812L73.1032 33.4398L76.0039 33.1455L76.4377 33.104L112.827 29.4187C114.204 29.2792 115.452 30.1015 115.901 31.3387C115.958 31.4783 115.995 31.6141 116.026 31.7612C116.048 31.8555 116.063 31.9573 116.075 32.0554L118.779 57.8337L118.772 57.8488Z"
                fill="white"
              />
              <path
                id="Vector_80"
                d="M116.022 31.7762L69.663 36.563C69.6555 36.4159 69.663 36.2688 69.6819 36.1216L115.897 31.35C115.954 31.4895 115.992 31.6253 116.022 31.7724V31.7762Z"
                fill="#B9BAC7"
              />
              <path
                id="Vector_81"
                d="M113.857 30.4711C113.857 30.1052 113.559 29.8073 113.193 29.8073C112.827 29.8073 112.529 30.1052 112.529 30.4711C112.529 30.837 112.827 31.135 113.193 31.135C113.559 31.135 113.857 30.837 113.857 30.4711Z"
                fill="#B9BAC7"
              />
              <path
                id="Vector_82"
                d="M111.276 30.6899C111.276 30.324 110.979 30.026 110.613 30.026C110.247 30.026 109.949 30.324 109.949 30.6899C109.949 31.0558 110.247 31.3538 110.613 31.3538C110.979 31.3538 111.276 31.0558 111.276 30.6899Z"
                fill="#B9BAC7"
              />
              <path
                id="Vector_83"
                d="M108.383 31.0143C108.383 30.6484 108.085 30.3504 107.72 30.3504C107.354 30.3504 107.056 30.6484 107.056 31.0143C107.056 31.3802 107.354 31.6782 107.72 31.6782C108.085 31.6782 108.383 31.3802 108.383 31.0143Z"
                fill="#B9BAC7"
              />
              <path
                id="Vector_84"
                d="M77.694 44.5975C77.7167 44.5598 77.6978 44.3825 77.6676 44.3523L77.2338 40.1729C77.2376 40.1502 77.2301 40.0559 77.215 40.0333L76.7699 35.7105L76.3361 35.752L76.7699 39.9277L73.7598 40.2257L73.3184 36.0651L72.8846 36.1066L73.3297 40.2634L70.0669 40.5803L70.1159 41.014L73.3712 40.6972L73.7937 44.6315L70.5233 44.9483L70.5648 45.3821L73.8352 45.0615L74.2539 48.9769L70.976 49.2937L71.0175 49.7275L74.2954 49.4069L74.7103 53.2884L71.4286 53.6505L71.4776 54.0843L74.7594 53.7222L75.1516 57.415L71.8662 57.7771L71.9077 58.2109L78.7992 57.4414H78.803C78.803 57.4414 78.8219 57.4376 78.8332 57.4339C78.852 57.4301 78.8671 57.4263 78.886 57.415C78.8935 57.4112 78.9049 57.4037 78.9124 57.3924C78.9237 57.3848 78.9388 57.3735 78.9464 57.3622C78.9539 57.3547 78.9577 57.3396 78.9652 57.332C78.9728 57.3132 78.9841 57.3018 78.9878 57.2905C78.9954 57.2717 78.9954 57.2528 78.9954 57.2302V57.2038L77.6865 44.6013L77.694 44.5975ZM74.2313 44.5937L73.8126 40.6595L76.8227 40.3615L77.2301 44.3033L74.2313 44.5975V44.5937ZM74.6952 48.9354L74.2803 45.0238L77.2791 44.7258L77.6865 48.6412L74.699 48.9354H74.6952ZM75.1554 53.2393L74.7405 49.3692L77.7242 49.0712L78.1203 52.9112L75.1516 53.2393H75.1554ZM75.5968 57.366L75.2007 53.6731L78.1731 53.3412L78.5578 57.034L75.6005 57.366H75.5968Z"
                fill="#B9BAC7"
              />
              <path
                id="Vector_85"
                d="M114.437 64.0199C114.332 64.0199 114.238 63.9407 114.222 63.8313C114.207 63.7144 114.29 63.605 114.411 63.5899L116.897 63.2655C117.014 63.2504 117.123 63.3334 117.138 63.4503C117.153 63.5673 117.07 63.6767 116.953 63.6917L114.468 64.0161C114.468 64.0161 114.449 64.0161 114.441 64.0161L114.437 64.0199Z"
                fill="#AE9F95"
              />
              <path
                id="Vector_86"
                d="M103.888 49.233C107.192 48.9735 109.634 45.7461 109.342 42.0243C109.049 38.3026 106.134 35.4959 102.83 35.7553C99.5253 36.0148 97.0836 39.2422 97.3759 42.964C97.6681 46.6857 100.584 49.4924 103.888 49.233Z"
                fill="#FFE0D1"
              />
              <path
                id="Vector_87"
                d="M111.873 52.6698C109.851 53.2205 107.674 53.8466 105.562 53.8768C104.249 53.8957 103.401 53.2695 103.386 51.8852C103.371 50.7422 103.661 49.5767 103.993 48.4903C104.412 47.106 104.861 45.7405 104.182 44.3599C103.57 43.1226 102.333 42.2664 101.017 41.9382C97.5653 41.0706 93.4198 42.5644 90.4361 44.1222C87.3393 45.7405 84.3782 47.9547 82.3224 50.8705C82.1602 51.0968 82.5374 51.3156 82.6958 51.0893C84.8384 48.049 88.105 45.7669 91.3527 44.1373C94.5326 42.5417 100.432 40.3803 103.31 43.8356C104.796 45.6198 103.703 47.5661 103.265 49.5012C103.001 50.6743 102.567 52.4812 103.386 53.526C104.117 54.4615 105.475 54.3521 106.52 54.2276C108.372 54.0088 110.19 53.5788 111.986 53.0885C112.254 53.0168 112.141 52.5981 111.873 52.6698Z"
                fill="#B9BAC7"
              />
            </g>
            <g id="Group_21">
              <path
                id="Vector_88"
                d="M117.24 153.991C117.24 153.991 117.221 153.991 117.214 153.991C117.097 153.976 117.01 153.871 117.025 153.75L121.653 115.792C121.669 115.675 121.778 115.588 121.895 115.603C122.012 115.618 122.099 115.724 122.083 115.844L117.455 153.803C117.444 153.912 117.35 153.991 117.24 153.991Z"
                fill="#914136"
              />
              <path
                id="Vector_89"
                d="M130.322 160.087C130.322 160.087 130.303 160.087 130.295 160.087C130.178 160.072 130.092 159.966 130.107 159.846L134.863 120.816C134.878 120.699 134.988 120.612 135.105 120.627C135.222 120.642 135.308 120.748 135.293 120.869L130.537 159.898C130.525 160.008 130.431 160.087 130.322 160.087Z"
                fill="#914136"
              />
              <path
                id="Vector_90"
                d="M162.516 155.983C162.407 155.983 162.316 155.904 162.301 155.794L157.722 118.515C157.707 118.398 157.79 118.289 157.911 118.274C158.024 118.255 158.137 118.342 158.152 118.462L162.731 155.745C162.746 155.862 162.663 155.972 162.543 155.987C162.535 155.987 162.524 155.987 162.516 155.987V155.983Z"
                fill="#914136"
              />
              <path
                id="Vector_91"
                d="M148.677 150.091C148.567 150.091 148.473 150.008 148.462 149.899L144.482 113.351C144.471 113.234 144.554 113.125 144.674 113.113C144.788 113.102 144.901 113.185 144.912 113.306L148.892 149.853C148.903 149.97 148.82 150.08 148.699 150.091C148.692 150.091 148.684 150.091 148.677 150.091Z"
                fill="#914136"
              />
              <path
                id="Vector_92"
                d="M172.841 83.1255L165.112 116.142C164.504 118.911 161.807 119.647 159.371 120.277C154.414 121.555 139.624 124.399 132.419 124.117C132.268 124.113 132.121 124.109 131.978 124.098C131.001 124.037 130.182 123.917 129.579 123.717C127.478 123.019 125.561 121.88 123.513 121.091C122.136 120.563 120.805 119.907 119.462 119.315C118.896 119.07 118.394 118.722 117.968 118.304C116.565 116.916 117.252 114.517 119.138 113.947L119.688 113.781L132.566 110.103L133.611 110.133L140.088 84.8795C140.378 83.9025 141.227 83.1934 142.238 83.084L170.11 80.0361C171.343 79.9003 172.403 80.6585 172.769 81.7185C172.822 81.8543 172.856 81.9938 172.878 82.1409C172.931 82.454 172.92 82.786 172.844 83.1217L172.841 83.1255Z"
                fill="#FFE0D1"
              />
            </g>
            <path
              id="Vector_93"
              d="M136.421 85.9055C135.512 88.4931 134.324 90.4056 132.917 91.7031C132.736 91.8691 132.551 92.0275 132.359 92.1747C130.069 93.9626 127.263 94.2644 124.166 93.344C118.828 91.756 115.679 85.3434 118.772 80.8019C119.221 80.1455 119.835 79.7042 120.514 79.2855C120.733 79.1459 120.96 79.0139 121.19 78.8706C122.046 78.35 122.936 77.7691 123.66 76.7657C124.905 75.0494 125.418 70.2589 126.965 65.8871C127.044 65.6683 127.119 65.4495 127.206 65.2307C127.478 64.514 127.787 63.8124 128.126 63.141C128.617 62.1942 129.307 61.3531 130.107 60.625C130.45 60.3082 130.812 60.014 131.189 59.7424C134.464 57.3207 138.658 56.3022 138.658 56.3022V64.6838C138.658 64.6838 138.869 78.9271 136.417 85.913L136.421 85.9055Z"
              fill="#E85546"
            />
            <path
              id="Vector_94"
              d="M112.454 79.6061C111.164 79.7721 109.915 80.1078 108.821 80.6283C108.568 80.7453 108.35 80.3718 108.602 80.2511C109.723 79.7193 110.998 79.3685 112.314 79.1949C112.367 79.3345 112.412 79.4703 112.457 79.6061H112.454Z"
              fill="#D66751"
            />
            <path
              id="Vector_95"
              d="M104.668 78.1425C104.291 77.7427 103.864 77.4409 103.4 77.2297C103.453 77.2372 103.476 77.2485 103.476 77.2485C103.476 77.2485 103.51 77.0901 103.646 76.8713C104.128 77.1014 104.581 77.4258 104.977 77.8408C105.173 78.0407 104.864 78.3462 104.672 78.1425H104.668Z"
              fill="#D66751"
            />
            <path
              id="Vector_96"
              d="M108.191 77.3806C107.916 76.9958 107.592 76.6752 107.229 76.4149C107.256 76.4338 107.267 76.4413 107.267 76.4413C107.267 76.4413 107.388 76.3055 107.614 76.166C107.958 76.43 108.267 76.7506 108.535 77.1203C108.701 77.3466 108.354 77.6031 108.188 77.3806H108.191Z"
              fill="#D66751"
            />
            <path
              id="Vector_97"
              d="M133.611 110.137L131.385 117.994C131.385 117.994 129.748 117.742 122.381 115.237C121.31 114.871 118.817 113.887 118.074 113.506L126.323 109.651L133.607 110.137H133.611Z"
              fill="#4E548F"
            />
            <path
              id="Vector_98"
              d="M104.306 113.019C104.189 113.019 104.095 112.925 104.091 112.808C104.091 112.687 104.181 112.589 104.302 112.585C106.935 112.514 110.364 111.058 110.398 111.043C110.507 110.997 110.635 111.046 110.681 111.156C110.726 111.265 110.677 111.393 110.567 111.439C110.424 111.499 107.025 112.944 104.313 113.015H104.306V113.019Z"
              fill="#1F2547"
            />
            <path
              id="Vector_99"
              d="M158.001 53.8504C155.248 53.4053 152.403 53.4996 149.597 53.9107C147.681 54.1936 145.753 54.5105 143.841 54.8952C143.924 54.8726 143.969 54.8462 143.98 54.8311C144.029 54.7368 144.097 54.5671 144.188 54.3558C146.304 53.9447 148.428 53.6127 150.529 53.3525C152.811 53.0696 155.104 53.0281 157.349 53.3223C157.503 53.4618 157.722 53.6429 158.001 53.8504Z"
              fill="#A83126"
            />
            <path
              id="Vector_100"
              d="M133.494 89.0438C133.306 89.9303 133.117 90.8167 132.917 91.6994C132.736 91.8654 132.551 92.0238 132.359 92.1709C132.581 91.2241 132.781 90.2773 132.985 89.3305C133.706 85.9922 134.403 82.3824 133.721 78.9724C133.113 75.9472 130.809 73.3709 129.285 70.7531C128.376 69.1877 127.557 67.5732 126.965 65.8795C127.044 65.6608 127.119 65.442 127.206 65.2232C127.915 67.4336 129.017 69.5007 130.235 71.4999C131.82 74.1027 133.856 76.5394 134.268 79.6476C134.679 82.786 134.143 85.9809 133.494 89.0476V89.0438Z"
              fill="#A83126"
            />
            <path
              id="Vector_101"
              d="M123.857 91.3523C121.439 90.3867 120.292 87.2823 119.847 84.9172C119.489 82.9896 119.741 81.0319 120.518 79.2817C120.737 79.1421 120.963 79.0101 121.194 78.8668C120.303 80.5793 119.926 82.5294 120.213 84.4758C120.556 86.7692 121.684 90.0208 124.019 90.9525C124.279 91.0581 124.117 91.4579 123.86 91.3561L123.857 91.3523Z"
              fill="#A83126"
            />
            <path
              id="Vector_102"
              d="M172.874 82.1409L144.912 85.068C143.516 85.2151 142.34 86.2261 141.981 87.584L132.423 124.113C132.272 124.109 132.125 124.105 131.981 124.094L141.566 87.4746C141.962 85.9394 143.29 84.8002 144.871 84.638L172.769 81.7147C172.822 81.8505 172.856 81.99 172.878 82.1371L172.874 82.1409Z"
              fill="#DE9581"
            />
            <path
              id="Vector_103"
              d="M149.299 66.0115C148.733 65.6607 147.688 66.5962 147.375 66.9998C147.115 67.3317 146.232 69.1159 147.145 69.1235C147.307 69.1235 147.458 69.0405 147.594 68.95C148.435 68.3917 149.08 67.543 149.39 66.5773C149.446 66.3963 149.484 66.17 149.344 66.0417C149.329 66.0304 149.318 66.0191 149.303 66.0077L149.299 66.0115Z"
              fill="white"
            />
            <path
              id="Vector_104"
              d="M124.475 80.6246C123.91 80.2738 122.865 81.2092 122.552 81.6128C122.291 81.9448 121.409 83.729 122.322 83.7365C122.484 83.7365 122.635 83.6535 122.77 83.563C123.612 83.0047 124.257 82.156 124.566 81.1904C124.623 81.0093 124.66 80.783 124.521 80.6547C124.506 80.6434 124.494 80.6321 124.479 80.6208L124.475 80.6246Z"
              fill="white"
            />
            <path
              id="Vector_105"
              d="M170.042 65.2043C169.808 65.4005 169.612 65.6042 169.506 65.74C169.246 66.0719 168.363 67.8561 169.276 67.8637C169.435 67.8637 169.589 67.7769 169.721 67.6901C169.993 67.5129 170.246 67.3016 170.457 67.0678C170.351 66.4227 170.215 65.8003 170.042 65.2043Z"
              fill="white"
            />
            <path
              id="Vector_106"
              d="M162.282 78.1954C162.697 77.6748 161.894 76.5281 161.532 76.1698C161.23 75.8718 159.567 74.7816 159.45 75.6869C159.431 75.8491 159.495 76.0076 159.567 76.1509C160.019 77.0524 160.789 77.7955 161.705 78.2142C161.879 78.2934 162.098 78.3538 162.241 78.2331C162.256 78.2218 162.267 78.2067 162.279 78.1954H162.282Z"
              fill="white"
            />
            <path
              id="Vector_107"
              d="M160.48 58.3542C160.895 57.8337 160.091 56.687 159.729 56.3286C159.427 56.0306 157.764 54.9405 157.647 55.8458C157.628 56.008 157.692 56.1664 157.764 56.3098C158.216 57.2113 158.986 57.9544 159.903 58.3731C160.076 58.4523 160.295 58.5127 160.438 58.392C160.453 58.3806 160.465 58.3656 160.476 58.3542H160.48Z"
              fill="white"
            />
            <path
              id="Vector_108"
              d="M138.658 86.7089C139.073 86.1884 138.269 85.0417 137.907 84.6833C137.609 84.3853 135.942 83.2952 135.825 84.2005C135.806 84.3627 135.87 84.5211 135.942 84.6645C136.395 85.566 137.164 86.3091 138.081 86.7278C138.254 86.807 138.473 86.8673 138.616 86.7466C138.632 86.7353 138.643 86.7202 138.654 86.7089H138.658Z"
              fill="white"
            />
            <path
              id="Vector_109"
              d="M131.797 60.1649C131.703 60.0706 131.469 59.8971 131.19 59.7311C130.812 60.0027 130.45 60.2969 130.107 60.6137C130.56 61.304 131.216 61.8623 131.97 62.2093C132.14 62.2923 132.359 62.3527 132.506 62.232C132.517 62.2206 132.532 62.2056 132.54 62.1942C132.955 61.6699 132.151 60.5232 131.793 60.1649H131.797Z"
              fill="white"
            />
          </g>
          <path
            id="Vector_110"
            d="M59.5958 83.5781L57.144 84.1891C57.144 84.1891 57.1251 84.1929 57.1176 84.1967C56.9516 84.2382 56.8271 84.3702 56.7932 84.5324C56.7856 84.5626 56.7819 84.5965 56.7819 84.6305L56.7592 86.6108C56.7592 86.8485 56.5216 87.0144 56.299 86.9314L52.625 85.5622C52.4892 85.5131 52.4062 85.3849 52.4062 85.2416V82.933C52.4062 82.7897 52.4704 82.6615 52.576 82.5785C52.6363 82.5294 52.7118 82.4955 52.791 82.4842L55.5522 82.0428C55.6502 82.0315 55.7521 82.0391 55.8426 82.0692L59.6109 83.1707C59.7316 83.2084 59.7241 83.5479 59.5996 83.5781H59.5958Z"
            fill="#B9BAC7"
          />
          <path
            id="Vector_111"
            d="M54.5033 83.7026C54.3222 83.7026 54.1525 83.5856 54.0921 83.4046L39.9884 40.4935C39.9129 40.2672 40.0374 40.022 40.2637 39.9465C40.4901 39.8749 40.7352 39.9956 40.8107 40.2219L54.9145 83.133C54.9899 83.3593 54.8654 83.6045 54.6391 83.6799C54.5938 83.695 54.5486 83.7026 54.5033 83.7026Z"
            fill="#B9BAC7"
          />
          <path
            id="Vector_112"
            d="M57.1176 84.1967C56.9516 84.2382 56.8271 84.3702 56.7932 84.5324C55.3485 83.9666 53.8774 83.4687 52.4062 82.9708V82.9331C52.4062 82.7897 52.4704 82.6615 52.576 82.5785C54.0999 83.0915 55.6163 83.6158 57.1176 84.1967Z"
            fill="#80859E"
          />
          <g id="Group_22">
            <path
              id="Vector_113"
              d="M39.5546 43.1754C39.415 43.1754 39.2755 43.1075 39.1925 42.9793C39.0605 42.7793 39.117 42.5115 39.317 42.3795L74.3029 19.3736C74.5028 19.2416 74.7707 19.2982 74.9027 19.4981C75.0347 19.698 74.9781 19.9658 74.7782 20.0979L39.7922 43.1037C39.7206 43.1528 39.6376 43.1754 39.5546 43.1754Z"
              fill="#B9BAC7"
            />
            <g id="Group_23">
              <path
                id="Vector_114"
                d="M88.3766 30.7729C84.7064 34.5563 80.9796 33.8509 79.2067 32.7193C79.0596 32.625 78.9238 32.5269 78.8031 32.4288C78.7013 32.3458 78.5919 32.244 78.4749 32.1233C76.9472 30.5465 74.6161 26.1521 74.2578 24.0812C73.6919 20.8297 74.2012 19.0417 75.3328 18.3175C76.5135 17.5631 76.5851 16.8275 78.0562 16.7408C79.633 16.6465 81.436 17.1142 82.2923 17.465C85.3137 18.7098 87.4789 20.8335 89.5912 23.3268C89.6214 23.3607 89.6478 23.3985 89.678 23.4362C89.8176 23.6097 89.9458 23.8096 90.059 24.0246C90.8851 25.5976 90.9152 28.1513 88.3766 30.7729Z"
                fill="#B9BAC7"
              />
              <path
                id="Vector_115"
                d="M77.8902 19.9659L77.4413 20.3582C76.5586 21.1352 75.212 21.0484 74.4349 20.1696L71.5719 16.9143C71.2966 16.5974 71.1268 16.224 71.0665 15.8317C71.0438 15.6883 71.0363 15.545 71.0438 15.3979C71.0702 14.8434 71.3154 14.3002 71.7643 13.9004L72.2094 13.5081C72.5979 13.1648 73.077 12.9875 73.5636 12.98C73.7107 12.9724 73.8616 12.9838 74.0087 13.0139C74.4613 13.0969 74.8914 13.3308 75.2195 13.7005L78.0825 16.9557C78.8558 17.8384 78.769 19.185 77.8864 19.9621L77.8902 19.9659Z"
                fill="#666680"
              />
              <path
                id="Vector_116"
                d="M74.0125 13.0139C73.9219 13.8174 73.4881 14.5869 72.8129 15.0773C72.3037 15.4431 71.6926 15.7261 71.0665 15.8317C71.0438 15.6883 71.0363 15.545 71.0438 15.3979C71.802 15.2621 72.5451 14.8396 72.9902 14.3191C73.2882 13.9683 73.4957 13.4817 73.5636 12.98C73.7107 12.9724 73.8616 12.9838 74.0087 13.0139H74.0125Z"
                fill="#313140"
              />
              <path
                id="Vector_117"
                d="M86.5808 30.4409C84.5854 32.1836 82.3712 32.9833 79.9985 32.8098C79.7307 32.7909 79.4704 32.757 79.2064 32.7155C79.0593 32.6212 78.9235 32.5231 78.8028 32.425C78.7009 32.3421 78.5915 32.2402 78.4746 32.1195C78.9801 32.2515 79.5006 32.3383 80.0287 32.376C82.2806 32.5382 84.3892 31.7762 86.2941 30.109C88.6139 28.0796 89.5079 25.6881 89.6739 23.4286C89.8135 23.6022 89.9417 23.8021 90.0549 24.0171C89.7795 26.2049 88.8139 28.4757 86.577 30.4334L86.5808 30.4409Z"
                fill="#80859E"
              />
            </g>
          </g>
          <path
            id="Vector_118"
            d="M53.3602 71.1868C53.296 71.1868 53.2319 71.1566 53.1904 71.1038C52.3983 70.0703 51.5647 69.0669 50.7612 68.0975C49.2825 66.3133 47.7549 64.4687 46.3479 62.3074C44.1337 58.9125 42.4777 55.2121 41.5611 51.606C41.5309 51.489 41.6026 51.3721 41.7158 51.3419C41.8327 51.3118 41.9496 51.3834 41.9798 51.4966C42.8851 55.0574 44.5222 58.7126 46.71 62.0697C48.1057 64.2085 49.6258 66.0455 51.0931 67.8183C51.9004 68.7915 52.734 69.7987 53.5299 70.8398C53.6016 70.9341 53.5865 71.0699 53.4884 71.1415C53.4507 71.1717 53.4017 71.1868 53.3564 71.1868H53.3602Z"
            fill="#3C425C"
          />
          <path
            id="Vector_119"
            d="M55.9708 34.1753C55.8954 34.1753 55.8199 34.1338 55.7822 34.0621C55.7256 33.9565 55.7633 33.8244 55.8652 33.7679C56.6271 33.3454 57.3627 32.7947 58.0492 32.1232C60.2634 29.9694 61.644 27.0649 62.704 24.5112C63.183 23.3569 63.613 22.1574 64.0317 20.9956C64.6315 19.3246 65.2539 17.597 66.0309 15.9675C67.0682 13.791 68.3809 12.6782 69.935 12.6556C69.9426 12.6556 69.9539 12.6556 69.9614 12.6556C70.9648 12.6556 71.8248 13.4062 72.5566 14.1154C72.6434 14.1984 72.6434 14.3342 72.5604 14.4209C72.4774 14.5077 72.3416 14.5077 72.2548 14.4247C71.5872 13.7759 70.8026 13.0894 69.9614 13.0894C69.9539 13.0894 69.9501 13.0894 69.9426 13.0894C68.5658 13.1082 67.3813 14.138 66.4232 16.1523C65.6575 17.763 65.0389 19.4793 64.4429 21.139C64.0242 22.3045 63.5904 23.5116 63.1076 24.6734C62.0288 27.2686 60.6218 30.2183 58.3548 32.4288C57.6381 33.1266 56.8723 33.7 56.0802 34.1413C56.0462 34.1602 56.0123 34.1677 55.9746 34.1677L55.9708 34.1753Z"
            fill="#3C425C"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_9473_7343">
          <rect
            width="237"
            height="160"
            fill="white"
            transform="translate(0.5 0.00201416)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
