import { Button } from "components/Button";
import { EmptyStateInProgress } from "components/icons/EmptyStateInProgress";
import EmptyStateAssignedToMe from "components/icons/EmptyStateAssignedToMe";
import EmptyStateCompleted from "components/icons/EmptyStateCompleted";

const EmptyState = ({ section }: { section: string }) => {
  return (
    <div className="flex  md:w-2/4 items-center m-auto gap-4 flex-col">
      {(section === "in-progress" && <EmptyStateInProgress />) ||
        (section === "assignedtome" && <EmptyStateAssignedToMe />) ||
        (section === "completed" && <EmptyStateCompleted />)}
      <p className="body text-center">
        {(section === "in-progress" &&
          "Your library is currently empty. Kickoff your learning journey by starting a program.") ||
          (section === "completed" &&
            "You haven't completed any programs yet, but there's a whole library waiting to be explored.") ||
          (section === "assignedtome" &&
            "Congratulations! Looks like you've completed your assigned programs. Feeling ready for more?")}
      </p>
      <Button variant="neutral" size="default" className="button-text-medium">
        Discover now
      </Button>
    </div>
  );
};

export default EmptyState;
