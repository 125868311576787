import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./Dialog";
import { WorkwayLogoFull } from "./logo/WorkwayLogo";
import { MicrosoftIcon } from "./icons/BrandsIcon";
import { Button } from "./Button";
// @ts-ignore
import Getstarted from "./logo/GetStarted.png";
import { useNavigate } from "react-router-dom";

const LockedModal = ({
  open = false,
  onCancel,
  isAuthenticated,
}: {
  open?: boolean;
  onCancel: () => void;
  isAuthenticated?: boolean;
}) => {
  const FreemiumView = () => {
    const navigate = useNavigate();
    const handleClickGetInTouch = () => {
      navigate("/interestform");
    };
    return (
      <div>
        <div className="flex flex-col gap-y-4">
          <p className="body-small">
            To access our full library of exclusive content, get in touch with
            our sales team about a membership for your organization today.
          </p>
          <Button
            variant="primary"
            className="text-md"
            onClick={handleClickGetInTouch}
          >
            Get in touch now
          </Button>
        </div>

        <div className="py-[6px]">
          <p className="title-9 text-center">
            or <button className="text-red-600 underline">learn more</button>{" "}
            about Workway
          </p>
        </div>
      </div>
    );
  };

  const PreLoggedinView = () => {
    return (
      <div>
        <div className="flex flex-col gap-y-4">
          <p className="body-small">
            To access our full library of exclusive content, log in now with
            your Microsoft Account.
          </p>
          <button
            type="button"
            // onClick={() => reload()}
            className="flex w-full justify-center items-center bg-white px-4 py-3 space-x-2 border border-cool-grey-300 rounded-lg"
          >
            <MicrosoftIcon size={16} />
            <p className="md:button-text-medium button-text-small text-cool-grey-500">
              Log in with Microsoft
            </p>
          </button>
        </div>

        <div className="py-[6px]">
          <p className="title-9 text-center">
            or <button className="text-red-600 underline">get in touch</button>{" "}
            with our sales team
          </p>
        </div>
      </div>
    );
  };

  return (
    <Dialog open={open}>
      <DialogContent
        className="z-[100]  md:max-w-lg max-w-[360px]  flex p-0 flex-col sm:rounded-2xl rounded-2xl bg-brown-50"
        onInteractOutside={onCancel}
      >
        <DialogTitle className="hidden"></DialogTitle>
        <DialogHeader className="">
          <div className="w-full h-full relative">
            <img
              className="w-full h-full object-cover rounded-t-2xl aspect-video "
              src={Getstarted}
              alt="cover image"
            />
          </div>
        </DialogHeader>

        <DialogFooter className="flex flex-col py-6 px-10">
          {isAuthenticated ? <FreemiumView /> : <PreLoggedinView />}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default LockedModal;
