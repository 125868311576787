import { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/helper.css";
import Icons from "./icons/Icons";

enum DropdownSections {
  profile = "profile",
  logout = "logout",
}

enum DropdownLabels {
  profile = "My profile",
  support = "Support",
  logout = "Log out",
}

const NavDropdownMenuTabletMobile = ({
  userData,
  setIsMenuOpen,
  menuRender,
}: {
  menuRender: Array<any>;
  userData: any;
  setIsMenuOpen: () => void;
}) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [activeAnimation, setActiveAnimation] = useState(false);

  const links = menuRender;

  const linksPage2 = () => {
    return [
      {
        icon: <Icons name="user-outlined" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.profile,
        section: DropdownSections.profile,
        link: "/account/profile",
      },
      {
        icon: <Icons name="life-buoy-help-outlined" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.support,
        section: DropdownSections.profile,
        link: "/",
      },
      {
        icon: <Icons name="log-out-filled" height={20} width={20} fill="#292D38" />,
        label: DropdownLabels.logout,
        section: DropdownSections.logout,
        link: "/",
      },
    ];
  };

  const pageNumberHandler = (value: number) => {
    setActiveAnimation(true);
    setPageNumber(value);
  };

  const userDetails = () => {
    return (
      <div className="flex gap-4 items-center justify-center py-3 ">
        <div className="relative">
          <img src={userData.profilePhoto} alt="user" className="w-10 h-10 rounded-full" />
          <img src={userData.smallLogo} alt="user" className="absolute bottom-0 right-0 w-4 h-4" />
        </div>
        <div className="flex flex-col px-4 py-2">
          <div className="text-sm font-semibold text-cool-grey-600">{userData.name}</div>
          <div className="text-xs text-cool-grey-500">{userData.email}</div>
        </div>
      </div>
    );
  };

  const adminPanel = () => {
    return (
      <div className="px-4 md:px-8 pb-4">
        <div className="py-2 w-full text-center bg-cool-grey-200 rounded-lg">
          <Link className="text-cool-grey-600 body-sm font-semibold cursor-pointer" to={userData.adminPanelURL}>
            Go to admin panel
          </Link>
        </div>
      </div>
    );
  };
  return (
    <div className="fixed md:absolute w-full top-12 z-[50] bg-white border-cool-grey-300 ">
      {pageNumber === 1 ? (
        <div className={`${activeAnimation ? "fade-in-animation-right-left" : ""}`}>
          <div className="flex flex-col border-b border-cool-grey-300 px-4 md:px-8 ">
            {links.map((el) => (
              <Link
                to={el.link}
                key={el.label}
                className="py-4 text-cool-grey-600 font-semibold"
                onClick={setIsMenuOpen}
              >
                {el.label}
              </Link>
            ))}
          </div>
          <div
            className="px-4 flex justify-between items-center gap-4 md:px-8"
            onClick={() => pageNumberHandler(2)}
            onKeyDown={() => pageNumberHandler(2)}
            onKeyPress={() => pageNumberHandler(2)}
            onKeyUp={() => pageNumberHandler(2)}
            role="button"
          >
            {userDetails()}
            <div>
              <Icons name="chevron-right-filled" width={24} height={24} fill="#979CA5" />
            </div>
          </div>
          {userData.isAdmin && adminPanel()}
        </div>
      ) : (
        <div className="fade-in-animation-left-right">
          <div className="border-b border-cool-grey-300">
            <div
              className="flex gap-4 items-center px-4 md:px-8"
              onClick={() => pageNumberHandler(1)}
              onKeyDown={() => pageNumberHandler(1)}
              onKeyPress={() => pageNumberHandler(1)}
              onKeyUp={() => pageNumberHandler(1)}
              role="button"
            >
              <Icons name="chevron-left-filled" width={24} height={24} fill="#979CA5" />
              {userDetails()}
            </div>
            {userData.isAdmin && adminPanel()}
          </div>
          <div className="px-4 md:px-8 py-4 flex flex-col gap-4 border-b border-cool-grey-300">
            {linksPage2()
              .filter(({ section }) => section === DropdownSections.profile)
              .map((el) => (
                <Link
                  to={el.link}
                  key={el.label}
                  className="flex gap-3 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg"
                >
                  <div>{el.icon}</div>
                  <div className="font-semibold">{el.label}</div>
                </Link>
              ))}
          </div>
          <div className="px-4 md:px-8 py-4 flex flex-col gap-4 ">
            {linksPage2()
              .filter(({ section }) => section === DropdownSections.logout)
              .map((el) => (
                <Link
                  to={el.link}
                  key={el.label}
                  className="flex gap-3 cursor-pointer text-cool-grey-600 hover:bg-red-8a hover:text-red-600 px-2 rounded-lg"
                >
                  <div>{el.icon}</div>
                  <div className="font-semibold">{el.label}</div>
                </Link>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavDropdownMenuTabletMobile;
