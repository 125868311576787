import {
  type AccountInfo,
  type Configuration,
  LogLevel,
  ProtocolMode,
  ServerResponseType,
} from "@azure/msal-browser";
import { loadConfig } from "utils/loadEnv";
import { buildAuthUrl } from "utils/urlBuilder";

export const authConfig = {
  initiateLoginEndpoint: loadConfig("REACT_APP_START_LOGIN_PAGE_URL"),
  authClientId: loadConfig("REACT_APP_AUTH0_AUTH_CLIENT_ID"),
  authAuthority: loadConfig("REACT_APP_AUTH0_AUTH_AUTHORITY"),
  authScopes: loadConfig("REACT_APP_AUTH0_AUTH_SCOPES"),
  authAudience: loadConfig("REACT_APP_AUTH0_AUTH_AUDIENCE"),
  authMicrosoftConnectionName: loadConfig(
    "REACT_APP_AUTH0_AUTH_MICROSOFT_CONNECTION_NAME"
  ),
  responseType: "token id_token",
  responseMode: "query",
};

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const loginRequest = {
  scopes: [authConfig.authScopes],
};

export const msalConfig: Configuration = {
  auth: {
    clientId: authConfig.authClientId,
    authority: authConfig.authAuthority,
    knownAuthorities: [authConfig.authAuthority.replace(/^https?:\/\//, "")],
    protocolMode: ProtocolMode.OIDC,
    OIDCOptions: {
      serverResponseType: ServerResponseType.QUERY,
      defaultScopes: ["openid"],
    },
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    allowNativeBroker: false,
    loggerOptions: {
      loggerCallback: (
        level: unknown,
        message: unknown,
        containsPii: unknown
      ) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const authUrl = buildAuthUrl(authConfig.initiateLoginEndpoint, {
  clientId: authConfig.authClientId,
  authority: authConfig.authAuthority,
  knownAuthorities: authConfig.authAuthority.replace(/^https?:\/\//, ""),
  scopes: loginRequest.scopes.join(""),
  audience: authConfig.authAudience,
  microsoftConnection: authConfig.authMicrosoftConnectionName,
  responseType: authConfig.responseType,
  responseMode: authConfig.responseMode,
});

export const authConnectionBuilder = (account: AccountInfo) => {
  return {
    scopes: loginRequest.scopes,
    account,
    extraQueryParameters: {
      connection: authConfig.authMicrosoftConnectionName,
      response_type: authConfig.responseType,
      response_mode: authConfig.responseMode,
      audience: authConfig.authAudience,
    },
  };
};
