import { Tab, TabList } from "@fluentui/react-components";
import type { TabListProps } from "@fluentui/react-components";
import { app, meeting } from "@microsoft/teams-js";
import useEmblaCarousel from "embla-carousel-react";
import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { PrimaryAssetI } from "types/interfaces";
import MeditationsListView from "./views/MeditationsListView";
import MeditationsSeriesListView from "./views/MeditationsSeriesListView";
import QuestListView from "./views/QuestsListView";

enum TabConfigEnum {
  Quest = "Quest",
  Meditations = "Meditations",
  MeditationsSeries = "Series",
}

export default function AppInMeetingView(props: Partial<TabListProps>) {
  const navigate = useNavigate();

  const [emblaRef] = useEmblaCarousel({ loop: true });
  const [selectedTab, setSelectedTab] = useState(TabConfigEnum.Quest);
  const [frameContext, setFrameContext] = useState<string | null>(null);

  useEffect(() => {
    app.getContext().then((context: app.Context) => {
      setFrameContext(context.page.frameContext);
    });
  }, []);

  function shareSpecificPart(mediaUrl: PrimaryAssetI, coverAsset: string) {
    let appContentUrl = "";

    if (frameContext === "content") {
      navigate(
        `/stageView?mediaUrl=${encodeURIComponent(mediaUrl.url)}&thumbnailUrl=${encodeURIComponent(
          coverAsset,
        )}&autoplay=true&mimeType=${mediaUrl.contentType}&isStageViewSupported=false`,
      );
    } else {
      appContentUrl = `${window.location.origin}/stageView?mediaUrl=${encodeURIComponent(
        mediaUrl.url,
      )}&thumbnailUrl=${encodeURIComponent(coverAsset)}&autoplay=true`;
      meeting.shareAppContentToStage((err, result) => {
        if (result) {
          console.log(result);
        }
        if (err) {
          alert(JSON.stringify(err));
        }
      }, appContentUrl);
    }
  }

  return (
    <section className={cn("mx-auto mr-2", frameContext === "content" && "px-6")}>
      <TabList
        defaultSelectedValue={TabConfigEnum.Quest}
        onTabSelect={(_, data) => {
          setSelectedTab(data.value as TabConfigEnum);
        }}
        {...props}
      >
        <div ref={emblaRef}>
          <div className="touch-pan-y flex">
            {Object.values(TabConfigEnum).map((tabValue) => (
              <Tab key={tabValue} value={tabValue}>
                {tabValue}
              </Tab>
            ))}
          </div>
        </div>
      </TabList>

      {selectedTab === TabConfigEnum.Quest && (
        <QuestListView shareSpecificPart={shareSpecificPart} frameContext={frameContext} />
      )}
      {selectedTab === TabConfigEnum.Meditations && (
        <MeditationsListView shareSpecificPart={shareSpecificPart} frameContext={frameContext} />
      )}
      {selectedTab === TabConfigEnum.MeditationsSeries && <MeditationsSeriesListView />}
    </section>
  );
}
