import type {IOutcome} from "types/interfaces";

interface OutcomesProps {
    outcomes: IOutcome[];  // Expect outcomes to be an array
}
const Outcomes = ({ outcomes }: OutcomesProps) => {
  return (
    <div className="flex flex-col md:flex-row gap-y-4 md:gap-x-6">
      <div className="heading-8 text-cool-grey-700 w-full md:w-[180px]">Outcomes</div>
      <div className="flex flex-row flex-1 gap-1.5 flex-wrap">
        {outcomes.map((outcome) => (
          <div key={outcome.name} className="py-1 px-2 border border-cool-grey-300 rounded-[100px] button-text-small text-cool-grey-600">
            {outcome.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Outcomes;
