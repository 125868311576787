import { Button } from "components/Button";
import Icons from "components/icons/Icons";
import { WorkwayLogoFull } from "components/logo/WorkwayLogo";
import { useNavigate } from "react-router-dom";
import HubspotForm from "./components/HubspotForm";

const portalId = "619497";
const formId = "a1cd346d-5de8-43c2-acd8-dc0221209d35";
const CompanyNameForm = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-y-6  pt-20">
      <div className="px-5">
        <Button
          variant={"icon"}
          className="px-3 py-[6px]"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Icons name="arrow-left-circle-outlined" width={20} height={20} fill="black" />
          <p className="px-1 button-text-medium">Back</p>
        </Button>
      </div>
      <div className="max-w-[680px] self-center">
        <div className="  flex flex-col items-center gap-y-4">
          <WorkwayLogoFull width="160" height="26" />
          <p className="title-8">Your organization doesn't have an active account yet. Let's get you started.</p>
        </div>
      </div>
      <HubspotForm portalId={portalId} formId={formId} />
    </div>
  );
};

export default CompanyNameForm;
