import { UploadIcon } from "@radix-ui/react-icons";
import { cn } from "libs/classMerger";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "./Button";

export const Dropzone = ({ setFile }: { setFile: (file: string | ArrayBuffer) => void }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) setFile(reader.result);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
      "image/webp": [],
    },
  });

  return (
    <div
      {...getRootProps({
        className: cn(
          isDragAccept ? "bg-red-8a" : "",
          "dropzone w-full py-20 px-25 flex flex-col border border-dashed border-cool-grey-300 rounded-[9px] border-[1px] gap-6 my-6 justify-between align-middle items-center self-stretch",
        ),
      })}
    >
      <input {...getInputProps()} hidden />
      {isDragAccept ? (
        <div className="h-70 py-11 flex gap-1 items-center align-middle">
          <UploadIcon className="cursor-pointer w-5 h-5 text-cool-grey-600" />
          <p className="title-8">Drop your image here</p>
        </div>
      ) : (
        <div className="h-70 flex flex-col justify-between items-center align-middle w-full">
          <Button variant={"link"} className="px-1">
            <UploadIcon className="cursor-pointer w-10 h-10 mx-4 my-3 text-cool-grey-600" />
          </Button>
          <div className="title-7">
            Drop your image here, or
            <Button variant={"link"} className="px-1">
              <p className="title-7 text-red-600">browse</p>
            </Button>
          </div>
          <div className="flex">
            <span className="body-2xs text-content-quaternary">Supports: PNG, JPG, JPEG, WEBP</span>
          </div>
        </div>
      )}
    </div>
  );
};
