import { cn } from "libs/classMerger";
import { useEffect, useState } from "react";
import { QuestCard, type QuestData } from "../components/QuestCard";

export default function AssignedToYouSection() {
  const [cards, setCards] = useState([] as QuestData[]);

  useEffect(() => {
    setCards([
      {
        id: 1,
        title: "The Art of Manifesting",
        author: "Regan Hillyer",
        endDate: "Jul 30, 2023",
        image:
          "https://cdn.mindvalley.com/asset/2c67ca2d-fc26-4ba2-9ad8-f1b41d693ab1/image.png?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 2,
        title: "The Silva Ultramind System",
        author: "Vishen",
        endDate: "Jul 30, 2023",
        image:
          "https://cdn.mindvalley.com/asset/10b1de3f-44d4-4dd5-84d7-dc2456a79da4/3NyD7jfA.jpeg?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 3,
        title: "Duality",
        author: "Jeffrey Allen",
        endDate: "Jul 30, 2023",
        image:
          "https://cdn.mindvalley.com/asset/ebac39bf-5a08-4aec-9476-af0735a843a9/DU_ENG_Cover.jpg?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 4,
        title: "Be Extraordinary",
        endDate: "Jul 30, 2023",
        author: "Jim Kwik",
        image:
          "https://cdn.mindvalley.com/asset/af39a317-eb44-4dbc-a2b9-c98f9bc1de04/BE_QuestCover_1920x1080_EN.jpg?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 5,
        title: "Quantum Jumping",
        author: "Burt Goldman",
        image:
          "https://cdn.mindvalley.com/asset/c4a5748e-c0ce-404f-bc56-439e6031d5ce/QJ_Burt_Goldman_Quest_Cover_1920x1080px_EN.jpg?auto=webp&fit=cover&quality=50&dpr=2",
      },
    ]);
  }, []);

  return (
    <section className="flex flex-col gap-y-6">
      <div className="flex justify-between">
        <div className="heading-6">Assigned to you</div>
        <button type="button" className="px-3 py-[6px] rounded-[100px] text-red-600 button-text-medium">
          See all
        </button>
      </div>

      <div className={cn("flex flex-row gap-6 lg:hidden")}>
        {cards.map((card) => {
          return <QuestCard key={card.id} card={card} />;
        })}
      </div>

      <div className={cn("hidden lg:flex gap-6 overflow-hidden grid-rows-1")}>
        {cards.slice(0, 3).map((card) => {
          return <QuestCard key={card.id} card={card} />;
        })}
      </div>
    </section>
  );
}
