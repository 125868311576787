import { MediaPlayer } from "components/MediaPlayer";
import Icons from "components/icons/Icons";
import { MeditationIcon } from "components/icons/MeditationIcon";
import useEmblaCarousel from "embla-carousel-react";
import { useNavigate } from "react-router-dom";
import { NavigationEnum } from "router/navigationType";
import useScreenType from "../../../hooks/useScreenType";

export function HeroCarousel() {
  const [emblaRef] = useEmblaCarousel({ loop: true });

  return (
    <section className="mx-auto overflow-auto lg:max-xl:overflow-visible">
      <div ref={emblaRef}>
        <div className="flex touch-pan-y gap-4 px-4">
          {cards.map((card) => (
            <div className="shrink-0 lg:max-xl:basis-3/4 w-full max-h-[800px] max-w-[1024px]" key={card.label}>
              <Card
                entity={card.entity}
                subEntity={card.subEntity}
                text={card.text}
                link={card.link}
                label={card.label}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

function Card({ entity, subEntity, label, text, link }: CardProps) {
  const navigate = useNavigate();
  const screenType = useScreenType();

  return (
    <button
      type="button"
      className="rounded-3xl relative overflow-x-hidden cursor-pointer"
      onClick={() => navigate(entity + (subEntity ? `#${subEntity}` : ""))}
    >
      <MediaPlayer
        mediaUrl={link}
        autoplay={!screenType.isMobile}
        muted={true}
        autoplayVisible={true}
        looped={true}
        playerClasses="aspect-auto relative"
        showVControls={false}
      />
      <div className="flex flex-col">
        <div className="absolute top-8 left-8">
          <div className="bg-white/70 flex flex-row gap-1 py-2 px-4 rounded-full text-cool-grey-600 items-center">
            {getIcon(entity)}
            <p className="heading-7">{label}</p>
          </div>
        </div>

        <div
          className="absolute bottom-0 left-0 right-10 w-full flex flex-col md:flex-row items-center"
          style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)" }}
        >
          <div className="gap-10 px-10 pb-10 pt-14 justify-between items-start inline-flex self-stretch">
            <div className="text-left text-white title-5 align-middle	">{text}</div>
            <div className="p-2 bg-white/90 rounded-[128px] justify-start items-start gap-[10px] flex">
              <div className="ArrowDownRight relative">
                <Icons name="arrow-down-right-filled" width={40} height={40} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </button>
  );
}

function getIcon(entity: string) {
  switch (entity) {
    case "programs":
      return <Icons name="mountain-outlined" height={24} width={24} fill="#292D38" />;
    case "meditations":
      return <MeditationIcon />;
    case "meditations_series":
      return <Icons name="playlist-filled" height={24} width={24} />;
    default:
      return <Icons name="mountain-outlined" height={24} width={24} fill="#292D38" />;
  }
}

interface CardProps {
  entity: string;
  subEntity?: string;
  text: string;
  link: string;
  label: string;
}

const cards: CardProps[] = [
  {
    entity: NavigationEnum.Programs,
    label: "Quests",
    text: "Learnings that are short, daily lessons designed to keep you engaged and growing at work.",
    link: "/hero/banner.mp4",
  },
  {
    entity: NavigationEnum.Meditations,
    label: "Meditations",
    text: "Guided mindfulness practices to enhance focus, reduce stress, and boost general wellbeing.",
    link: "/hero/meditation.mp4",
  },
  {
    entity: NavigationEnum.Meditations,
    subEntity: "meditations_series",
    label: "Meditation Series",
    text: "Curated collections of meditations tailored to get you started on your wellbeing journey.",
    link: "/hero/banner-med-series.mp4",
  },
];
