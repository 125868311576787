import { useQuery } from "@apollo/client";

import gqlQuery from "services/graphql/queries";
import type { IQuest } from "types/interfaces";

function useQuestBySlugAndLanguage(slug: string, language: string) {
  const { loading, error, data, refetch } = useQuery(gqlQuery.getQuestBySlugAndLanguage, {
    variables: {
      slugAndLanguage: {
        slug,
        language,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  return { data: data?.b2bQuestBySlugAndLanguage as IQuest, loading, error, refetch };
}

export default useQuestBySlugAndLanguage;
