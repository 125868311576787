import { app, stageView } from "@microsoft/teams-js";
import { teamsConfig } from "config/teamsConfig";
import { useNavigate } from "react-router-dom";
import { BannerImage } from "tabs/Home/components/BannerImage";
import { MeditationMedia } from "tabs/Meditations/components/MeditationMedia";
import type { SeriesI } from "types/interfaces";

// import { PlayIcon } from "components/icons/PlayIcon";

interface FeaturedMeditationsSectionProps {
  series?: SeriesI;
}

export function FeaturedMeditationsSection({ series }: FeaturedMeditationsSectionProps) {
  const navigate = useNavigate();

  function handlePlay({
    mediaAsset,
    coverAsset,
    name,
    mimeType,
  }: {
    mediaAsset: string;
    coverAsset?: string;
    name: string;
    mimeType: string;
  }) {
    app.initialize().then(() => {
      if (stageView.isSupported()) {
        stageView
          .open({
            appId: teamsConfig.appId,
            contentUrl: `${window.location.origin}/stageView?mediaUrl=${mediaAsset}&thumbnailUrl=${coverAsset}&autoplay=true`,
            title: name,
            websiteUrl: window.location.origin,
            openMode: stageView.StageViewOpenMode.modal,
            threadId: "",
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        navigate(
          `/stageView?mediaUrl=${encodeURIComponent(mediaAsset)}&thumbnailUrl=${encodeURIComponent(
            coverAsset || "",
          )}&autoplay=true&mimeType=${mimeType}&isStageViewSupported=false`,
        );
      }
    });
  }

  return series ? (
    <div className="flex flex-col gap-8">
      <div className="flex flex-col md:flex-row items-end gap-10">
        <h3 className="heading-3 w-full md:w-2/5">Featured series</h3>
        <p className="body w-full md:w-3/5">
          Immerse yourself in our Meditation Series, curated to address specific needs and enhance your well-being. Each
          series offers a collection of guided meditations, expertly designed to help you achieve your goals, whether
          it's starting your day with clarity or winding down for a restful night's sleep. With our Meditation Series,
          you can easily integrate mindfulness into your daily routine and enjoy the profound benefits it brings to your
          life.
        </p>
      </div>
      <div className="flex flex-col md:flex-row gap-4">
        <div className="w-full md:w-8/12 aspect-video">
          <BannerImage src={series.coverAsset.url} alt={series.title} />
        </div>
        <div className="w-full md:w-4/12 relative">
          <div className="flex md:flex-col gap-6 overflow-x-scroll md:overflow-y-scroll scrollbar-hide md:absolute size-full top-0 pb-[15%]">
            {series.media.map((media) => (
              <MeditationMedia
                key={media.id}
                media={media}
                handleClick={() =>
                  handlePlay({
                    mediaAsset: media.mediaAsset.url,
                    coverAsset: media.coverAsset?.url,
                    name: media.title,
                    mimeType: media.mediaAsset.contentType,
                  })
                }
              />
            ))}
            <div className="min-h-10" />
          </div>
          <div className="md:absolute bottom-0 left-0 right-0">
            <div className="relative rounded-t-2xl bg-white">
              <div className="bg-gradient-to-b from-[#0000] to-white h-16 w-full block absolute bottom-[80%]" />

              <div className="bg-black/90 px-3 py-4 rounded-2xl flex flex-row gap-4 items-center mix-blend-multiply h-[80px]">
                {/* <button
                  onClick={() => handlePlayAll()}
                  className="rounded-full bg-white flex flex-row items-center px-3 py-4"
                >
                  <PlayIcon size={24} />
                  <span className="button-text px-2">Play all</span>
                </button> */}
                <span className="flex-1 body-small text-cool-grey-100">
                  {`There are ${series.media.length} meditations from this series`}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div />
  );
}
