import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "config/msalConfig";
// interfaces
import {
  type IApiResponse,
  type ICategoryEdge,
  type ICategoryNode,
  type IGraphQLResponse,
  type IMeditation,
  type IQuestEdge,
  type IQuestNode,
  type IQuestsData,
  type IRecommendedQuestEdge,
  type ISeries,
  type ICategoryNodeAdditionalInfo,
  type ICurrentOrganization,
  type ICurrentOrganizationResponse,
  type ICurrentProfile,
  type IQuestProgressInput,
  type IUpdateProfileInput,
  type IUpdateCurrentProfile,
  type ISpokenLanguage,
  QuestCategoryEnum,
  ICity,
  IRecommendedQuestNode,
} from "types/interfaces";
// gql
import gqlQuery from "./graphql/queries";
// mocks
import curatedSeries from "./mocks/curatedSeries.json";
import meditationsData from "./mocks/meditations.json";
import freeCategories from "./mocks/freeCategories.json";
import freeRecommendedQuests from "./mocks/recommendedQuests.json";
import category54 from "./mocks/quests_by_category/54.json";
import category55 from "./mocks/quests_by_category/55.json";
import category56 from "./mocks/quests_by_category/56.json";
import category57 from "./mocks/quests_by_category/57.json";
import categoryDefault from "./mocks/quests_by_category/default.json";

// TODO: change to dynamic import
import prodAdditionalInfo from "./mocks/category_node_additional_info/prod.json";
// proxy
import { callApiViaBot } from "./proxyDataService";

enum LogInState {
  PreloggedIn = "preloggedIn",
  Freemium = "freemium",
  Paid = "paid",
}

export class DataService {
  private instance: IPublicClientApplication;
  public static currentLoginState: LogInState = LogInState.PreloggedIn;
  private static initializationPromise: Promise<void> | null = null;
  private authToken: string | null = null;

  constructor(instance: IPublicClientApplication) {
    this.instance = instance;
    if (!DataService.initializationPromise) {
      DataService.initializationPromise = this.initializeLoginState();
    }
  }

  private async initializeLoginState(): Promise<void> {
    try {
      const tempAuthToken =
        "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ik5FWXpNVVJDT0VFM09FTXlNelpCTkRRNU1EYzBNakU0TlRjek0wRTBNVGd4TmpaR1JUZzVPUSJ9.eyJpc3MiOiJodHRwczovL2xvZ2luLm1pbmR2YWxsZXkuY29tLyIsInN1YiI6ImF1dGgwfDY2MDNiN2RmNDlkZGMzMGJkZTA0ZTVkNCIsImF1ZCI6WyJodHRwczovL3BsYXRmb3JtLWFwaS5taW5kdmFsbGV5LmNvbSIsImh0dHBzOi8vbWluZHZhbGxleS5hdXRoMC5jb20vdXNlcmluZm8iXSwiaWF0IjoxNzIyOTMzOTIyLCJleHAiOjE3MjI5NDExMjIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwiLCJhenAiOiJLUmFGbUNla3RBdFhSNnNkMmd1bVdWRlFhNkFuWG53RiJ9.d8C9VERw_yopADsArAMhA5r5B4Rbbk0jBtx1FiBzgV5zxL6rE0YvJ9CggS9HomOeluRwv8EeOlXac_NjVJf_1og7bi4tcH24paEftLASVHQ7b5umQm2hfxeL0lH9oKM47Fzy0l1nXcjBWDBu0SZrA1X307TI5mJUBSvzVIbQux-BfRK6ubsA40i839xrWhy1X0Z4w0qGbP-SAjZnchs3T2JglAFmDhI8m8Tloh-IxWvH924HdB_7xzUQ7XMtHxNNeqVakQDWslLXtNvKFZuAgroDZGjJiqPl0rXNF8R0dAycExugnhwQ_IEm1LN0hS6KEHWfU1BENiLLb20mFMWvqA";

      const authResponse = await this.instance.acquireTokenSilent({
        scopes: loginRequest.scopes,
      });
      this.authToken = authResponse.accessToken ? tempAuthToken : null;

      if (this.authToken) {
        const orgInfo = await this.getCurrentOrg();
        DataService.currentLoginState = orgInfo
          ? LogInState.Paid
          : LogInState.Freemium;
      } else {
        DataService.currentLoginState = LogInState.PreloggedIn;
      }
    } catch (error) {
      console.error("Error initializing login state:", error);
      DataService.currentLoginState = LogInState.PreloggedIn;
    }
  }

  // To refresh when login or logout event happens
  public async updateLoginState(): Promise<void> {
    await this.initializeLoginState();
  }

  public static async ensureInitialized(
    instance: IPublicClientApplication
  ): Promise<void> {
    if (!DataService.initializationPromise) {
      const dataService = new DataService(instance);
      await dataService.initializeLoginState();
    }
    await DataService.initializationPromise;
  }

  async getCurrentOrg(): Promise<ICurrentOrganization | null> {
    console.log("getCurrentOrg");
    try {
      const response = await callApiViaBot(
        this.authToken,
        gqlQuery.getCurrentOrgDeprecated
      );
      console.log("getCurrentOrg", response);
      const data = response.data as ICurrentOrganizationResponse;
      return data.currentOrganization;
    } catch (error) {
      console.error("Error fetching categories:", error);
      throw error;
    }
  }

  // async fetchCategories(): Promise<ICategoryNode[]> {
  //   console.log("fetchCategories");
  //   await DataService.ensureInitialized(this.instance);
  //   // mapping for nodeTitle and nodeDescription based on env
  //   const env = process.env.NODE_ENV;
  //   const additionalInfo: ICategoryNodeAdditionalInfo = prodAdditionalInfo;
  //   // dynamic import is the issue here

  //   const banners = {
  //     [QuestCategoryEnum.Career]: {
  //       mobile:
  //         "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-01-m-a7d65162c49c4f4b16db61028aabdd01.png",
  //       tablet:
  //         "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-01-t-1ac4757f827599a23d37361b51c73562.png",
  //       desktop:
  //         "https://cdn-assets.workway.app/images/programs/banner/banner-01-7e9ec04942c4fd844729daf39d7883e1.png",
  //     },
  //     [QuestCategoryEnum.Mindset]: {
  //       mobile:
  //         "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-02-m-4c35af917119d58f6fb0f964ace1d43d.png",
  //       tablet:
  //         "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-02-t-964fe95af036db956814523aa8ec8a40.png",
  //       desktop:
  //         "https://cdn-assets.workway.app/images/programs/banner/banner-02-5e2d2ba03de67d77c2451fa1644ea277.png",
  //     },
  //     [QuestCategoryEnum.Collaboration]: {
  //       mobile:
  //         "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-03-m-856ec7c5641f96ef940247c8441cdb1f.png",
  //       tablet:
  //         "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-03-t-9d592757ab34572b15c0ea6d4263c4c9.png",
  //       desktop:
  //         "https://cdn-assets.workway.app/images/programs/banner/banner-03-a8daa75c69cdb25c5f8fe5907130e3e5.png",
  //     },
  //     [QuestCategoryEnum.Soul]: {
  //       mobile:
  //         "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-04-m-60c78ccbb311b680981e5b58cec74c4c.png",
  //       tablet:
  //         "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-04-t-eb37b3a2388be2adfdf68bd8eca555d3.png",
  //       desktop:
  //         "https://cdn-assets.workway.app/images/programs/banner/banner-04-aa2abe2e6d7f53b535737893ef9fee26.png",
  //     },
  //     [QuestCategoryEnum.Health]: {
  //       mobile:
  //         "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-05-m-bff4ab901ab421e9965d590101f1e513.png",
  //       tablet:
  //         "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-05-t-3e3e0a53ffdfe3f58bab6b14d24ff777.png",
  //       desktop:
  //         "https://cdn-assets.workway.app/images/programs/banner/banner-05-de689099454b4fea9c8764d41d71ccc9.png",
  //     },
  //     [QuestCategoryEnum.Intrapreneurship]: {
  //       mobile:
  //         "https://cdn-assets.workway.app/images/programs/banner/mobile/banner-06-m-a773f46551e90dc5a59a666137b1a958.png",
  //       tablet:
  //         "https://cdn-assets.workway.app/images/programs/banner/tablet/banner-06-t-b28d5fcf30a543c9f181919f15e21e54.png",
  //       desktop:
  //         "https://cdn-assets.workway.app/images/programs/banner/banner-06-5059ac5ed0deabd6b882278e8c6075f7.png",
  //     },
  //   } as Record<string, any>;

  //   try {
  //     const categoriesData =
  //       DataService.currentLoginState === LogInState.Paid
  //         ? await callApiViaBot(this.authToken, gqlQuery.getCategories)
  //         : freeCategories;

  //     const categories = categoriesData.data.categories.edges
  //       // .filter((edge: ICategoryEdge) => edge.node.id !== "59") // Coaching is not a category
  //       .map((edge: ICategoryEdge) => {
  //         const additional = additionalInfo[edge.node.id] || {
  //           title: "",
  //           description: "",
  //         };
  //         const name = edge.node.name.replace(/-B2B/g, "").trim(); // B2B text must not appear on UI
  //         return {
  //           id: edge.node.id,
  //           name: name,
  //           assets: {
  //             banners: banners[edge.node.id],
  //           },
  //           title: additional.title,
  //           description: additional.description,
  //           color: additional.color,
  //           pattern: additional.pattern,
  //         };
  //       });

  //     return categories;
  //   } catch (error) {
  //     console.error("Error fetching categories:", error);
  //     throw error;
  //   }
  // }

  getMockCategoryData(categoryId: string) {
    switch (categoryId) {
      case "54":
        return category54;
      case "55":
        return category55;
      case "56":
        return category56;
      case "57":
        return category57;
      default:
        return categoryDefault;
    }
  }

  async getCuratedSeries(): Promise<ISeries[]> {
    await DataService.ensureInitialized(this.instance);
    return new Promise((resolve) => {
      setTimeout(() => {
        const series = (curatedSeries as IApiResponse).data.series;
        resolve(series);
      }, 300);
    });
  }

  async getMeditations(): Promise<IMeditation[]> {
    await DataService.ensureInitialized(this.instance);
    return new Promise((resolve) => {
      setTimeout(() => {
        const data: IApiResponse = meditationsData as IApiResponse;
        const meditations: IMeditation[] = (data.data.meditations ?? []).map(
          (item) =>
            ({
              resource: {
                author: item.resource.author,
                coverAsset: item.resource.coverAsset,
                ctaQuest: item.resource.ctaQuest,
                description: item.resource.description,
                globalId: item.resource.globalId,
                id: item.resource.id,
                info: item.resource.info,
                mediaAsset: item.resource.mediaAsset,
                title: item.resource.title,
              },
            } as IMeditation)
        );
        resolve(meditations);
      }, 300);
    });
  }

  async getCityList(keyword = ""): Promise<ICity[]> {
    return Promise.resolve([
      {
        id: "1",
        name: "Bangalore",
        cityCode: "BNG",
        country: {
          id: "1",
          iso3Code: "IND",
          name: "India",
        },
      } as unknown as ICity,
      {
        id: "2",
        name: "Melbourne",
        cityCode: "MLB",
        country: {
          id: "2",
          iso3Code: "AUS",
          name: "Australia",
        },
      } as unknown as ICity,
      {
        id: "3",
        name: "Sydney",
        cityCode: "SYD",
        country: {
          id: "2",
          iso3Code: "AUS",
          name: "Australia",
        },
      } as unknown as ICity,
    ]);
  }

  async getQuestAssignments(count: number = 3): Promise<IQuestNode[]> {
    await DataService.ensureInitialized(this.instance);
    try {
      if (DataService.currentLoginState == LogInState.Paid) {
        const response = await callApiViaBot(
          this.authToken,
          gqlQuery.getQuestAssignments,
          { first: count }
        );

        const quests = response.data.currentWorkwayQuestAssignments.edges.map(
          (edge: IQuestEdge) => ({
            authors: edge.node.authors,
            coverAsset: edge.node.coverAsset,
            description: edge.node.description,
            headshotCoverAsset: edge.node.headshotCoverAsset,
            id: edge.node.id,
            name: edge.node.name,
            resources: edge.node.resources,
            type: edge.node.type,
            url: edge.node.url,
            wordmarkAsset: edge.node.wordmarkAsset,
          })
        );

        return quests;
      } else {
        return []; // Assuming only paid users will have assigned quests
      }
    } catch (error) {
      console.error("Error fetching Quests Assignments:", error);
      throw error;
    }
  }

  async getRecommendedQuests(count: number = 3): Promise<IQuestNode[]> {
    await DataService.ensureInitialized(this.instance);
    try {
      if (DataService.currentLoginState == LogInState.Paid) {
        const response = await callApiViaBot(
          this.authToken,
          gqlQuery.getQuestAssignments,
          { first: count }
        );

        const quests = response.data.recommendedB2bQuests.edges.map(
          (edge: IQuestEdge) => ({
            authors: edge.node.authors,
            coverAsset: edge.node.coverAsset,
            description: edge.node.description,
            headshotCoverAsset: edge.node.headshotCoverAsset,
            id: edge.node.id,
            name: edge.node.name,
            resources: edge.node.resources,
            type: edge.node.type,
            url: edge.node.url,
            wordmarkAsset: edge.node.wordmarkAsset,
          })
        );

        return quests;
      } else {
        return []; // Assuming only paid users will have recommended quests
      }
    } catch (error) {
      console.error("Error fetching Recommended quests:", error);
      throw error;
    }
  }

  // TODO: The query might change once there is a B2B version
  async getMeditationsOfTheDay(count: number = 3): Promise<IMeditation[]> {
    await DataService.ensureInitialized(this.instance);
    try {
      if (DataService.currentLoginState == LogInState.Paid) {
        const response = await callApiViaBot(
          this.authToken,
          gqlQuery.getMeditationsOfTheDay,
          { first: count }
        );
        const meditations: IMeditation[] =
          response.data.recommendedMeditations.edges.map((item: any) => ({
            resource: item.node.resource,
          }));
        return meditations;
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching Mediations of the day:", error);
      throw error;
    }
  }

  async getCurrentProfile(): Promise<ICurrentProfile | null> {
    await DataService.ensureInitialized(this.instance);
    try {
      if (DataService.currentLoginState == LogInState.Paid) {
        const response = await callApiViaBot(
          this.authToken,
          gqlQuery.getProfile,
          {}
        );
        const profile: ICurrentProfile = response.data.currentProfile;
        return profile;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error fetching currentProfile:", error);
      throw error;
    }
  }

  async getLibrary(progress: IQuestProgressInput): Promise<IQuestNode[]> {
    await DataService.ensureInitialized(this.instance);
    try {
      if (DataService.currentLoginState === LogInState.Paid) {
        const response = await callApiViaBot(
          this.authToken,
          // @ts-ignore
          gqlQuery.getLibrary,
          { progress }
        );

        return response.data.quests.edges.map((edge: IQuestEdge) => edge.node);
      } else {
        return [];
      }
    } catch (error) {
      console.error("Error fetching library:", error);
      throw error;
    }
  }

  async updateProfile(
    input: IUpdateProfileInput
  ): Promise<IUpdateCurrentProfile> {
    await DataService.ensureInitialized(this.instance);
    try {
      const response = await callApiViaBot(
        this.authToken,
        gqlQuery.updateCurrentProfile,
        { input }
      );

      const result: IUpdateCurrentProfile = {
        result: {
          ...response.data.updateCurrentProfile.result,
          spokenLanguages:
            response.data.updateCurrentProfile.result.spokenLanguages || [],
        },
        successful: response.data.updateCurrentProfile.successful,
        messages: response.data.updateCurrentProfile.messages,
      };

      return result;
    } catch (error) {
      console.error("Error updating profile:", error);
      throw error;
    }
  }

  async fetchSupportedLanguages(
    first: number = 1500
  ): Promise<ISpokenLanguage[]> {
    await DataService.ensureInitialized(this.instance);
    try {
      const response = await callApiViaBot(this.authToken, gqlQuery.languages, {
        first,
      });

      const languages = response.data.languages.edges.map(
        (edge: { node: ISpokenLanguage }) => edge.node
      );

      return languages;
    } catch (error) {
      console.error("Error fetching supported languages:", error);
      throw error;
    }
  }
}
