import type React from "react";
import type { IQuestNode } from "types/interfaces";
import type { CategoryContentPropsI } from "types/interfaces";
import ProgramQuest from "./ProgramQuest";

const CategoryContent: React.FC<CategoryContentPropsI> = ({
  title,
  category,
  description,
  questData,
  headline,
  bannerImage,
}) => {
  return (
    <div className="px-3">
      <div className="flex items-center justify-between rounded-2xl">
        <img alt={title} src={bannerImage} className="w-full object-cover h-19 md:h-25" />
      </div>
      <div className="py-6 text-cool-grey-600 flex flex-col gap-2">
        <h3 className="heading-2">{headline}</h3>
        <p className="body">{description}</p>
      </div>
      <h5 className="heading-4 text-cool-grey-600">Quests</h5>
      <div className="grid grid-cols-12 gap-6 pt-6">
        {questData?.map((quest: IQuestNode) => (
          <div key={quest.id} className="col-span-12 md:col-span-4 lg:col-span-3">
            <ProgramQuest questData={quest} category={category} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryContent;
