import { Card } from "components/Card";
import EmptyState from "../components/EmptyState";

import useLibrary from "hooks/useLibrary";
import type { IQuestEdge } from "types/interfaces";

const InProgress = () => {
  const { data, loading, error } = useLibrary("ongoing");
  const inprogressData = data?.quests.edges;
  console.log(inprogressData);

  if (loading) return <div>Loading</div>;

  return (
    <div className="flex flex-col gap-8 ">
      <p className="heading-5 hidden lg:block">In progress</p>
      {inprogressData.length === 0 ? (
        <EmptyState section="completed" />
      ) : (
        <div className="items-stretch space-y-2">
          <p className="heading-7">{`Quests(${inprogressData.length})`}</p>
          <div className="flex flex-col w-full md:flex gap-5">
            {inprogressData.map((quest: IQuestEdge) => (
              <div key={quest.node.id}>
                <Card
                  variant={`${"programCardInprogress"}`}
                  title={quest.node.name}
                  author={quest.node.authors[0].name}
                  imageUrl={quest.node.coverAsset?.url}
                  totalLessons={quest.node.userProgress?.totalLessons}
                  totalLessonsCompleted={quest.node.userProgress?.totalLessonsCompleted}
                  pages={quest.node.resources}
                  availableDays={quest.node.userProgress?.totalDays}
                  duration={quest.node.resources[1]?.mediaAsset?.duration}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default InProgress;
