import { useQuery } from "@apollo/client";
import gqlQuery from "services/graphql/queries";

function useLibrary(status: string) {
  const { loading, data, error } = useQuery(gqlQuery.getLibrary, {
    variables: status ? { status: status } : {},
  });

  return {
    loading,
    data: data ?? null,
    error,
  };
}

export default useLibrary;
