import { app, stageView } from "@microsoft/teams-js";
import Icons from "components/icons/Icons";
import { teamsConfig } from "config/teamsConfig";
import { cn } from "libs/classMerger";
import { useNavigate } from "react-router-dom";
import type { IResource } from "types/interfaces";
import { durationToMinutes } from "utils/time";

export function MeditationCard({
  item,
  className,
}: {
  item: IResource;
  className: string;
}) {
  const { coverAsset, title, author, mediaAsset } = item;
  const navigate = useNavigate();

  function handlePlay() {
    if (!mediaAsset) {
      return;
    }

    app.initialize().then(() => {
      if (stageView.isSupported()) {
        stageView
          .open({
            appId: teamsConfig.appId,
            contentUrl: `${window.location.origin}/stageView?mediaUrl=${mediaAsset?.url}&thumbnailUrl=${coverAsset?.url}&autoplay=true&mimeType=${mediaAsset?.contentType}`,
            title: title?.toString(),
            websiteUrl: window.location.origin,
            openMode: stageView.StageViewOpenMode.modal,
            threadId: "",
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        navigate(
          `/stageView?mediaUrl=${encodeURIComponent(mediaAsset?.url)}&thumbnailUrl=${encodeURIComponent(
            coverAsset?.url ?? "",
          )}&autoplay=true&mimeType=${mediaAsset?.contentType}&isStageViewSupported=false`,
        );
      }
    });
  }

  return (
    <button type="button" onClick={handlePlay} className={cn("text-left flex-shrink-0", className)}>
      <div className="w-full aspect-square relative rounded-2xl">
        <img src={coverAsset?.url} alt={title} className="size-full object-cover overflow-clip rounded-2xl" />
        <div className="absolute bottom-2 right-2">
          <Icons name="play-circle-outlined" height={32} width={32} fill="#0F131A" />
        </div>
      </div>
      <h6 className="title-8 mt-3 w-full truncate">{title}</h6>
      <p className="body-small mt-1 w-full truncate">
        {author?.name} &bull; {durationToMinutes(mediaAsset?.duration)}
      </p>
    </button>
  );
}
