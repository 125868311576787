import type { ICurrentProfile } from "types/interfaces";
import { AccountSection } from "../Profile/Profile";
import { ProfilePage } from "./ProfilePage";
import { SecuritySettings } from "./SecuritySettings";

export default function MyAccount({
  section,
  profileData,
  showEditProfilePage,
}: {
  section: string;
  profileData?: ICurrentProfile;
  editProfilePageOpen?: boolean;
  showEditProfilePage: (show: boolean) => void;
}) {
  return (
    <>
      {section === AccountSection.Profile && (
        <ProfilePage
          onEdit={() => showEditProfilePage(true)}
          profileData={profileData}
        />
      )}
      {section === AccountSection.SecuritySettings && (
        <SecuritySettings profileData={profileData} />
      )}
    </>
  );
}
