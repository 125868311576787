import { useCallback, useEffect, useState } from "react";
import "./RecommendedForYou.css";

import { CarouselNext, CarouselPrevious } from "components/Carousel";
import useEmblaCarousel from "embla-carousel-react";
import useScreenType from "../../../hooks/useScreenType";
import { QuestCard, type QuestData } from "../components/QuestCard";

const RecommendedForYou = () => {
  const [cards, setCards] = useState([] as QuestData[]);

  const screenType = useScreenType();

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false });

  const scrollPrev = useCallback(() => {
    emblaApi?.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    emblaApi?.scrollNext();
  }, [emblaApi]);

  useEffect(() => {
    setCards([
      {
        id: 1,
        title: "The Art of Manifesting",
        author: "Regan Hillyer",
        image:
          "https://cdn.mindvalley.com/asset/2c67ca2d-fc26-4ba2-9ad8-f1b41d693ab1/image.png?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 2,
        title: "The Silva Ultramind System",
        author: "Vishen",
        image:
          "https://cdn.mindvalley.com/asset/10b1de3f-44d4-4dd5-84d7-dc2456a79da4/3NyD7jfA.jpeg?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 3,
        title: "Duality",
        author: "Jeffrey Allen",
        image:
          "https://cdn.mindvalley.com/asset/ebac39bf-5a08-4aec-9476-af0735a843a9/DU_ENG_Cover.jpg?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 4,
        title: "Be Extraordinary",
        author: "Jim Kwik",
        image:
          "https://cdn.mindvalley.com/asset/af39a317-eb44-4dbc-a2b9-c98f9bc1de04/BE_QuestCover_1920x1080_EN.jpg?auto=webp&fit=cover&quality=50&dpr=2",
      },
      {
        id: 5,
        title: "Quantum Jumping",
        author: "Burt Goldman",
        image:
          "https://cdn.mindvalley.com/asset/c4a5748e-c0ce-404f-bc56-439e6031d5ce/QJ_Burt_Goldman_Quest_Cover_1920x1080px_EN.jpg?auto=webp&fit=cover&quality=50&dpr=2",
      },
    ]);
  }, []);

  return (
    <div className="md:py-10 px-5 py-6 flex flex-col gap-6" style={{ backgroundColor: "#F9F8F4" }}>
      <div className="flex justify-between items-center">
        <div className="flex flex-col items-start gap-0.5">
          <div className="heading-7">Recommended</div>
          {screenType.isDesktop && <div className="text-cool-grey-450 mb-4">Quests based on your interest</div>}
        </div>
        <div className="hidden sm:flex lg:hidden items-start space-x-2">
          <CarouselPrevious onClick={scrollPrev} />
          <CarouselNext onClick={scrollNext} />
        </div>
      </div>
      {(screenType.isDesktop || screenType.isLargeDesktop) && (
        <div className="rounded-xl pl-2 flex justify-between items-center gap-4 items-stretch -ml-3 overflow-hidden">
          {cards
            .map((card) => (
              <div key={card.id} className="w-full cursor-pointer" style={{ gap: 0 }}>
                <QuestCard card={card} />
              </div>
            ))
            .slice(0, screenType.isLargeDesktop ? 5 : 4)}
        </div>
      )}
      <div className="rounded-xl embla lg:hidden overflow-hidden" ref={emblaRef}>
        <div className="embla__container rounded-xl">
          {cards.map((item) => (
            <div
              key={item.id}
              className="overflow-hidden embla__slide rounded-xl flex-shrink-0 flex-grow-0 basis-full sm:basis-1/2 md:basis-1/2"
            >
              <QuestCard card={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecommendedForYou;
