import spriteUrl from "@mindvalley/design-system/dist/icons/sprites/ui-icons.svg";

interface IconProps {
  name: string;
  className?: string;
  fill?: string;
  width?: number;
  height?: number;
}

const SvgIcons = ({ name, className, fill = "currentColor", width = 16, height = 16 }: IconProps) => (
  <svg className={className} width={width} height={height} fill={fill}>
    <title>{name}</title>
    <use href={`${spriteUrl}#${name}`} />
  </svg>
);
const withOverrideFill =
  (IconComponent: React.FC<IconProps>) =>
    ({ fill = "currentColor", ...props }: IconProps) => {
      return (
        <span style={{ color: fill }}>
          <IconComponent {...props} />
        </span>
      );
    };
const Icons = withOverrideFill(SvgIcons);
export default Icons;
