import InProgress from "./sections/InProgress";
import Completed from "./sections/Completed";
import AssignedToMe from "./sections/AssignedToMe";

export default function MyLibrary({ section }: { section: string }) {
  return (
    <>
      {section === "in-progress" && <InProgress />}
      {section === "assigned" && <AssignedToMe />}
      {section === "completed" && <Completed isEmpty={true} />}
    </>
  );
}
